import { DOC, isObject, isArray, isJson, each, createElement, getClosest, prefix, classRemove, classAdd, getSibling, uicoreCustomEvent, validateNum, renderSvg, flush, isEdge, getText} from "./utilities.js";
import Pagination from "./pagination.js";
import Columns from "./helpers/columns.js";
import Rows from "./helpers/rows.js";
import Dropdown from "./dropdown.js";
import Modal from "./modal.js";

export default function TableComplex(element, params) {
    element = element instanceof HTMLElement ? element : (function() {
        return;
    })();

    function validateRow(row, name) {
        var hasErrors = "";
        var rowCount = row.match(/\{((\w*|\d*):?)+\}/g);
        if (rowCount.length < 1 || rowCount.length > 3) {
            hasErrors += "Custom layout for row "+name+" was delared with an incorrect set of elements!\n";
        }
        var rowLength = 0;
        var localFunctions = ["placeholder","actions","settings","search"];
        each(rowCount, function(temp) {
            var subElements = temp.replace(/\{|\}/g, "").split(":");
            if (subElements.indexOf("placeholder") === -1 && !temp.match(/\{[A-Z,a-z]+:[1,2,3](:(start|center|end))*\}/g) ) {
                hasErrors += "Custom layout for row "+name+" has an element "+ temp +", which has been declared incorrectly!\n";
            }
            if (layoutOptions.indexOf(subElements[0]) === -1 && !window[subElements[0]] && localFunctions.indexOf(subElements[0]) < 0) { // does custom function exist
                hasErrors += "Custom layout for row "+name+" has an element with custom function '"+ subElements[0] +"' that is not accessible or dosen't exist!\n";
            }
            if (!isNaN(subElements[1].substring(0,1))) {
                rowLength += +subElements[1].substring(0,1);
            }
        });
        if (rowLength != 3) {
            hasErrors += "Custom layout for row "+name+" is configured with grid length '"+ rowLength +"', which needs to add up to '3'!\n";
        }
        return hasErrors;
    }

    var jsonParams = element.dataset.tableData;
    if (jsonParams) {
        params = JSON.parse(jsonParams);
    }

    // init options
    var options = {};
    var layoutOptions = ["{actions:1:start}","{search:1:center}","{settings:1:end}"];
    
    // Check to see what Javascript options are set or are missing from table element classes
    options.sort = typeof params.sort === "boolean" ? params.sort : false;
    options.expand = typeof params.expand === "boolean" ? params.expand : false;
    if (options.expand) {
        options.expandIcon = params.expandIcon && typeof params.expandIcon === "string" ? params.expandIcon : "arrow-tri-solid-right";
        if (options.expandIcon.indexOf(prefix) > -1) {
            options.expandIcon = options.expandIcon.split(prefix)[1];
        }
    }
    options.showTotal = typeof params.showTotal === "string" ? params.showTotal : false;
    options.condensed = typeof params.condensed === "boolean" ? params.condensed : false;
    options.fixedColumns = typeof params.fixedColumns === "boolean" ? params.fixedColumns : false;
    options.rearrangeableColumns = typeof params.rearrangeableColumns === "boolean" ? params.rearrangeableColumns : false;
    options.fixedHeight = typeof params.fixedHeight === "boolean" ? params.fixedHeight : false;
    options.header = typeof params.header === "boolean" ? params.header : true;
    //Set Default Batch Actions
    options.defaultBatchActions = {};
    options.defaultBatchActions.exportCsv = params.defaultBatchActions && typeof params.defaultBatchActions.exportCsv === "boolean" ? params.defaultBatchActions.exportCsv : true ;
    options.defaultBatchActions.exportJson = params.defaultBatchActions && typeof params.defaultBatchActions.exportJson === "boolean" ? params.defaultBatchActions.exportJson : true ;
    options.defaultBatchActions.deleteRow = params.defaultBatchActions && typeof params.defaultBatchActions.delete === "boolean" ? params.defaultBatchActions.delete : true ;

    // Set all table text to variables
    options.text = {};
    options.text.apply = params.text && params.text.apply && typeof params.text.apply === "string"? params.text.apply : "Apply";
    options.text.cancel = params.text && params.text.cancel && typeof params.text.cancel === "string" ? params.text.cancel : "Cancel";
    options.text.exportCsv = params.text && params.text.exportCsv && typeof params.text.exportCsv === "string" ? params.text.exportCsv : "Export as csv";
    options.text.exportJson = params.text && params.text.exportJson && typeof params.text.exportJson === "string" ? params.text.exportJson : "Export as json";
    options.text.deleteRow = params.text && params.text.delete && typeof params.text.delete === "string" ? params.text.delete : "Delete";
    options.text.noEntries = params.text && params.text.noEntries && typeof params.text.noEntries === "string" ? params.text.noEntries: "No entries found";
    options.text.import = params.text && params.text.import && typeof params.text.import === "string" ? params.text.import: "Import";
    options.text.print = params.text && params.text.print && typeof params.text.print === "string" ? params.text.print: "Print";
    options.text.columns = params.text && params.text.columns && typeof params.text.columns === "string" ? params.text.columns: "Columns";
    options.text.batchActions = params.text && params.text.batchActions && typeof params.text.batchActions === "string" ? params.text.batchActions: "Batch Actions";
    options.text.chooseActions = params.text && params.text.chooseAction && typeof params.text.chooseAction === "string" ? params.text.chooseAction: "Choose Actions";
    
    // Remove the data from the Table element
    options.showData = jsonParams && typeof params.showData === "boolean" ? params.showData : true;
    // Search is either on by default or can be truned off
    options.search = typeof params.search === "boolean" ? params.search : true;
    if (options.search) {
        options.text.search  = {};
        options.text.search.label = params.text && params.text.search && typeof params.text.search.label === "string" ? params.text.search.label : false;
        options.text.search.placeholder = params.text && params.text.search && typeof params.text.search.placeholder === "string" ? params.text.search.placeholder : false;
    }
    // Settings is either on by default or can be turned off
    options.settings = typeof params.settings === "boolean" ? params.settings : true;
    // Import either on by default or can be turned off
    options.import = typeof params.import === "boolean" ? params.import : true;
    // Printing is either on by default or can be turned off
    options.print = typeof params.print === "boolean" ? params.print : true;
    // Column Management is either on by default or can be turned off
    options.column = typeof params.column === "boolean" ? params.column : true;
    // Export details roww is either on by default or can be turned off
    options.exportDetails = typeof params.exportDetails === "boolean" ? params.exportDetails : true;

    var allowedExtensions = [".csv", ".json", ".js"];
    options.allowedImportExtensions = [];
    //is it just one or is it an array 
    each(params.allowedImportExtensions, function(ext) {
        if (typeof ext  === "string" && allowedExtensions.indexOf(ext) >= -1) {
            options.allowedImportExtensions.push(ext); 
        }
    });
    if (options.allowedImportExtensions.length === 0) options.allowedImportExtensions = allowedExtensions;
    // Bulk Actions
    options.actionsSelectFilters = [];
    each(params.additionalActions, function(additionalAction) {
        if (additionalAction.js) {
            var testJs = additionalAction.js;
            if (additionalAction.js.indexOf("(") > -1) {
                testJs = additionalAction.js.substring(0, additionalAction.js.indexOf("(")).trim();
            } else {
                additionalAction.js += "()";
            }
            if (!window[testJs]) {
                throw new Error("The additional action function "+additionalAction.js+" could not be accessed, please verify and try again!");
            } else {
                options.actionsSelectFilters.splice(additionalAction.pos, 0, additionalAction);
            }
        } else {
            options.actionsSelectFilters.splice(additionalAction.pos, 0, additionalAction);
        }
    });
    for (var action in options.defaultBatchActions) {
        if (options.defaultBatchActions[action]) {
            if (action === "deleteRow" && options.actionsSelectFilters[0]) { //place divider above `delete` action only when other actions are present
                options.actionsSelectFilters.push("hr");
            }
            options.actionsSelectFilters.push(options.text[action]);
        }
    }
    options.select = typeof params.select === "boolean" ? params.select : true;
    if (!options.select) layoutOptions.splice(layoutOptions.indexOf("{actions:1:start}"),1,"{placeholder:1:start}");
    if (!options.search) layoutOptions.splice(layoutOptions.indexOf("{search:1:center}"),1,"{placeholder:1:center}");
    if (!options.settings) layoutOptions.splice(layoutOptions.indexOf("{settings:1:end}"),1,"{placeholder:1:end}");
    // Customise the layout
    if (params.layout) {
        var rowCount = 0;
        var hasErrors = "";
        if (params.layout.row1) {
            hasErrors += validateRow(params.layout.row1, "one");
            rowCount++;
        }
        if (params.layout.row2) {
            hasErrors += validateRow(params.layout.row2, "two");
            rowCount++;
        }
        if (rowCount === 0) {
            hasErrors += "Custom layout was configured to have no Rows, which it needs to have at least 1!\n";
        }
        if (hasErrors.length > 0) {
            throw new Error(hasErrors);
        }
    }
    options.layout = params.layout ? params.layout : {row2:layoutOptions.join("")};

    if (isArray(params.perPageSelect)) {
        if (typeof params.perPageSelect[0] === "number") {
            options.perPageSelect = params.perPageSelect.map(function(i) { 
                i = Math.abs(parseInt(i));
                return i;
            });
        } else if (validateNum(params.perPageSelect[0])) {
            options.perPageSelect = params.perPageSelect.map(function(i) { 
                i = Math.abs(parseInt(i));
                return i;
            });
        } else {
            throw new Error("Invalid perPageSelect. Should be a non-empty array of integer");
        }
    } else {
        options.perPageSelect = [12,24,48,96];
    }
    options.perPage = options.perPageSelect.indexOf(params.perPage) > -1 ? validateNum(params.perPage) : options.perPageSelect[0];
    options.origPerPage = options.perPage;
    options.items = validateNum(params.items, 0);
    options.buttonLabelLeft = params.buttonLabelLeft && typeof params.buttonLabelLeft === "string" ? params.buttonLabelLeft : "Previous";
    options.buttonLabelRight = params.buttonLabelRight && typeof params.buttonLabelRight === "string" ? params.buttonLabelRight : "Next";
    options.disablePaginationInput = params.disablePaginationInput !== null && typeof params.disablePaginationInput === "boolean" ? params.disablePaginationInput : false;

    // transfer data
    options.data = {};
    if(!params.data) {
        var msg = "Missing data parameters for table!";
        console.error(msg);
        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
        return false;
    }
    if (!params.data.headings) {
        msg = "Missing data.headings parameters for table!";
        console.error(msg);
        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
        return false;
    }
    options.data.headings = params.data.headings;
    if (!params.data.columns) {
        msg = "Missing data.columns parameters for table!";
        console.error(msg);
        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
        return false;
    }
    options.data.columns = params.data.columns;
    if (!params.data.rows) {
        msg = "Missing data.rows parameters for table!";
        console.error(msg);
        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
        return false;
    }
    options.data.rows = params.data.rows;
    
    options.labels = {
        noRows: options.text.noEntries, // Message shown when there are no search results
    };

    if (!options.showData) {
        delete element.dataset.tableData;
    }

    var self = this,
        stringTable = "Table",
        table = element,
        body,
        head,
        header,
        wrapper,
        container,
        bottom,
        columnBox,
        isIE,
        pages,
        currentPage,
        totalPages,
        paginationElement,
        pagination,
        columnRenderers,
        selectedColumns,
        labels,
        rect,
        input,
        searching = false,
        dragSrcEl,
        sortedColumnIdx,
        metHeight = true,
        batchId,
        searchId,
        columnsFixed = false,
        initialY,
        begOffsetTop,
        currentY,
        moveableCols = [],
        colMoved = false,
        // Handlers
        handleTouchEvent = function(e) {
            if (e.pointerType === "mouse") {
                return false; //If mouse touch on IE, cancel and use handleDragEvent
            } else {
                e.preventDefault(); //else prevent default actions, like scrolling
            }
            var yOffset = 0;
            var finOffsetTop;
            if (e.type === "touchstart" || e.type === "pointerdown") {
                if (e.type === "pointerdown") {
                    initialY = e.clientY - yOffset;
                } else {
                    initialY = e.touches[0].clientY - yOffset;
                }
                dragSrcEl = e["target"];
                if (dragSrcEl.tagName != "LI" ) {
                    dragSrcEl = getClosest(dragSrcEl, "." + prefix + "table-cmplx-drag-li");
                }
                dragSrcEl.dataTransfer = {};
                dragSrcEl.dataTransfer.data = dragSrcEl.outerHTML;
                begOffsetTop = dragSrcEl.offsetTop;
            }
            if (e.type === "touchmove" || e.type === "pointermove") {
                e.preventDefault();
                var before = false;
                if (isIE || isEdge) {
                    currentY = e.clientY - initialY;
                    dragSrcEl.addEventListener("pointerleave", handlePointerLeave);
                } else {
                    currentY = e.touches[0].clientY - initialY; //difference btn start and end if you move up it is negative
                }
                yOffset = currentY;
                if (yOffset === 0) {
                    return false;
                }

                if (!dragSrcEl) {
                    dragSrcEl = e["target"];
                    if (dragSrcEl.tagName != "LI" ) {
                        dragSrcEl = getClosest(dragSrcEl, "." + prefix + "table-cmplx-drag-li");
                    }
                }

                finOffsetTop = begOffsetTop + currentY;
                dragSrcEl.style.transform = "translate3d(0, " + yOffset + "px, 0)";
                dragSrcEl.style.opacity = 0.5;
                colMoved = true;
                each(moveableCols, function(col) {
                    var startedBelow = col.offsetTop < dragSrcEl.offsetTop;
                    var startedAbove = col.offsetTop > dragSrcEl.offsetTop;
                    var endedBelow = col.offsetTop < finOffsetTop;
                    var endedAbove = col.offsetTop > finOffsetTop;
                    if (endedAbove && startedBelow  //if started from below and moved above column
                        || endedBelow && startedAbove) { //if started from above and moved below column
                        if (endedAbove) { //if item is being moved before col
                            if (col.previousElementSibling) { //if not the first column
                                if (finOffsetTop > col.previousElementSibling.offsetTop) { //if moving up, but not above predecessor
                                    if (col.classList.contains(prefix + "border-bottom-black")) {
                                        col.classList.remove(prefix + "border-bottom-black");
                                    } 
                                    col.classList.add(prefix + "border-top-black");
                                } else if (col.classList.contains(prefix + "border-top-black")) {
                                    col.classList.remove(prefix + "border-top-black");
                                } else if (col.classList.contains(prefix + "border-bottom-black")) {
                                    col.classList.remove(prefix + "border-bottom-black");
                                }
                            } else if (finOffsetTop < 0) { //if being placed before first column
                                col.classList.add(prefix + "border-top-black");
                            }
                        } else if (endedBelow) { //if item is being moved below
                            if (col.nextElementSibling) {
                                if (finOffsetTop < col.nextElementSibling.offsetTop) {
                                    if (col.classList.contains(prefix + "border-top-black")) {
                                        col.classList.remove(prefix + "border-top-black");
                                    }
                                    col.classList.add(prefix + "border-bottom-black");
                                } else  if (col.classList.contains(prefix + "border-bottom-black")) { //either should get the styling or should not
                                    col.classList.remove(prefix + "border-bottom-black");
                                } else if (col.classList.contains(prefix + "border-top-black")) {
                                    col.classList.remove(prefix + "border-top-black");
                                }
                            } else if (finOffsetTop > col.offsetTop) {
                                col.classList.add(prefix + "border-bottom-black");
                            }
    
                        }
                    } else if (startedBelow && col.nextElementSibling && col.nextElementSibling.offsetTop > finOffsetTop && !col.nextElementSibling.classList.contains(prefix + "table-cmplx-drag-li")) { // started from below + moved below a draggable but above a fixed column
                        col.classList.add(prefix + "border-bottom-black");
                    } else if (endedAbove && startedAbove && finOffsetTop > col.previousElementSibling.offsetTop && col.previousElementSibling.classList.contains(prefix + "table-cmplx-li")) { //if started from above + moved above but below a fixed 
                        col.classList.add(prefix + "border-top-black");
                    } else if (col.classList.contains(prefix + "border-top-black")) { //If it has a border but doesmn't fall into above category, remove it
                        col.classList.remove(prefix + "border-top-black");
                    } else if (col.classList.contains(prefix + "border-bottom-black")) { //If it has a border but doesmn't fall into above category, remove it
                        col.classList.remove(prefix + "border-bottom-black");
                    }
                });
            }
            if (e.type ==="touchend" || e.type === "pointerup") {
                initialY = currentY;
                before = false;
                if (!dragSrcEl) {
                    dragSrcEl = e["target"];
                    if (dragSrcEl.tagName != "LI" ) {
                        dragSrcEl = getClosest(dragSrcEl, "." + prefix + "table-cmplx-drag-li");
                    }
                }
                finOffsetTop = begOffsetTop + currentY;
                if (colMoved) {
                    each(moveableCols, function(col) {
                        if (col != dragSrcEl) {
                            var startedBelow = col.offsetTop < dragSrcEl.offsetTop;
                            var startedAbove = col.offsetTop > dragSrcEl.offsetTop;
                            var endedBelow = col.offsetTop < finOffsetTop;
                            var endedAbove = col.offsetTop > finOffsetTop;
                            if (endedAbove && startedBelow 
                                || endedBelow && startedAbove 
                                    || col.nextElementSibling && startedBelow && col.nextElementSibling.offsetTop > finOffsetTop && !col.nextElementSibling.classList.contains(prefix + "table-cmplx-drag-li") //if moving from bottom coming up: starting top is lower than above's starting top but ending top is higher 
                                        || endedAbove && startedAbove && finOffsetTop > col.previousElementSibling.offsetTop && col.previousElementSibling.classList.contains(prefix + "table-cmplx-li") ) {          
                                if (endedAbove && startedBelow 
                                                || endedAbove && startedAbove && finOffsetTop > col.previousElementSibling.offsetTop && col.previousElementSibling.classList.contains(prefix + "table-cmplx-li")) {
                                    before = true;
                                    if (col.previousElementSibling) { //if not the first column
                                        if (finOffsetTop > col.previousElementSibling.offsetTop) {
                                            handleTouchEnd(dragSrcEl, before, col);
                                        } 
                                    } else if (!col.previousElementSibling && finOffsetTop < 0) { //if being placed before the first column
                                        handleTouchEnd(dragSrcEl, before, col);
                                    }
                                } else if (endedBelow && startedAbove 
                                                || startedBelow && col.nextElementSibling && col.nextElementSibling.offsetTop > finOffsetTop && !col.nextElementSibling.classList.contains(prefix + "table-cmplx-drag-li")) { //if moving column down
                                    if (col.nextElementSibling && finOffsetTop < col.nextElementSibling.offsetTop) {
                                        handleTouchEnd(dragSrcEl, before, col);
                                    } else if (!col.nextElementSibling && finOffsetTop > col.offsetTop) { //if being placed below last column
                                        handleTouchEnd(dragSrcEl, before, col);
                                    }
                                }

                                col.classList.remove(prefix + "border-top-black");
                                col.classList.remove(prefix + "border-bottom-black");

                                columnBox.orderChanged = true;
                            }
                        }
                    });
                    if (dragSrcEl.hasAttribute("style")) { //set back to origin if doesn't get moved into available slot
                        dragSrcEl.removeAttribute("style");
                    }
                    colMoved = false; //once touch end is over, set colMoved back to false 
                } else {
                    if (dragSrcEl.querySelector("input").checked) {
                        dragSrcEl.querySelector("input").checked = false;
                    } else {
                        dragSrcEl.querySelector("input").checked = true;
                    }
                }
                var applyButton = columnBox.querySelector("."+prefix+"btn-primary");
                if (applyButton.disabled) {
                    applyButton.removeAttribute("disabled");
                }
                if (isIE || isEdge) {
                    dragSrcEl.removeEventListener("pointerleave", handlePointerLeave);
                }
            }
        },
        handleTouchEnd = function(dragSrcEl, before, col) {
            var input = dragSrcEl.querySelector("input");
            var checked = input && input.checked;
            setTimeout(function() {
                var dropElem;
                var dropHTML = dragSrcEl.dataTransfer.data;
    
                dragSrcEl.parentNode.removeChild(dragSrcEl);
                if (before) {
                    col.insertAdjacentHTML("beforebegin", dropHTML);
                    dropElem = col.previousSibling;
                } else {
                    col.insertAdjacentHTML("afterend", dropHTML);
                    dropElem = col.nextSibling;
                }
                
                moveableCols.push(dropElem);
                moveableCols = moveableCols.filter( function(value) {
                    return value != dragSrcEl;
                });
                if (checked) {
                    dropElem.querySelector("input").checked = true;
                }
                setupDragEvents(dropElem);
            }, 50);
        },
        handlePointerLeave = function(e) {
            setTimeout(function() {
                dragSrcEl = e["relatedTarget"];
                if (dragSrcEl.tagName != "LI" ) {
                    dragSrcEl = getClosest(dragSrcEl, "." + prefix + "table-cmplx-drag-li");
                }
                if (dragSrcEl.hasAttribute("style")) {
                    dragSrcEl.removeAttribute("style");
                }
                each(moveableCols, function(col) {
                    if (col.classList.contains(prefix + "border-top-black")) {
                        col.classList.remove(prefix + "border-top-black");
                    } else if (col.classList.contains(prefix + "border-bottom-black")) {
                        col.classList.remove(prefix + "border-bottom-black");
                    }
                });
            },50);
        },
        handleDragEvent = function(e) {
            e.stopPropagation();
            if (e.type === "dragstart") {
                dragSrcEl = e["target"];

                dragSrcEl.dataTransfer = {};
                dragSrcEl.dataTransfer.data = dragSrcEl.outerHTML;
            }
            if (e.type === "dragover") {
                var overEl = e["target"];
                if (overEl.tagName != "LI" ) {
                    overEl = getClosest(overEl, "." + prefix + "table-cmplx-drag-li");
                }
                if (dragSrcEl != overEl) {
                    if (e.preventDefault) {
                        e.preventDefault(); // Necessary. Allows us to drop.
                    }
                    overEl.classList.add(prefix + "border-bottom-black");
                    if (overEl.previousElementSibling && overEl.previousElementSibling.classList.contains(prefix + "border-bottom-black")) {
                        overEl.previousElementSibling.classList.remove(prefix + "border-bottom-black");
                    }
                    if (overEl.nextElementSibling && overEl.nextElementSibling.classList.contains(prefix + "border-bottom-black")) {
                        overEl.nextElementSibling.classList.remove(prefix + "border-bottom-black");
                    }
                    return false;
                }
               
            }
              
            if (e.type === "dragleave") {
                
                var leaveEl = e["target"];
                if (leaveEl.tagName != "LI" ) {
                    leaveEl = getClosest(leaveEl, "." + prefix + "table-cmplx-drag-li");
                }
                if (dragSrcEl != leaveEl) {
                    if (e.preventDefault) {
                        e.preventDefault(); // Necessary. Allows us to drop.
                    }
                    
                    leaveEl.classList.remove(prefix + "border-bottom-black");
                    return false;
                }
                
            }
              
            if (e.type === "drop") {
              
                if (e.stopPropagation) {
                    e.stopPropagation(); // Stops some browsers from redirecting.
                }
              
                var dropTarget = e["target"];    
                if (dropTarget.tagName != "LI") {
                    dropTarget = getClosest(dropTarget, "." + prefix + "table-cmplx-drag-li");
                }
                // Don't do anything if dropping the same column we're dragging.
                if (dragSrcEl != dropTarget) {
                    var checkbox = dragSrcEl.querySelector("input");
                    var checked = checkbox ? checkbox.checked : false;
                    var dropHTML = dragSrcEl.dataTransfer.data;
                    dragSrcEl.parentNode.removeChild(dragSrcEl);
                    
                    dropTarget.insertAdjacentHTML("afterend",dropHTML);
                    var dropElem = dropTarget.nextSibling;
                    if (checked) {
                        dropElem.querySelector("input").checked = true;
                    }
                    setupDragEvents(dropElem);
                    dropTarget.classList.remove(prefix + "border-bottom-black");

                    columnBox.orderChanged = true;
                    var applyButton = columnBox.querySelector("."+prefix+"btn-primary");
                    // eanble Apply and Cancel
                    if (applyButton.disabled) {
                        applyButton.removeAttribute("disabled");
                    }
                }
                return false;
            }
        },
        handleImportActionEvent = function(e) {
            var t = e.target;
            var reader = new FileReader();
            var output = ""; //placeholder for text output
            var type = ""; //placeholder for file type
            if(t.files && t.files[0]) {
                if (!t.files[0].type || 
                    t.files[0].type === "application/json") {
                    type = "json";
                } else if(t.files[0].type && 
                    (t.files[0].type === "application/vnd.ms-excel" ||
                    t.files[0].type === "text/csv")) {
                    type = "csv";
                } else {
                    var msg = "The file type for the file selected was not recognizable!";
                    console.error(msg);
                    uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                }     
                reader.onload = function (e) {
                    output = e.target.result;
                    if(type === "json") {
                        if(!isJson(output)) {
                            msg = "The file type for the supplied file was not recognizable!";
                            console.error(msg);
                            uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                        }
                    }
                    var inputOptions = {type: type,
                        data: output,
                        headings: true};
                    self.import(inputOptions);
                    t.value = "";
                    
                };//end onload()
                reader.readAsText(t.files[0]);
            }//end if html5 filelist support
            e.preventDefault();
        },
        handleExportActionEvent = function(t) {
            var exportFileOptions = { };
            if (options.expand && options.select) {
                exportFileOptions.skipColumn = [0,1];
            } else if (options.expand || options.select) {
                exportFileOptions.skipColumn = [0];
            }

            if (t.innerHTML === options.text.exportCsv) {
                exportFileOptions.type = "csv";
            } else {
                exportFileOptions.type = "json";
            }
            self.export(exportFileOptions);
        },
        handleDeleteActionEvent = function() {
            self.delete();
        },
        handleSelectAllChange = function(state) {
            if (options.select) {
                var actions = wrapper.querySelector("[data-target='#cmplxTablesActions']");
                if (state === "checked" || state === "indeterminate" && actions.disabled) {
                    actions.removeAttribute("disabled");
                } else if (state === "unchecked" && !actions.disabled && table.selectedRows.length < 1) {
                    actions.setAttribute("disabled","");
                }
            }
        },
        handleCheckBoxEvent = function(t) {
            if(options.select && t.classList.contains(prefix + "table-cmplx-select-all")) { // Header Select All
                if (table.hasRows) {
                    var cellIdx = getHeaderCellIndex(prefix + "table-cmplx-select-all");
                    var check = t.checked;
                    if (!check || t.state === "indeterminate") {
                        check = false;
                        t.checked = false;
                        t.state = "unchecked";
                    } else {
                        t.state = "checked";
                        check = true;
                    }
                    each(pages[currentPage-1], function(row) {
                        if(row.cells.length != 1) {
                            var cell = row.cells[cellIdx];
                            var input = cell.querySelector("input[type='checkbox']");
                            var dataInput = table.data[row.dataIndex].cells[cellIdx].querySelector("input[type='checkbox']");

                            if (check) {
                                dataInput.checked = input.checked = true;
                                table.selectedRows.push(row.dataIndex);
                                row.classList.add("selected");
                                if (options.expand) {
                                    row.nextElementSibling.classList.add("selected");
                                }
                                t.state = "checked";
                            } else {
                                dataInput.checked = input.checked = false;
                                table.selectedRows.pop(row.dataIndex);
                                row.classList.remove("selected");
                                if (options.expand) {
                                    row.nextElementSibling.classList.remove("selected");
                                }
                            }
                        }
                    });
                    pages[currentPage-1].selected = t.checked;
                    handleSelectAllChange(t.state);
                } else {
                    t.checked = false;
                }
            } else if(options.select && t.parentNode.classList.contains(prefix + "table-cmplx-row-select")) { // Table Cell Select
                cellIdx = getHeaderCellIndex(prefix + "table-cmplx-select-all");
                var tr = getClosest(t, "tr", false);
                if (t.checked) {
                    table.data[tr.dataIndex].cells[cellIdx].querySelector("input[type='checkbox']").checked = true;
                    tr.classList.add("selected");
                    if (options.expand) {
                        tr.nextElementSibling.classList.add("selected");
                    }
                    if (table.selectedRows.length === 0) {
                        table.selectedRows.push(tr.dataIndex);
                    } else {
                        var newRows = [];
                        for (var idx = 0; idx < table.selectedRows.length; idx++) {
                            
                            var value = table.selectedRows[idx];
                            var less = table.selectedRows[idx-1];
                            var more = table.selectedRows[idx+1];
                            if (tr.dataIndex < value 
                                && (less === undefined || tr.dataIndex > less)
                                && newRows.indexOf(tr.dataIndex) === -1) {
                                newRows.push(tr.dataIndex);
                                newRows.push(value);
                            } else if (tr.dataIndex > value 
                                && (more === undefined || tr.dataIndex < more)
                                && newRows.indexOf(tr.dataIndex) === -1) {
                                newRows.push(value);
                                newRows.push(tr.dataIndex);
                            } else {
                                newRows.push(value);
                            }
                        }
                        if (newRows.length > 0) {
                            table.selectedRows = newRows;
                        }
                    }
                } else {
                    table.data[tr.dataIndex].cells[cellIdx].querySelector("input[type='checkbox']").checked = false;
                    tr.classList.remove("selected");
                    if (options.expand) {
                        tr.nextElementSibling.classList.remove("selected");
                    }
                    table.selectedRows = table.selectedRows.filter( function(value) {
                        if (value != tr.dataIndex) {
                            return "" + value;
                        }
                    });
                    each(table.activeHeadings[cellIdx].children, function(el) {
                        if (el && el.classList.contains(prefix + "table-cmplx-select-all")) {
                            el.checked = false;
                        }
                    });
                }
                var selectAll = wrapper.querySelector("." + prefix + "table-cmplx-select-all");
                if (table.selectedRows.length === options.perPage || table.selectedRows.length === pages[currentPage-1].length) {
                    selectAll.indeterminate = false;
                    selectAll.state = "checked";
                    selectAll.checked = true;
                } else if (table.selectedRows.length > 0) {
                    selectAll.state = "indeterminate";
                    selectAll.indeterminate = true;
                } else {
                    selectAll.state = "unchecked";
                    selectAll.indeterminate = false;
                }
                handleSelectAllChange(selectAll.state);
            }
        },
        handleButtonEvent =  function(t) {
            if (t.innerHTML === options.text.cancel) {
                columnBox.classList.remove(prefix + "show");
            }
            else if (t.innerHTML === options.text.apply) {
                if(columnBox.orderChanged) {
                    var order = [];
                    // Make sure we pick up cells for Expandable, Selectable and Labels
                    for (var h = 0; h < baseCellIdx(); h++) {
                        order.push(h);
                    }
                    each (columnBox.getElementsByTagName("ul"), function(unOderedList) {
                        each(unOderedList.children, function(li) {
                            each(table.headings, function(header) {
                                if(li.querySelectorAll("label")[0].textContent === header.textContent) {
                                    order.push(header.originalCellIndex);
                                }
                            });
                        });
                    });
                    columnBox.orderChanged = false;
                    columns().order(order);
                    table.hiddenColumns = [];
                    self.update();
                }

                each (columnBox.getElementsByTagName("ul"), function(unOderedList) {
                    each(unOderedList.children, function(li) {
                        var liCheckbox = li.querySelector("input[type='checkbox']");
                        if (liCheckbox && liCheckbox.checked) {
                            each(table.headings, function(header) {
                                if(li.querySelectorAll("label")[0].textContent === header.textContent) {
                                    columns().show([header.originalCellIndex]);
                                    self.update();
                                }
                            });
                        } else if (liCheckbox && !liCheckbox.checked) {
                            each(table.headings, function(header) {
                                if(li.querySelectorAll("label")[0].textContent === header.textContent) {
                                    columns().hide([header.originalCellIndex]);
                                    self.update();
                                }
                            });
                        }
                    });

                    if (searching) {
                        self.search(input.value);
                    }
                });
                columnsFixed = false;
                renderHeader();
                columnBox.classList.remove(prefix + "show");
            }
        },
        handleTableHeaderEvent = function(e,t) {
            if (
                options.sort &&
                t.hasAttribute("data-sortable")
            ) {
                if ( options.items > 0 && (options.items != table.activeRows.length)) {
                    self.deleteAll();
                    //change column to asc or desc
                    columns().updateSortDirection(table.activeHeadings[table.activeHeadings.indexOf(t)], t.classList.contains("asc") ? "asc":"desc");
                }
                else{
                    columns().sort(table.activeHeadings.indexOf(t));
                    sortedColumnIdx = t.originalCellIndex;
                    self.update();
                    e.preventDefault();
                }
                uicoreCustomEvent("Table", "SortEvent", table, { "column" : table.activeHeadings.indexOf(t), "direction" : (t.classList.contains("asc") ? "ascending":"descending"), "currentPage": currentPage, "perPage": options.perPage });
            }
            t.focus();
        },
        handleAccordionEvent = function(t) {
            if(t.classList.contains(prefix + "table-cmplx-accordion-btn")) {
                var htmlRow = t.parentNode.parentNode,
                    currentRow = table.data[htmlRow.dataIndex];
                if (currentRow.details) {
                    var detailsRow = htmlRow.nextElementSibling.children[0];
                    if (detailsRow.classList.contains(prefix + "show")) {
                        detailsRow.classList.remove(prefix + "show");
                        t.classList.add(prefix + "collapsed");
                        t.setAttribute("aria-expanded",false);
                    } else {
                        detailsRow.classList.add(prefix + "show");
                        t.classList.remove(prefix + "collapsed");
                        t.setAttribute("aria-expanded",true);
                    }
                } else {
                    each(t.querySelector("svg").childNodes, function(use) {
                        if (use.getAttribute("class").indexOf(prefix + "show") > -1) {
                            classRemove(use, prefix + "show");
                            if (use.getAttribute("xlink:href").indexOf(prefix + "loading-sqrs") != -1) {
                                var row = getClosest(use, "tr", false);
                                table.activeRows[row.dataIndex].event = "ExpandCancelEvent";
                                uicoreCustomEvent("Table", "ExpandCancelEvent", table, {"rowId": row.dataIndex});
                            }
                        } else {
                            classAdd(use, prefix + "show");
                            if (use.getAttribute("xlink:href").indexOf(prefix + "loading-sqrs") != -1) {
                                row = getClosest(use, "tr", false);
                                table.activeRows[row.dataIndex].event = "ExpandStartEvent";
                                var content = [];
                                each(table.data[row.dataIndex].cells, function(cell, idx) {
                                    if (options.expand && options.select) {
                                        if (idx >= 2) {
                                            var dt = "\""+labels[idx]+"\": \""+ ( (cell.hasAttribute("data-content") ? cell.getAttribute("data-content") : cell.textContent )+"\"" );
                                            content.push(dt);
                                        }
                                    } else if (options.expand ||  options.select) {
                                        if (idx >= 1) {
                                            dt = "\""+labels[idx]+"\": \""+ ( (cell.hasAttribute("data-content") ? cell.getAttribute("data-content") : cell.textContent )+"\"" );
                                            content.push(dt);
                                        }
                                    } else {
                                        dt = "\""+labels[idx]+"\": \""+ ( (cell.hasAttribute("data-content") ? cell.getAttribute("data-content") : cell.textContent )+"\"" );
                                        content.push(dt);
                                    }
                                });
                                uicoreCustomEvent("Table", "ExpandStartEvent", table, {"rowId": row.dataIndex, "content": content});
                            }
                        }
                    });
                }
            }
        },
        setupDragEvents = function(el) {
            el.addEventListener("dragstart", handleDragEvent, false);
            el.addEventListener("dragover", handleDragEvent, false);
            el.addEventListener("dragleave", handleDragEvent, false);
            el.addEventListener("drop", handleDragEvent, false);
            if (isIE || isEdge) {
                el.addEventListener("pointerdown", handleTouchEvent, false);
                el.addEventListener("pointerup", handleTouchEvent, false);
                el.addEventListener("pointermove", handleTouchEvent, false);
                if (isEdge) {
                    el.addEventListener("click", function(e) {
                        if (e.pointerType === "touch") {
                            e.preventDefault();
                        }
                    });
                }
            } else {
                el.addEventListener("touchstart", handleTouchEvent, false);
                el.addEventListener("touchend", handleTouchEvent, false);
                el.addEventListener("touchmove", handleTouchEvent, false);
            }

        },
        extend = function (src, props) {
            for (var prop in props) {
                if (Object.prototype.hasOwnProperty.call(props, prop)) {
                // if (props.hasOwnProperty(prop)) {
                    var val = props[prop];
                    if (val && isObject(val)) {
                        src[prop] = src[prop] || {};
                        extend(src[prop], val);
                    } else {
                        src[prop] = val;
                    }
                }
            }
            return src;
        },
        baseCellIdx = function() {
            var baseIdx = 0;
            if (table.expand) {
                baseIdx++;
            }
            if (table.select) {
                baseIdx++;
            }
            return baseIdx;
        },
        columns = function (columns) {
            return new Columns(table, columns);
        },
        rows = function (rows) {
            return new Rows(table, rows);
        },
        // Function used to convert options data to thead and tbody
        dataToTable = function() {
            var thead = false,
                tbody = false;

            table.data = table.data || options.data;

            if (table.data.headings) {
                thead = createElement("thead");
                var tr = createElement("tr");

                // Add the expandable header for row details
                if (options.expand) {
                    var th = createElement("th", {
                        title: "expand details"
                    });
                    tr.appendChild(th);
                    table.expand = options.expand;
                }
                if (options.select) {
                    th = createElement("th");
                    th.appendChild(createElement("input", {
                        type: "checkbox",
                        class: prefix + "table-cmplx-select-all",
                        title: "select all rows"
                    }));
                    tr.appendChild(th);
                    table.select = options.select;
                }
                each(table.data.headings, function (col) {
                    var th = createElement("th", {
                        scope: "col",
                        html: col
                    });
                    tr.appendChild(th);
                });
                thead.appendChild(tr);
            }

            if (table.data.rows && table.data.rows.length) {
                tbody = createElement("tbody");
               
                each(table.data.rows, function (row, idx) {
                    row.dataIndex = idx;
                    if (table.data.headings) {
                        if (options.data.headings.length !== row.data.length) {
                            throw new Error(
                                "Row found at index, [ "+idx+" ] that does not match the number of headings supplied."
                            );
                        }
                    }

                    var tr = createElement("tr");
                    //Add secondary accordion arrow for details
                    if(options.expand) {
                        tr.appendChild(renderExpand());
                    }
                    //Add selectable checkboxes
                    if(options.select) {
                        tr.appendChild(renderSelect());
                    }
                    each(row.data, function (value) {
                        var td = createElement("td", {
                            html: value
                        });
                        td.setAttribute("data-content", getText(value));
                        tr.appendChild(td);
                    });
                    
                    tbody.appendChild(tr);

                    if (row.details) {
                        tr.details = row.details;
                    }

                    // Secondary accordion html for details
                    if (options.expand){
                        tbody.appendChild(renderDetails(tr, table.headings.length));
                    }
                    
                });
            }

            if (thead) {
                if (table.tHead !== null) {
                    table.removeChild(table.tHead);
                }
                table.appendChild(thead);
            }

            if (tbody) {
                if (table.tBodies.length) {
                    table.removeChild(table.tBodies[0]);
                }
                table.appendChild(tbody);
            }
        },
        setRenderColumns = function() {
            if (selectedColumns.length) {
                each(table.data, function (row) {
                    each(row.cells, function (cell, i) {
                        if (selectedColumns.indexOf(i) > -1) {
                            each(columnRenderers, function (renderers) {
                                if (renderers.columns === cell.cellIndex) {
                                    cell.innerHTML = renderers.renderer.call(self, cell.data, cell, row);
                                }
                            });
                        }
                    });
                });
            }
        },
        renderDetails = function(row, length, stripeClass, selected) {

            var clazz = prefix + "table-cmplx-row-details";
            if (stripeClass) {
                clazz = stripeClass + " " + clazz;
            }
            if (selected) {
                clazz += " " + "selected";
            }
           
            var detailsRow = createElement("tr", {
                class: clazz
            });
            var detailsCell = createElement("td", {
                class :  prefix + "table-complx-details",
                colspan: length
            });
            var accordionBody = createElement("div",{
                class : prefix + "table-complx-details-body"
            });
            if (row.details) {
                accordionBody.innerHTML = row.details;
            } else {
                row.details = false;
            }
            detailsCell.appendChild(accordionBody);
            detailsRow.appendChild(detailsCell);
            return detailsRow;
        },
        renderExpand = function() {
            var td = createElement("td");
            var showButton = createElement("button", {
                    class: prefix + "table-cmplx-accordion-btn " + prefix + "collapsed", 
                    "aria-expanded": false,
                    "aria-label": "expand details"
                }),
                svgElem = renderSvg([{name:options.expandIcon, show:true},{name:"loading-sqrs", show:false}]);
            showButton.appendChild(svgElem);
            td.appendChild(showButton);
            return td;
        },
        renderSelect = function() {
            var td = createElement("td", {
                class: prefix + "table-cmplx-row-select"
            });
            var inputCheckbox = createElement("input", {
                type: "checkbox",
                title: "row select"
            });
            td.appendChild(inputCheckbox);
            return td;
        },
        renderCustomRow = function(row) {
            var template = "<div class='" + prefix + "table-cmplx-top'>";
            template += row;
            template += "</div>";

            each(row.match(/\{((\w*|\d*):?)+\}/g), function(el) {
                var replace = el;
                var clean = el.replace(/[{,}]/g, "").split(":");
                switch(clean[0]) {
                case "actions": template = template.replace(replace, renderActions(clean[1], clean[2])); break;
                case "search": template = template.replace(replace, renderSearch(clean[1], clean[2])); break;
                case "settings": template = template.replace(replace, renderSettings(clean[1], clean[2])); break;
                case "placeholder": template = template.replace(replace, renderPlaceholder(clean[1])); break;
                default : 
                    var topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "justify-content-" + clean[2] + "' style='flex-grow: " + clean[1] +";'>";
                    topCtnr += window[clean[0]]()+"</div>";
                    template = template.replace(replace, topCtnr);
                    
                }
            });
            return template;
        },
        createActionButton = function(html, js, dataToggle, dataTarget) {
            var button;
            if (dataToggle && dataTarget) {
                button = createElement("button", {
                    class: prefix + "dropdown-item",
                    role :"menuitem",
                    onclick: js ? js : "javascript: void(0);",
                    html: html,
                    tabindex: "-1",
                    data_toggle: dataToggle,
                    data_target: dataTarget
                });
            } else {
                button = createElement("button", {
                    class: prefix + "dropdown-item",
                    role :"menuitem",
                    onclick: js ? js : "javascript: void(0);",
                    html: html,
                    tabindex: "-1"
                });
            }
            return button;
        },
        createActionLi = function () {
            return createElement("li", {
                class: prefix + "dropdown-list-item",
                role: "none presentation",
                tabindex: "0"
            });
        },
        renderActions = function(flexGrow, justifyContent) {
            var topCtnr;
            if (options.select) {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "justify-content-" + justifyContent +"'";
                flexGrow ? topCtnr += " style='flex-grow: " + flexGrow + ";'>" :  +">";
                var cmplxAction = createElement("div", {
                    class: prefix + "table-cmplx-action"
                });
                var cmplxActionLabel = createElement("label", {
                    class: prefix + "table-cmplx-action-label",
                    html: options.text.batchActions,
                    for: batchId+"BatchActions"
                });
                cmplxAction.appendChild(cmplxActionLabel);

                var cmplxActionDropdown = createElement("div", {
                    class: prefix + "mb-0 " + prefix + "btn-dropdown"
                });
                var anchor = createElement("button", {
                    id: batchId+"BatchActions",
                    class: prefix + "btn " + prefix + "btn-secondary " + prefix + "table-cmplx-action-button" ,
                    tabindex: "0",
                    "data-toggle": prefix + "dropdown",
                    "data-target": "#cmplxTablesActions",
                    "aria-expanded": "false",
                    "aria-controls": "cmplxTablesActions",
                    html: options.text.chooseActions
                });
                
                var svgElem = renderSvg([{name:"arrow-tri-solid-right", show:true}]);
                var svgClass= svgElem.getAttribute("class");
                svgElem.setAttribute("class", svgClass + " " + prefix + "arrow-tri-solid-right");
                anchor.appendChild(svgElem);
                cmplxActionDropdown.appendChild(anchor);

                var unordered = createElement("ul", {
                    id: "cmplxTablesActions",
                    class: prefix + "button-dropdown-container " + prefix + "collapse",
                    role: "menu"
                });

                each(options.actionsSelectFilters, function(action) {
                    if ("hr" === action) {
                        unordered.appendChild(createElement(action,{class: prefix+"dropdown-divider"}));
                    } else {
                        var actionLI = createActionLi();
                        var actionLabel;
                        if (typeof action === "string") {
                            if (action === options.text.exportCsv) { 
                                actionLabel = createActionButton(action, null, "dds__modal", "#"+prefix+"csvSpecialChars");
                            } else {
                                actionLabel = createActionButton(action);
                            }
                        } else {
                            actionLabel = createActionButton(action.html, action.js);
                        }
                        actionLI.appendChild(actionLabel);
                        unordered.appendChild(actionLI);
                    }
                });
                
                anchor.setAttribute("disabled","");

                cmplxActionDropdown.appendChild(unordered);
                cmplxAction.appendChild(cmplxActionDropdown);

                topCtnr += cmplxAction.outerHTML + "</div>";
            } else {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr'></div>";
            }

            return topCtnr;
        },
        renderSearch = function(flexGrow, justifyContent) {
            var topCtnr;
            if (options.search) {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "justify-content-" + justifyContent +"'";
                flexGrow ? topCtnr += " style='flex-grow: " + flexGrow + ";'>" :  +">";
                var inputGroup = createElement("div", {
                    class: prefix + "table-cmplx-input-group"
                });
                var searchCtnr = createElement("div", {
                    class: prefix + "table-cmplx-search"
                });
                var searchIcon = renderSvg([{name:"search", show:true}]);
                var searchInput = createElement("input", {
                    type: "search",
                    class: prefix + "form-control ",
                    "aria-label": "search"
                });
                if (options.search && options.text.search.placeholder) {
                    searchInput.setAttribute("placeholder", options.text.search.placeholder);
                }
                searchCtnr.appendChild(searchInput);
                searchCtnr.appendChild(searchIcon);
                if (options.search && options.text.search.label) {
                    var searchLabel = createElement("label", {
                        for: searchId + "Search",
                        html: options.text.search.label
                    });
                    searchInput.setAttribute("id",searchId+"Search");
                    inputGroup.appendChild(searchLabel);
                    inputGroup.appendChild(searchCtnr);
                    topCtnr += inputGroup.outerHTML + "</div>";
                } else {
                    topCtnr += searchCtnr.outerHTML + "</div>";
                }
            } else {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr'></div>";
            }

            return topCtnr;
        },
        renderSettings = function(flexGrow, justifyContent) {
            var topCtnr;
            if (options.settings) {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "justify-content-" + justifyContent +"'";
                flexGrow ? topCtnr += " style='flex-grow: " + flexGrow + ";'>" :  +">";
                var optGear = "<div class='" + prefix + "table-cmplx-settings'>";
                
                if (options.import) {
                    optGear += "<button class='"+prefix+"table-cmplx-settings-button dds__text-truncate' aria-label='table settings import'>";
                    var svgElem = renderSvg([{name:"import-alt", show:true}]);
                    optGear += svgElem.outerHTML || new XMLSerializer().serializeToString(svgElem);
                    optGear += " " + options.text.import +"</button>";
                    optGear += "<input class='dds__table-cmplx-file-import' type='file' hidden accept='" + options.allowedImportExtensions + "'/>";
                }
                if (options.print) {
                    optGear += "<button class='"+prefix+"table-cmplx-settings-button dds__text-truncate' aria-label='table settings print'>";
                    svgElem = renderSvg([{name:"printer", show:true}]);
                    optGear += svgElem.outerHTML || new XMLSerializer().serializeToString(svgElem);
                    optGear += " " + options.text.print +"</button>";
                }
                if (options.column) {
                    optGear += "<button class='"+prefix+"table-cmplx-settings-button dds__text-truncate' aria-label='table settings columns'>";
                    svgElem = renderSvg([{name:"gear", show:true}]);
                    optGear += svgElem.outerHTML || new XMLSerializer().serializeToString(svgElem);
                    optGear += " " + options.text.columns +"</button>";
                }
                
                optGear += columnBox = "<div class=\"" + prefix + "column-box";
                optGear += "\" data-toggle=\"data-column-box\"></div>";
                optGear += "</div>";
                topCtnr += optGear + "</div>";
            } else {
                topCtnr = "<div class='" + prefix + "table-cmplx-top-cntr'></div>";
            }

            return topCtnr;
        },
        renderPlaceholder = function (flexGrow) {
            return "<div class='" + prefix + "table-cmplx-top-cntr " + prefix + "table-cmplx-placeholder' style='flex-grow: " + flexGrow + ";'></div>";
        },
        render = function() {
 
            var template = "";

            // Convert data to HTML
            if (options.data) {
                dataToTable(options.data);
            }

            body = table.tBodies[0];
            head = table.tHead;
    
            // Should move this to dataToTable method
            if (!body) {
                body = createElement("tbody");
                table.appendChild(body);
            }
    

            table.hasRows = body.rows.length > 0;
    
            // Make a tHead if there isn't one (fixes #8)
            // Should move this to dataToTable method
            if (!head) {
                var h = createElement("thead");
                var t = createElement("tr");
    
                if (table.hasRows) {
                    each(body.rows[0].cells, function () {
                        t.appendChild(createElement("th"));
                    });
    
                    h.appendChild(t);
                }
    
                head = h;
    
                table.insertBefore(head, body);
            }
    
            table.hasHeadings = head.rows.length > 0;
    
            if (table.hasHeadings) {
                header = head.rows[0];
                table.headings = [].slice.call(header.cells);
            }
    
            // Header
            if (!options.header) {
                if (head) {
                    table.removeChild(table.tHead);
                }
            }
    
            // Build
            wrapper = createElement("div", {
                class: prefix + "table-cmplx-wrapper"
            });
    
            //////////////////////////////////////////////////////
            // Top template - Begin
            //////////////////////////////////////////////////////
   
            if (options.layout.row1) {
                template += renderCustomRow(options.layout.row1);
            }

            if (options.layout.row2) {
                template += renderCustomRow(options.layout.row2);
            }

            //////////////////////////////////////////////////////
            // Top template - End
            //////////////////////////////////////////////////////

            template += "<div class='" + prefix + "table-cmplx-container'></div>";
            
            //////////////////////////////////////////////////////
            // Bottom template - Begin
            //////////////////////////////////////////////////////

            template += "<div class='" + prefix + "table-cmplx-bottom'>";
            template += "</div>";
    
            //////////////////////////////////////////////////////
            // Bottom template - End
            //////////////////////////////////////////////////////

            if (table.hasHeadings) {
                // Sortable
                renderHeader();
            }
    
            // Add table class
            table.classList.add(prefix + "table-cmplx");
            
            wrapper.innerHTML = template;
    
            container = wrapper.querySelector("." + prefix + "table-cmplx-container");

            bottom = wrapper.querySelector("." + prefix + "table-cmplx-bottom");
    
            // Pagination
            options.pagination = getSibling(element, ".dds__pagination") ? true : false;
            if (options.pagination) {
                paginationElement = getSibling(element, ".dds__pagination");
                bottom.appendChild(paginationElement);
            }

            columnBox = wrapper.querySelector("[data-toggle='data-column-box']");
    
            // Insert in to DOM tree
            table.parentNode.replaceChild(wrapper, table);
            container.appendChild(table);
    
            // Store the table dimensions
            rect = table.getBoundingClientRect();
    
            // Convert rows to array for processing
            table.data = [];
            table.activeRows = [];

            var dataIdx = 0;
            each(body.rows, function(row, idx) {
                if (options.expand) {
                    if (idx % 2 == 0) {
                        row.dataIndex = dataIdx++;
                        table.activeRows.push(row);
                        table.data.push(row);
                    }
                } else {
                    row.dataIndex = idx;
                    table.activeRows.push(row);
                    table.data.push(row);
                }
            }, true);

            table.activeHeadings = table.headings.slice();

            // Update
            self.update();
    
            // Set Columns
            setColumns();

            // Fix columns, think this is the only valid call for fixColumns
            fixColumns();

            // Options condensed
            if (options.condensed) {
                wrapper.querySelector("." + prefix + "table-cmplx").classList.add(prefix + "condensed");
            }
            
            // This needs to run after the options.condensed
            if (options.fixedHeight) {
                if (options.origPerPage > table.activeRows.length) {
                    metHeight = false;
                } else {
                    // Fix height
                    fixHeight();
                }
            }
    
            bindEvents();
        },
        renderPage = function () {
            if (options.search && searching === true && table.searchData.length === 0) return;
            if (table.hasRows && totalPages) {
    
                // Use a fragment to limit touching the DOM
                var index = currentPage - 1,
                    frag = DOC.createDocumentFragment();
    
                var page = pages[index];
                var selectHeader;
                var selectCellIdx;
                
                if (table.hasHeadings) {
                    flush(header, isIE);
                    
                    each(table.activeHeadings, function (th) {
                        if (options.select) {
                            each(th.children, function(el) {
                                if (el && el.classList.contains(prefix + "table-cmplx-select-all")) {
                                    selectHeader = el;
                                    selectCellIdx = th.originalCellIndex;
                                    if(page.selected) {
                                        selectHeader.checked = true;
                                        selectHeader.state = "checked"; // set the state
                                    } else {
                                        selectHeader.checked = false;
                                        selectHeader.state = "unchecked"; // set the state
                                    }
                                } 
                            });
                        }
                        // reest sortable columns
                        if (th.hasAttribute("data-sortable") && th.originalCellIndex != sortedColumnIdx) {
                            th.setAttribute("aria-sort", "none");
                        }
                        header.appendChild(th);
                    }, this);
                }
                
                var selectedRows = 0;
                each(page, function (row, idx) {
                    //row.className = "";
                    row.removeAttribute("class");
                    var selected = false;
                    // redo if previously selected
                    if (options.select) {
                        if(table.data[row.dataIndex].cells[selectCellIdx].querySelector("input[type='checkbox']").checked) {
                            row.classList.add("selected");
                            selected = true;
                            selectedRows++;
                            if (!selectHeader.checked) {
                                selectHeader.indeterminate = true;
                                selectHeader.state = "indeterminate"; // set the state
                            }
                        }
                    }
                    // redo if stripped table
                    if (table.classList.contains(prefix + "table-striped")) {
                        var stripeColor = idx % 2 > 0 ? prefix + "table-cmplx-row-odd" : prefix + "table-cmplx-row-even";
                        row.classList.add(stripeColor);
                    }
                    frag.appendChild(rows().render(row));
                    if(options.expand) {
                        row.querySelector("." + prefix + "table-cmplx-accordion-btn").classList.add(prefix + "collapsed");
                        var detailsRow = renderDetails(row, table.headings.length, stripeColor, selected);
                        detailsRow.children[0].classList.remove(prefix + "show");
                        frag.appendChild(detailsRow);
                    }
                }, this);

                if (options.select) {
                    if (selectedRows === page.length) {
                        selectHeader.indeterminate = false;
                        selectHeader.checked = true;
                        selectHeader.state = "checked";
                    } else if (selectedRows === 0 && options.select) {
                        selectHeader.indeterminate = false;
                        selectHeader.checked = false;
                        selectHeader.state = "unchecked";
                    }
                    handleSelectAllChange(selectHeader.state);
                }
                self.clear(frag);
    
            } else if (searching && totalPages == 0) {
                if (table.hasHeadings) {
                    flush(header, isIE);
    
                    each(table.activeHeadings, function (th) {
                        header.appendChild(th);
                    }, this);
                }
            } else {
                self.clear();
            }
            if(!metHeight) {
                fixHeight();
            }
        },
        renderHeader = function () {
    
            labels = [];
    
            if (table.headings && table.headings.length) {

                each(table.headings, function (th, i) {
    
                    labels[i] = th.textContent;
    
                    th.sortable = th.hasAttribute("data-sortable");
    
                    th.originalCellIndex = i;
                    if (options.sort && th.sortable) {
                        var sortLabel = createElement("label", {
                            class: prefix + "table-cmplx-sorter",
                            html: th.textContent
                        });
                        th.textContent = "";
                        th.setAttribute("tabindex","0");
                        th.appendChild(sortLabel);
                    }
                });
            }
    
            fixColumns();
        },
        getHeaderCellIndex = function(selector) {
            var cellIdx = 0;
            each(table.activeHeadings, function(header) {
                each(header.children, function(el) {
                    if (el.classList.contains(selector)) {
                        cellIdx = header.originalCellIndex;
                    }
                });
            });
            return cellIdx;
        },
        bindEvents = function () {

            // Batch Actions
            if (options.select) {
                var actionSelect = wrapper.querySelector("#cmplxTablesActions");
                actionSelect.addEventListener("keydown", function(e) {
                    if (e.keyCode == 13) {
                        e.preventDefault();
                        var actionButton = e.target.querySelector("button");
                        if (actionButton.innerHTML ===  options.text.deleteRow) {
                            handleDeleteActionEvent();
                        } else if (actionButton.innerHTML === options.text.exportCsv ) {
                            createSpecialCharModal();
                        } else if (actionButton.innerHTML === options.text.exportJson) {
                            handleExportActionEvent(e.target);
                        }
                    }
                });
                actionSelect.addEventListener("mousedown", function(e) {
                    e.preventDefault();
                    var actionButton = e.target;
                    if (actionButton.innerHTML ===  options.text.deleteRow) {
                        handleDeleteActionEvent();
                    } else if (actionButton.innerHTML === options.text.exportCsv ) {
                        createSpecialCharModal();
                    } else if (actionButton.innerHTML === options.text.exportJson) {
                        handleExportActionEvent(e.target);
                    }
                });
            } 

            // Search
            if (options.search) {
                input = wrapper.querySelector("." + prefix + "table-cmplx-search").querySelector("input[type=search]");
                if (input) {
                    input.addEventListener("keyup",function (e) {
                        if (e.keyCode === 9 || e.keyCode === 16 || e.keyCode === 13) return;
                        self.search(input.value);
                        e.preventDefault();
                    }, false);
                    input.addEventListener("mouseup", function (e) {
                        var origInput = input.value;
                        setTimeout ( function() {
                            if (input.value == "" && origInput != "") { //the 'x' was pressed and removed the orig query string
                                self.search(input.value);
                            }
                        }, 100);
                        e.preventDefault();
                    }, false);
                }
            }
    
            // Settings
            if (options.settings) {
                var settingsCtnr = wrapper.querySelector("." + prefix + "table-cmplx-settings");
                if (options.column) {
                    var button = settingsCtnr.querySelector("button[aria-label='table settings columns']");
                    button.addEventListener("click", function(e) {
                        if(columnBox.classList.contains(prefix + "show")) {
                            columnBox.classList.remove(prefix + "show");
                        } else {
                            renderSettingsDropDown();
                            columnBox.classList.add(prefix + "show");
                            columnBox.addEventListener("click", function(e) {
                                var t = e.target;
                                if (t.nodeName.toLowerCase() === "button") {
                                    handleButtonEvent(t);
                                    settingsCtnr.querySelector("button[aria-label='table settings columns']").focus();
                                } else if(t.nodeName.toLowerCase() === "input") {
                                    var applyButton = columnBox.querySelector("."+prefix+"btn-primary");
                                    if (applyButton.disabled) {
                                        applyButton.removeAttribute("disabled");
                                    }
                                }
                            });
                        }
                        e.preventDefault();
                    }, false);
                }
                
                if (options.print) {
                    button = settingsCtnr.querySelector("button[aria-label='table settings print']");
                    button.addEventListener("click", function(e) {
                        self.print();
                        e.preventDefault();
                    }, false);
                }

                if (options.import) {
                    var fileImport = settingsCtnr.querySelector("."+prefix+"table-cmplx-file-import");
                    fileImport.addEventListener("change", handleImportActionEvent, false);
                    
                    button = settingsCtnr.querySelector("button[aria-label='table settings import']");
                    button.addEventListener("click", function(e) {
                        fileImport.click();
                        e.preventDefault();
                    }, false);
                }
            }        
    
            // All listeners with in the table are should use wrapper.
            // Pager(s) / sorting
            wrapper.addEventListener("keydown" ,function (e) {
                var t = e.target;
                // Checks for headers
                if (t.nodeName.toLowerCase() === "th" && e.keyCode === 13) { 
                    handleTableHeaderEvent(e,t);
                }
                // Checks for buttons
                if (t.nodeName.toLowerCase() === "button" && e.keyCode === 13 && t.classList.contains(prefix+"table-cmplx-accordion-btn")) {
                    handleAccordionEvent(t);
                    e.preventDefault();
                }
            }, false);

            wrapper.addEventListener("click" ,function (e) {
                var t = e.target;
                // Checks for headers
                if (t.classList.contains(prefix+"table-cmplx-sorter")) { 
                    handleTableHeaderEvent(e,t.parentNode); 
                }
                // Checks for buttons
                if (t.nodeName.toLowerCase() === "button") {
                    handleAccordionEvent(t);
                    e.preventDefault();
                }
                // Checks for inputs
                if (t.nodeName.toLowerCase() === "input") {
                    if(t.type === "checkbox") {
                        handleCheckBoxEvent(t);
                    }
                }
            }, false);
        },
        setColumns = function () {
            each(table.data, function (row) {
                each(row.cells, function (cell) {
                    cell.data = cell.innerHTML;
                });
            });
           
            // Check for the columns option
            if (options.data.columns && table.headings.length) {
    
                each(options.data.columns, function (data) {
                   
                    // convert single column selection to array
                    if (!isArray(data.select)) {
                        data.select = [data.select];
                    }
    
                    if (Object.prototype.hasOwnProperty.call(data, "render") && (typeof data.render === "function" || (typeof data.render === "string" && window[data.render]))) {
                        var nCIdx = parseInt(data.select)+baseCellIdx();
                        selectedColumns = selectedColumns.concat(nCIdx);
    
                        columnRenderers.push({
                            columns: nCIdx,
                            renderer: window[data.render]
                        });
                    }
                   
                    // Add the data attributes to the th elements
                    each(data.select, function (column) {
                        var th = table.headings[baseCellIdx()+column];
                        if (data.type) {
                            th.setAttribute("data-type", data.type);
                        }
                        if (data.format) {
                            th.setAttribute("data-format", data.format);
                        }
                        if (options.sort) {
                            if (Object.prototype.hasOwnProperty.call(data, "sortable") && data.sortable) {
                                th.setAttribute("data-sortable", "");
                                th.setAttribute("aria-sort","none");
                            }
                            if (Object.prototype.hasOwnProperty.call(data, "sort")) {
                                th.setAttribute("data-sortable", "");
                                th.setAttribute("aria-sort","none");
                            }
                        }
                        if (Object.prototype.hasOwnProperty.call(data, "fixed")) {
                            th.setAttribute("data-fixed", data.fixed);
                        }
                        if (Object.prototype.hasOwnProperty.call(data, "control")) {
                            th.setAttribute("data-control", data.control);
                        }
                        if (Object.prototype.hasOwnProperty.call(data, "hidden")) {
                            if (data.hidden) {
                                columns().hide([baseCellIdx()+column]);
                                self.update();
                            }
                        }
                        if (Object.prototype.hasOwnProperty.call(data, "sort") && data.select.length ==1 ) {
                            sortedColumnIdx = baseCellIdx()+data.select[0];
                            columns().sort(sortedColumnIdx, data.sort);
                            self.update();
                            uicoreCustomEvent("Table", "SortEvent", table, { "column" : sortedColumnIdx, "direction" : (data.sort === "asc" ? "ascending":"descending"), "currentPage": currentPage, "perPage": options.perPage });
                        }
                    });
                });
            }
    
            if (table.hasRows) {
                each(table.data, function (row, idx) {
                    row.dataIndex = idx;
                    each(row.cells, function (cell) {
                        cell.data = cell.innerHTML;
                    });
                });
    
                setRenderColumns();
                columns().rebuild();
                self.update();
            }

            renderHeader();
            
        },
        renderSettingsDropDown = function() {

            flush(columnBox, isIE);
            var columnContainer = createElement("div", {
                class: prefix + "table-cmplx-column-cntr"
            });

            var unOderedList = createElement("ul");
        
            each(table.headings, function(header, idx) {

                if (idx >= baseCellIdx() && !header.dataset["fixed"] && (options.rearrangeableColumns || header.dataset["control"] !== "false")) {
                    var listItem;
                    var dragSvg;
                    if (options.rearrangeableColumns) {
                        listItem = createElement("li", {
                            class: prefix + "table-cmplx-drag-li",
                            draggable: true
                        });
                        dragSvg = renderSvg([{name:"handle", show:true}]);
                        setupDragEvents(listItem);
                        moveableCols.push(listItem);
                    } else {
                        listItem = createElement("li", {
                            class: prefix + "table-cmplx-li"
                        });
                    }
                    var columnLabel = createElement("label",{ 
                        for: idx,
                        class: prefix + "text-truncate"
                    });
                    var boxLabel = createElement("span");
                    if (header.dataset["control"] !== "false") {
                        var columnInput = createElement("input",{   id: idx,
                            type: "checkbox"
                        });
                        columnInput.checked = columns().visible(idx);
                        columnLabel.appendChild(columnInput);
                    } else {
                        boxLabel.style.marginLeft = "1.75rem";
                    }
                    boxLabel.innerHTML = header.textContent;
                    columnLabel.appendChild(boxLabel);
                    if(dragSvg) {
                        listItem.appendChild(dragSvg);
                    }
                    listItem.appendChild(columnLabel);
                    unOderedList.appendChild(listItem);
                    columnContainer.appendChild(unOderedList);
                }
            });
            if (columnContainer.querySelectorAll("ul").length === 0) {
                var emptySpan = createElement("span", {
                    class: prefix + "text-wrap",
                    html: "Cannot rearrange or turn columns on/off."
                });
                columnContainer.appendChild(emptySpan);
            }
            var buttonContainer = createElement("div", {
                class: prefix + "row " + prefix + "no-gutters"
            });
            var cancelButton = createElement("button", {
                class: prefix + "btn " + prefix + "btn-secondary",
                html: options.text.cancel
            });
            var applyButton = createElement("button", {
                class: prefix + "btn " + prefix + "btn-primary",
                html: options.text.apply,
                disabled: ""
            });
            buttonContainer.appendChild(cancelButton);
            buttonContainer.appendChild(applyButton);
            columnContainer.appendChild(buttonContainer);
            columnBox.appendChild(columnContainer);
        },
        paginate = function() {

            var perPage = options.perPage,
                rows = table.activeRows;
                
            if (searching) {
                rows = [];
    
                each(table.searchData, function (index) {
                    rows.push(table.activeRows[index]);
                }, this);
            }
    
            // Check for hidden columns
            pages = rows
                .map(function (tr, i) {
                    return i % perPage === 0 ? rows.slice(i, i + perPage) : null;
                })
                .filter(function (page) {
                    return page;
                });
    
            totalPages = pages.length;
        },
        fixColumns = function () {
            rect = table.getBoundingClientRect();
            if (   options.fixedColumns 
                && table.activeHeadings 
                && table.activeHeadings.length
                && !columnsFixed) {
                var cells,
                    hd = false,
                    reducePx = 0;
    
                // If we have headings we need only set the widths on them
                // otherwise we need a temp header and the widths need applying to all cells
                if (table.tHead) {
                    each(table.activeHeadings, function (cell) {
                        cell.style.maxWidth = "";
                        cell.style.width = "";
                    });
                    var expandDone, selectDone = false;
                    each(table.activeHeadings, function (cell, i) {
                        if ((i==0) && options.expand && !expandDone){
                            expandDone = true;
                            cell.style.maxWidth = "20px";
                            cell.style.width = "20px";
                            reducePx += 20;
                        }
                        else if ((i==0||i==1) && options.select && !selectDone){
                            selectDone = true;
                            cell.style.maxWidth = "20px";
                            cell.style.width = "20px";
                            reducePx += 20;
                        }
                        else {
                            var ow = cell.offsetWidth;
                            var w = ow / (rect.width - reducePx) * 100;
                            cell.style.width = w + "%";
                        }
                    });
                } else {
                    cells = [];
    
                    // Make temperary headings
                    hd = createElement("thead");
                    var r = createElement("tr");
                    each(table.tBodies[0].rows[0].cells, function() {
                        var th = createElement("th");
                        r.appendChild(th);
                        cells.push(th);
                    });
    
                    hd.appendChild(r);
                    table.insertBefore(hd, body);
    
                    var widths = [];
                    each(cells, function (cell, i) {
                        if ((i==0) && options.expand){
                            reducePx += 20;
                        }
                        else if((i==0||i==1) && options.select){
                            reducePx += 20;
                        } 
                        else {
                            var ow = cell.offsetWidth;
                            var w = ow / (rect.width - reducePx) * 100;
                            widths.push(w);
                        }
                    }, this);
    
                    each(table.data, function (row) {
                        each(row.cells, function (cell, i) {
                            if((i==0) && options.expand){
                                cell.style.width = "20px";
                            }
                            else if ((i==0||i==1) && options.select){
                                cell.style.width = "20px";
                            }
                            else if (columns().visible(baseCellIdx()+cell.cellIndex))
                                cell.style.width = widths[i] + "%";
                        }, this);
                    }, this);
    
                    // Discard the temp header
                    table.removeChild(hd);
                }
                columnsFixed = true;
            }
        },
        fixHeight = function () { 
            if (options.fixedHeight) {
                container.style.height = null;
                rect = container.getBoundingClientRect();
                container.style.height = rect.height + "px";
            }
            metHeight = options.origPerPage <= table.activeRows.length && options.perPage == options.origPerPage;
        },
        resetCollapse = function() {
            each(table.activeRows, function(row) {
                var button = row.querySelector("."+ prefix + "table-cmplx-accordion-btn");
                if(button && !button.classList.contains(prefix + "collapsed")) {
                    button.classList.add(prefix + "collapsed");
                }
            });
        },
        insert = function (data) {
            var newrows = [];
            if (isObject(data)) {
                if (data.headings) {
                    if (!table.hasHeadings && !table.hasRows) {
                        var tr = createElement("tr"),
                            th;
                        each(data.headings, function (heading) {
                            th = createElement("th", {
                                html: heading
                            });

                            tr.appendChild(th);
                        });
                        head.appendChild(tr);

                        header = tr;
                        table.headings = [].slice.call(header.cells);
                        table.hasHeadings = true;

                        // Re-enable sorting if it was disabled due
                        // to missing header
                        // options.sort = initialSortable;
                    }
                }

                if (data.rows && isArray(data.rows)) {
                    each(data.rows, function (row, rowIdx) {
                        var headerCount = [];
                        for (var l = 0; l < labels.length; l++) {
                            headerCount.push(l);
                        }
                        var padR = 0,
                            r = [];
                        if (options.expand) {
                            r[0] = renderExpand().outerHTML;
                            padR++;
                            delete headerCount[0];
                        }
                        if (options.select) {
                            var selectData = renderSelect().outerHTML;
                            if (options.expand) {
                                r[1] = selectData;
                                padR++;
                                delete headerCount[1];
                            } else {
                                r[0] = selectData;
                                padR++;
                            }
                        }
                        each(row, function (cell, idx) {
                            var index;
                            if(data.headings) {
                                index = labels.indexOf(data.headings[idx]);
                                delete headerCount[index];
                            } else {
                                index = idx;
                            }
                            
                            if (index > -1) {
                                r[index] = cell;
                            }
                        });
                        for (var z = 0; z < headerCount.length; z++) {
                            if (headerCount[z]) {
                                r[headerCount[z]] = "";
                            }
                        }
                        if (r.length == (data.headings ? labels.length : (padR + labels.length))) {
                            newrows.push(r);
                        } else {
                            var msg = "Row found at index, [ "+rowIdx+" ] that did not match the current headers.";
                            console.error(msg);
                            uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                        }
                    });
                }
            }

            if (newrows.length) {
                if(data.details) {
                    rows().add(newrows, data.details);
                } else {
                    rows().add(newrows);
                }
                columns().rebuild();
                if(searching) {
                    var query = wrapper.querySelector("input[type='search']").value;
                    self.search(query);
                }
                table.hasRows = true;
            }

            fixColumns();
            setRenderColumns();
            columns().rebuild();
            self.update();

            if (!metHeight) {
                fixHeight();
            }
        },
        createSpecialCharModal = function(){
            var modal = document.getElementById(prefix +"csvSpecialChars");
            if (!modal) {
                var modalEl = createElement("div", {
                    class: prefix + "modal " + prefix + "fade",
                    role: "dialog",
                    aria_labelledby: prefix + "modal-title",
                    id: prefix+"csvSpecialChars",
                    tabindex: "-1",
                    html: "<div class=\"dds__modal-dialog\" role=\"document\"><div class=\"dds__modal-content\"><div class=\"dds__modal-header\">" +
                        "<h3 class=\"dds__modal-title\" id=\"dds__modal-title1\">CSV with Special Characters</h3></div><button class=\"dds__close\" data-dismiss=\"dds__modal\" aria-label=\"Close\"><span class=\"dds__icons dds__close-x\" aria-hidden=\"true\"></span></button>" +
                        "<div class=\"dds__modal-body\"><p>If you are exporting table data that includes special characters, then you may need to use the following process to see these within Excel:</p><ol class=\"dds__list-group\">" +
                            "<li class=\"dds__list-group-item-ordered\">Open <strong>Excel.</strong></li>" +
                            "<li class=\"dds__list-group-item-ordered\">Open the <strong>Data menu.</strong></li>" +
                            "<li class=\"dds__list-group-item-ordered\">Select the option <strong>From Text/CSV.</strong></li>" +
                            "<li class=\"dds__list-group-item-ordered\">Select the <strong>CSV file.</strong> This should open an import dialog.</li>"+
                            "<li class=\"dds__list-group-item-ordered\">Go to <strong>File Origin </strong> menu and choose the Unicode (UTF-8) options.</li>" +
                            "<li class=\"dds__list-group-item-ordered\"><strong>Load</strong> the file to complete the process.</li>" +
                        "</ol>" +
                        "</div>" +
                        "<div class=\"dds__modal-footer\">" +
                            "<button class=\"dds__btn dds__btn-secondary\" data-dismiss=\"dds__modal\">Cancel</button>" +
                            "<button class=\"dds__btn dds__btn-primary\">Continue Export</button>" +
                        "</div>" +
                        "</div>" +
                        "</div>"
                });
                wrapper.appendChild(modalEl);
                var batchActions = wrapper.querySelector("."+prefix+"table-cmplx-action ." + prefix + "btn-dropdown");
                var csvBtn = batchActions.querySelector("[data-target='#"+prefix+"csvSpecialChars']");
                setTimeout(function() {
                    var csvModal = new Modal(csvBtn, {
                        static: false
                    });
                    csvModal.show();
                    modal = document.getElementById(prefix +"csvSpecialChars");
                    var continueBtn = modal.querySelector("." +prefix +"btn-primary");
                    continueBtn.addEventListener("click", function() {
                        handleExportActionEvent(csvBtn);
                    });
                }, 200);
            }
        },
        isUrl = function (value) {
            return value.match(/(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))?/g);
        },
        isEmail = function(value) {
            /* eslint-disable */ 
            return value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            /* eslint-enable */ 
        },                                 
        modifyText = function(text) {
            text = text.trim();
            text = text.replace(/\s{2,}/g, " ");
            text = text.replace(/\n/g, "  ");
            text = text.replace(/"/g, "\"\"");
            if (text.indexOf(",") > -1) text = "\"" + text + "\"";
            return text;
        };

    if (!options.header) {
        options.sort = false;
    }

    if (table.tHead === null) {
        if (!options.data ||
            (options.data && !options.data.headings)
        ) {
            options.sort = false;
        }
    }

    if (table.tBodies.length && !table.tBodies[0].rows.length) {
        if (options.data) {
            if (!options.data.rows) {
                throw new Error(
                    "You seem to be using the data option, but you've not defined any rows."
                );
            }
        }
    }

    /**
     * Expand a row
     * @param  {number} rowIdx
     * @return {Void} 
     */
    this.expandRow = function(rowIdx) {
        var theRowIdx = validateNum(rowIdx, 0);

        if (typeof theRowIdx === "number") {
            var expandCell = table.activeRows[theRowIdx].querySelector("." + prefix + "table-cmplx-accordion-btn");
            handleAccordionEvent(expandCell);
        }
        return false;
    };

    /**
     * Update the instance
     * @return {Void}
     */
    this.update = function() {
        // Remove class that was set from setMessage if applicable
        if (!(options.search && searching && table.searchData.length == 0)) {
            wrapper.classList.remove(prefix + "table-cmplx-empty");
        }

        paginate();

        renderPage();

        table.sorting = false;

        rows().update();

        if (table.hasRows) {
            table.setAttribute("aria-rowcount", table.activeRows.length);
        } else {
            table.setAttribute("aria-rowcount", 0);
        }

        table.setAttribute("aria-colcount", table.activeHeadings.length);

        uicoreCustomEvent("Table", "UpdateEvent", table);
    };

    /**
     * Perform a search of the data set
     * @param  {string} query
     * @return {void}
     */
    this.search = function(query) {
        if (!table.hasRows) return false;
        
        resetCollapse();
        
        query = query.toLowerCase();

        searching = true;
        table.searchData = [];

        if (!query.length) {
            searching = false;
            if (currentPage != 1) { //if not already on the first page 
                pagination.page(1);
                currentPage = 1;
            }
            this.update();
            pagination.setItems(table.activeRows.length);
            uicoreCustomEvent("Table", "SearchEvent", table, { "query": query, "searchData": table.searchData});
            wrapper.classList.remove("search-results");

            return false;
        }

        this.clear();

        each(table.data, function (row, idx) {
            var inArray = table.searchData.indexOf(row) > -1;

            var doesQueryMatch = query.split(" ").reduce(function (bool, word) {
                
                var includes = false,
                    cell = null,
                    content = null;

                for (var x = baseCellIdx(); x < row.cells.length; x++) {
                    cell = row.cells[x];
                    content = cell.hasAttribute("data-content") ? cell.getAttribute("data-content") : cell.textContent;
                    if (
                        content.toLowerCase().indexOf(word) > -1 &&
                        columns().visible(cell.cellIndex)
                    ) {
                        includes = true;
                        break;
                    }
                }

                return bool && includes;
            }, true);

            if (doesQueryMatch && !inArray) {
                row.searchIndex = idx;
                table.searchData.push(idx);
            } else {
                row.searchIndex = null;
            }
        }, this);

        wrapper.classList.add("search-results");
        if (!table.searchData.length) {
            wrapper.classList.remove("search-results");
            this.setMessage(options.labels.noRows);
        } else {
            if (table.searchData.length > options.perPage && currentPage != 1) { //if not already on the first page 
                pagination.page(1);
            }
            currentPage = 1;
            this.update();
        }
        pagination.setItems(table.searchData.length);
        uicoreCustomEvent("Table", "SearchEvent", table, {"query": query, "searchData": table.searchData});
    };

    /**
     * Change page
     * @param  {int} page
     * @return {void}
     */
    this.page = function(page) {
        
        var newPage;

        if (!isNaN(page)) {
            newPage = parseInt(page, 10);    
        } else {
            newPage = page;
        }

        if (newPage == currentPage || newPage > pages.length || newPage < 0) {
            return false;
        }

        currentPage = newPage;

        renderPage();

        if (options.items > 0) {
            pagination.page(newPage);
        }

        uicoreCustomEvent("Table", "PageChangedEvent", table, { "currentPage": currentPage});
    };

    this.perPage = function(detail) {
        
        var newPerPage, newPage, newPages;
        if (!isNaN(detail.perPage)) {
            newPerPage = parseInt(detail.perPage, 10);
        } else {
            newPerPage = detail.perPage;
        }
        if (!isNaN(detail.page)) {
            newPage = parseInt(detail.page, 10);
        } else {
            newPage = detail.page;
        }
        if (!isNaN(detail.pages)) {
            newPages = parseInt(detail.pages, 10);
        } else {
            newPages = detail.perPage;
        }

        if (options.perPage != newPerPage) {
            options.perPage = newPerPage;
            totalPages = newPages;
            currentPage = newPage;

            this.update();

            if (options.items > 0) {
                pagination.perPage(newPerPage);
            }
            
            if(!metHeight) {
                fixHeight();
            }
        }
    };

    /**
     * Refresh the instance
     * @return {void}
     */
    this.refresh = function () {

        var that = this;
        if (options.search) {
            input.value = "";
            searching = false;
        }
        currentPage = 1;
        that.update();

        uicoreCustomEvent("Table", "RefreshEvent", table);
    };

    /**
     * Truncate the table
     * @param  {mixes} html - HTML string or HTMLElement
     * @return {void}
     */
    this.clear = function (html) {
        
        if (body) {
            flush(body, isIE);
        }

        var parent = body;
        if (!body) {
            parent = table;
        }

        if (html) {
            if (typeof html === "string") {
                var frag = DOC.createDocumentFragment();
                frag.innerHTML = html;
                parent.appendChild(frag);
            } else {
                parent.appendChild(html);
            }
        }
    };

    /**
     * Export table to various formats (csv, txt or sql)
     * @param  {Object} exportOptions User options
     * @return {Boolean}
     */
    this.export = function (exportOptions) {
        if (!table.hasHeadings && !table.hasRows) return false;

        var headers = table.activeHeadings,
            rows = [],
            arr = [],
            i,
            x,
            str,
            link;

        var defaults = {
            download: true,
            skipColumn: [],

            filename: params.exportFileName,
    
            // csv
            lineDelimiter: "\n",
            columnDelimiter: ",",

            // sql
            tableName: "myTable",

            // json
            replacer: null,
            space: 4
        };

        // Check for the options object
        if (!isObject(exportOptions)) {
            return false;
        }

        var o = extend(defaults, exportOptions);

        if (o.type) {
            if (options.select) {
                each(table.selectedRows, function(rowIdx) {
                    rows = rows.concat(table.activeRows[rowIdx]);
                });
            } else {
                if (o.currentPage) {
                    rows = rows.concat(pages[currentPage-1]);
                } else {
                    rows = rows.concat(table.activeRows);
                }
            }
            // Only proceed if we have data
            if (rows.length) {
                var blob;
                if (o.type === "txt" || o.type === "csv") {
                    rows.unshift(header);
                    str = "";

                    each(rows, function(row) {
                        for (x = 0; x < (options.exportDetails ? row.cells.length + 1: row.cells.length) ; x++) {
                            var text;
                            if (x === row.cells.length) {
                                //add details column if exportDetails = true
                                if (rows.indexOf(row) > 0) {
                                    text = table.data[rows.indexOf(row)-1].details ? table.data[rows.indexOf(row)-1].details : null;
                                } else {
                                    text = "Details";
                                }
                                if (text) {
                                    str += modifyText(text) + o.columnDelimiter;
                                }
                            } else if (
                                x < row.cells.length &&
                                o.skipColumn.indexOf(headers[x].originalCellIndex) < 0 &&
                                columns(headers[x].originalCellIndex).visible()
                                // Check for column skip and visibility
                            ) {
                                text = row.cells[x].getAttribute("data-content") ? row.cells[x].getAttribute("data-content") : row.cells[x].textContent;
                                text = modifyText(text);
                                
                                var isLink = row.cells[x].querySelector("a");
                                if (isLink) {
                                    text = isLink.getAttribute("href");
                                    if (text.substring(0,2) === "//") { //Excel won't interpret `//www.example.com` as a hyperlink, but will for links in format `www.example.com` or `http://www.example.com`)
                                        text = text.substring(2, text.length);
                                    } else if (text.substring(0,7) === "mailto:") {
                                        text = text.substring(7,text.length);
                                    }
                                }
                                str += text + o.columnDelimiter;
                            }
                        }
                        // Remove trailing column delimiter
                        str = str.trim().substring(0, str.length - 1);

                        // Apply line delimiter
                        str += o.lineDelimiter;
                    });

                    // Remove trailing line delimiter
                    str = str.trim().substring(0, str.length - 1);

                    if (isIE || isEdge) {
                        str = encodeURI(str);
                        blob = new Blob([decodeURIComponent(str)],{type:"text/csv;charset=UTF-8"});
                    }
                } else if (o.type === "json") {
                    // Iterate rows
                    each(rows, function(row, idx) {
                        arr[idx] = arr[idx] || {};
                        // Iterate columns
                        for (i = 0; i < (options.exportDetails ? headers.length + 1 : headers.length) ; i++) {
                            if (i === headers.length) {
                                if (table.data[rows.indexOf(row)].details) {
                                    arr[idx]["Details"] = table.data[rows.indexOf(row)].details;
                                }
                            } else if (
                                // Check for column skip and column visibility
                                i < row.cells.length &&
                                o.skipColumn.indexOf(headers[i].originalCellIndex) < 0 &&
                                columns(headers[i].originalCellIndex).visible()
                            ) {
                                if (row.cells[i].getAttribute("data-content")) {
                                    var isLink = row.cells[i].querySelector("a");

                                    if (isLink) { // Aligning with format for exporting to excel
                                        var url = isLink.getAttribute("href");
                                        if (url.substring(0,2) === "//") {
                                            arr[idx][headers[i].textContent] =  url.substring(2, url.length);
                                        } else if (url.substring(0,7) === "mailto:") {
                                            arr[idx][headers[i].textContent] = url.substring(7, url.length);
                                        } else {
                                            arr[idx][headers[i].textContent] = url;
                                        }
                                    } else {
                                        arr[idx][headers[i].textContent] = row.cells[i].getAttribute("data-content");
                                    }
                                } else {
                                    arr[idx][headers[i].textContent] = row.cells[i].textContent;
                                }
                            }
                        }
                    });

                    // Convert the array of objects to JSON string
                    str = JSON.stringify(arr, o.replacer, o.space);
                    if (isIE || isEdge) {
                        blob = new Blob([decodeURIComponent(str)],{type:"text/json;charset=UTF-8"});
                    }
                }

                // Download
                if (o.download) {
                    // Filename
                    o.filename = o.filename || "datatable_export";
                    o.filename += "." + o.type;

                   
                    if (isIE || isEdge) {
                        //Internet Explorer
                        window.navigator.msSaveBlob(blob, o.filename);
                    } else {
                        // Create a link to trigger the download
                        if (o.type === "json") {
                            str = "data:application/json;charset=utf-8," + str;
                            str = encodeURI(str);
                        } else if (o.type === "csv") {
                            str = encodeURI(str);
                            str = "data:application/csv;charset=utf-8," + str;
                        }
                        link = createElement("a", {
                            href: str,
                            download: o.filename
                        });

                        // Append the link
                        body.appendChild(link);

                        // Trigger the download
                        link.click();

                        // Remove the link
                        body.removeChild(link);
                    }
                }

                return str;
            }
        }

        return false;
    };

    /**
     * Import data to the table
     * @param  {Object} newData User newData
     * @return {Boolean}
     */
    this.import = function (newData) {
        var obj = false;
        var defaults = {
            // csv
            lineDelimiter: "\n",
            columnDelimiter: ","
        };

        // Check for the newData object
        if (!isObject(newData)) {
            return false;
        }
        newData = extend(defaults, newData);

        if (newData.data.length || isObject(newData.data)) {
            // Import CSV
            if (newData.type === "csv") {
                obj = {
                    rows: [],
                    details: [],
                    headings: []
                };

                // Split the string into rows
                var rows = newData.data.split(newData.lineDelimiter);

                if (rows.length) {
                    // var importHeaders = rows[0].replace(/\r?\n|\r/g, "").split(newData.columnDelimiter);
                    newData.skipColumn = [];
                    newData.details = -1;
                    // each(importHeaders, function(importHeader, idx) {
                    each(rows[0].replace(/\r?\n|\r/g, "").split(newData.columnDelimiter), function(importHeader, idx) {
                        if (labels.indexOf(importHeader) < 0) {
                            if (importHeader != "Details") {
                                newData.skipColumn.push(idx);
                            } else {
                                newData.details = idx;
                            }
                        } else {
                            obj.headings.push(importHeader);
                        }
                    });

                    if(obj.headings) { //.length + baseCellIdx() >= labels.length) {
                        rows.shift();
                        if (rows[rows.length-1] == "") {
                            rows = rows.splice(0, rows.length-1);
                        }
                        each(rows, function (row, i) {
                            row = row.replace(/\r?\n|\r/g, "");
                            obj.rows[i] = [];

                            // Split the rows into values
                            each(row.split(newData.columnDelimiter), function(value, v) {
                                if (newData.skipColumn.indexOf(v) == -1 && newData.details != v) {
                                    if (isEmail(value)) {
                                        value = "<a href=mailto:" + value + ">" + value + "</a>";
                                    } else if (isUrl(value)) {
                                        value = value.substring(0,3) === "www" ? 
                                            "<a href='//" + value + "'>" + value + "</a>" :
                                            "<a href='" + value + "'>" + value + "</a>";
                                    }
                                    obj.rows[i].push(value);
                                } else if (newData.details == v) {
                                    obj.details[i] = value;
                                }
                            });
                        });
                    } else {
                        var msg = "Supplied text/csv file does not contain the correct Header row!";
                        console.error(msg);
                        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                    }
                }
            } else if (newData.type === "json") {
                var json = isJson(newData.data);

                // Valid JSON string
                if (json) {
                    obj = {
                        rows: [],
                        details: [],
                        headings: []
                    };

                    if(isArray(json)) {
                        var idx = 0;
                        each(json, function (data) {
                            obj.rows[idx] = [];
                            obj.details[idx] = "";
                            each(data, function(value, importHeader) {
                                if (labels.indexOf(importHeader) < 0) {
                                    if (importHeader === "Details") {
                                        obj.details[idx] = value;
                                    }
                                } else {
                                    if (obj.headings.indexOf(importHeader) < 0) {
                                        obj.headings.push(importHeader);
                                    }
                                    if (isEmail(value)) {
                                        value = "<a href=mailto:" + value + ">" + value + "</a>";
                                    } else if (isUrl(value)) {
                                        value = value.substring(0,3) === "www" ?
                                            "<a href='//" + value + "'>" + value + "</a>" :
                                            "<a href='" + value + "'>" + value + "</a>";
                                    }
                                    obj.rows[idx].push(value);
                                }
                            });
                            idx++;
                        });
                    } else {
                        msg = "That's not valid JSON array data!";
                        console.error(msg);
                        uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                    }
                } else {
                    msg = "That's not valid JSON!";
                    console.error(msg);
                    uicoreCustomEvent("Table", "Error", table, {"msg": msg});
                }
            } else if (isObject(newData.data)) {
                obj = newData.data;
                obj.details = [];
                each(obj.rows, function(row, idx) {
                    obj.rows[idx] = row.data;
                    obj.details.push(row.details);
                });
            } else {
                return new Error("");
            }
            if (obj) {
                // Add the rows
                insert(obj);
            }
        }

        return false;
    };

    /**
     * Print the table
     * @return {void}
     */
    this.print = function () {
        var headings = table.activeHeadings;
        var rows = table.activeRows;

        // Open new window
        var w = window.open();
        var d = w.document;

        var pTable = d.createElement("table");
        var thead = d.createElement("thead");
        var tbody = d.createElement("tbody");

        var tr = d.createElement("tr");
        each(headings, function (th) {
            var newTh = d.createElement("th");
            newTh.appendChild(d.createTextNode(th.textContent));
            tr.appendChild(newTh);
        });

        thead.appendChild(tr);

        each(rows, function (row) {
            var tr = d.createElement("tr");
            each(row.cells, function (cell) {
                var newCell = d.createElement("td");
                newCell.appendChild(d.createTextNode(cell.textContent));
                tr.appendChild(newCell);
            });
            tbody.appendChild(tr);
        });

        pTable.appendChild(thead);
        pTable.appendChild(tbody);

        d.body.appendChild(pTable);

        // Print
        w.print();
    };

    /**
     * Show a message in the table
     * @param {string} message
     */
    this.setMessage = function (message) {

        var colspan = labels.length;
        wrapper.classList.add(prefix + "table-cmplx-empty");

        this.clear(
            createElement("tr", {
                html: "<td class=\"dataTables-empty\" colspan=\"" +
                    colspan +
                    "\">" +
                    message +
                    "</td>"
            })
        );

        if(!metHeight) {
            fixHeight();
        }
    };

    this.insertDetails = function(rowId, details) {
        var row = table.activeRows[rowId];
        if (row.event != "ExpandCancelEvent") {
            var stripeColor = false;
            if (table.classList.contains(prefix + "table-striped")) {
                stripeColor = rowId % 2 > 0 ? prefix + "table-cmplx-row-odd" : prefix + "table-cmplx-row-even";
            }
            table.data[rowId].details = row.details = details;
            renderDetails(row, table.headings.length, stripeColor);

            var button = row.cells[0].querySelector("." + prefix + "table-cmplx-accordion-btn");
            each(row.cells[0].querySelectorAll("use"), function(use) {
                if (use.getAttribute("xlink:href").indexOf(prefix + "loading-sqrs") != -1) {
                    classRemove(use, prefix +"show");
                } else {
                    classAdd(use, prefix + "show");
                    button.classList.remove(prefix + "collapsed");
                    button.setAttribute("aria-expanded", true);
                }
            });
            this.update();
            uicoreCustomEvent("Table", "ExpandEndEvent", table, {"rowId": rowId});
        } else {
            return false;
        }
        return true;
    };

    this.delete = function() {
        var that = this;
        if (table.selectedRows.length == 0) {
            return false;
        } else {
            rows().remove(table.selectedRows);
            columns().rebuild();
            pagination.removeItems(table.selectedRows.length);
            if (options.items > 0) {
                options.items -= table.selectedRows.length;
            }
        }

        if (table.activeRows.length == 0) {
            table.hasRows = false;
            var cell = header.cells[getHeaderCellIndex(prefix + "table-cmplx-select-all")];
            var input = cell.querySelector("input[type='checkbox']");
            if (input.checked) {
                input.checked = false;
                input.state = "unchecked";
            }
            handleSelectAllChange(input.state);
        }

        if (searching) {
            var query = wrapper.querySelector("input[type='search']").value;
            var searchResults = table.searchData.length;
            table.searchData = [];
            searching = false;
            if (searchResults == table.selectedRows.length) {
                wrapper.querySelector("input[type='search']").value = "";
                that.update();
            } else {
                that.search(query);
            }
        } else {
            that.update();
        }

        table.selectedRows = [];
        if (!table.hasRows) {
            this.setMessage(options.labels.noRows);
        }
        uicoreCustomEvent("Table","DeleteEvent", table, { "rowIds": table.selectedRows});

    };

    this.deleteAll = function() {
        var that = this;
        if (table.data.length == 0) {
            return false;
        } else { 
            table.data = [];
            pages = [];
            currentPage = 1;
            rows().update();
            columns().rebuild();
            options.items = 0;
            options.perPage = options.origPerPage;
            pagination.setItems(0);
        }

        table.hasRows = false;

        if (options.select) {
            var cell = header.cells[getHeaderCellIndex(prefix + "table-cmplx-select-all")];
            var input = cell.querySelector("input[type='checkbox']");
            if (input && (input.checked || input.state === "indeterminate")) {
                input.click();
                input.checked = false;
                input.state = "unchecked";
            }
            handleSelectAllChange(input.state);
        }


        if (searching) {
            searching = false;
            wrapper.querySelector("input[type='search']").value = "";
        } 
        that.update(); 

        this.setMessage(options.labels.noRows);

        uicoreCustomEvent("Table","DeleteAllEvent", table);  
    };

    this.setItems = function(items) {
        var newNum = validateNum(items);
        if (!newNum || options.items != 0) {
            return false;
        } else {
            options.items = newNum;
            pagination.setItems(options.items);
        }
    };

    //init
    if (!(stringTable in element)) {
        // Set the tables Id
        var crypto = window.crypto || window.msCrypto;
        batchId = crypto.getRandomValues(new Uint32Array(10))[0];
        if (options.search && options.text.search.label) {
            searchId = crypto.getRandomValues(new Uint32Array(10))[0];
        }

        table.data = null; // populated in dataToTable
        table.hasRows = false; // set in render
        table.headings = []; // duplicate commented out of code in render
        table.hasHeadings = false;
        
        // IE detection
        isIE = !!/(msie|trident)/i.test(navigator.userAgent);

        currentPage = 1;

        columnRenderers = [];
        selectedColumns = [];

        
        table.activeHeadings = [];
        
        table.sorting = false;
        
        table.searchData = [];
        table.hiddenColumns = [];
        if (options.select)
            table.selectedRows = [];

        render();

        // Options pagination
        if (options.pagination) {
            var itemCount = options.items > 0 ? options.items : table.activeRows.length;
            pagination = new Pagination(paginationElement, {
                perPageSelect: options.perPageSelect,
                perPage: options.perPage,
                items: itemCount,
                pageText: params.text && params.text.pageText,
                itemsPerPageText: params.text && params.text.itemsPerPageText,
                hidePages: params.hidePages,
                external: options.items == 0 ? false : true,
                buttonLabelLeft: options.buttonLabelLeft,
                buttonLabelRight: options.buttonLabelRight,
                disablePaginationInput: options.disablePaginationInput,
                showTotal: options.showTotal
            });
            // Option to override the item count was padded in
            if (options.items == 0) {
                paginationElement.parentNode.addEventListener("uicPaginationPageUpdateEvent", function(e) {
                    self.page(e.detail.page);
                }, false);
                paginationElement.parentNode.addEventListener("uicPaginationPerPageUpdateEvent", function(e) {
                    self.perPage(e.detail);
                }, false);
            } else {
                paginationElement.parentNode.addEventListener("uicPaginationPageChangeEvent", function(e) {
                    if (table.activeRows.length < (e.detail.page * e.detail.perPage)) {
                        var rowsNeeded = (e.detail.page * e.detail.perPage) - table.activeRows.length;
                        if ((e.detail.page * e.detail.perPage) > options.items) {
                            rowsNeeded = options.items - table.activeRows.length;
                        }
                        if (rowsNeeded > 0) {
                            uicoreCustomEvent("Table", "NewPageEvent", table, { "page": e.detail.page, "rows" : rowsNeeded });
                        } else {
                            self.page(e.detail.page);
                        }
                    } else {
                        self.page(e.detail.page);
                    }
                }, false);
                paginationElement.parentNode.addEventListener("uicPaginationPerPageChangeEvent", function(e) {
                    if ((e.detail.page * e.detail.perPage) > table.activeRows.length ) {
                        var rowsNeeded = (e.detail.page * e.detail.perPage) - table.activeRows.length;
                        if ((e.detail.page * e.detail.perPage) <= options.items) {
                            uicoreCustomEvent("Table", "MoreRowsEvent", table, { "page": e.detail.page, "perPage": e.detail.perPage, "rows" : rowsNeeded });
                        } else {
                            self.perPage(e.detail);
                        }
                    } else {
                        self.perPage(e.detail);
                    }
                }, false);
            }
        }

        // Options condensed
        if (options.condensed) {
            wrapper.querySelector("." + prefix + "table-cmplx").classList.add(prefix + "condensed");
        }

        each(wrapper.querySelectorAll("[data-toggle=\"dds__dropdown\"]"), function(drop) {
            if (drop.classList.contains(prefix+"table-cmplx-action-button")) {
                new Dropdown(drop);
            }
        });
    }

    element[stringTable] = self;
}