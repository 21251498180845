import Alert from "./alert.js";
import {BarSelect} from "./select.js";
import {Button} from "./button.js";
import {ButtonFilter} from "./button.js";
import {Carousel} from "./carousel.js";
import {FilmstripCarousel} from "./carousel.js";
import Collapse from "./collapse.js";
import ContactDrawer from "./contact-drawer.js";
import {DatePicker} from "./select.js";
import Dropdown from "./dropdown.js";
import {FilterCollection} from "./filter.js";
import {FilterKeyword} from "./filter.js";
import Form from "./form.js";
import LinkPicker from "./linkpicker.js";
import Masthead from "./masthead.js";
import Modal from "./modal.js";
import {NavAnchored} from "./nav.js";
import {NavAnchoredVertical} from "./nav.js";
import {NavSkip} from "./nav.js";
import {NavLeft} from "./nav.js";
import Pagination from "./pagination.js";
import Popover from "./popover.js";
import ProductStack from "./product-stack.js";
import Progress from "./progress.js";
import {NavSkip as SkipNav} from "./nav.js";
import Slider from "./slider.js";
import {Spinbox} from "./select.js";
import Tab from "./tab.js";
import Table from "./table.js";
import Tooltip from "./tooltip.js";
import { DOC, prefix, handleFirstTab, loadURLSVGs, each } from "./utilities.js";

// globals
var body = "body", // allow the library to be used in <head>
    supports = [];

var initializeDataAPI = function (constructor, collection) {
    each(collection, function(i) {
        new constructor(i);
    })
    },
    initCallback = function (lookUp) {
        lookUp = lookUp || DOC;
        each(supports, function(support) {
            initializeDataAPI(
                support[0],
                lookUp["querySelectorAll"](support[1])
            );

        })
};

// ALERT DATA API
supports["push"]([Alert, "[data-dismiss=\"" + prefix + "alert\"]"]);

// BUTTON DATA API
supports["push"]([Button, "[data-toggle=\"" + prefix + "button\"]"]);

// BUTTON DATA API
supports["push"]([BarSelect, "[data-toggle=\"" + prefix + "bar-select\"]"]);

// BUTTON FILTER DATA API
supports["push"]([ButtonFilter, "[data-toggle=\"" + prefix + "button-filter\"]"]);

// CAROUSEL DATA API
supports["push"]([Carousel, "[data-ride=\"" + prefix + "carousel\"]"]);

// CAROUSEL FILMSTRIP DATA API
supports["push"]([FilmstripCarousel, "[data-toggle=\"" + prefix + "filmstrip-carousel\"]"]);

// COLLAPSE DATA API
supports["push"]([Collapse, "[data-toggle=\"" + prefix + "collapse\"]"]);

// CONTACT DRAWER DATA API
supports["push"]([ContactDrawer, "[data-toggle=\"" + prefix + "contact-drawer\"]"]);

// DROPDOWN DATA API
supports["push"]([Dropdown, "[data-toggle=\"" + prefix + "dropdown\"]"]);

// DATEPICKER DATA API
supports["push"]([DatePicker, "[data-toggle=\"" + prefix + "datepicker\"]"]);

// FILTER COLLECTION DATA API
supports["push"]([FilterCollection, "[data-toggle=\"" + prefix + "filter-collection\"]"]);

// KEYWORD FILTER DATA API
supports["push"]([FilterKeyword, "[data-toggle=\"" + prefix + "filter-keyword\"]"]);

// FORM CAROUSEL DATA API
supports["push"]([Form, "[data-toggle=\"" + prefix + "form-validation\"]"]);

// LINKPICKER DATA API
supports["push"]([LinkPicker, "[data-toggle=\"" + prefix + "linkpicker\"]"]);

// MASTHEAD DATA API
supports["push"]([Masthead, "[data-toggle=\"" + prefix + "msthd\"]"]);

// MODAL DATA API
supports["push"]([Modal, "[data-toggle=\"" + prefix + "modal\"]"]);

// NAVIGATION ANCHORED DATA API
supports["push"]([NavAnchored, "[data-toggle=\"" + prefix + "nav-anchored\"]"]);

// NAVIGATION ANCHORED VERTICAL DATA API
supports["push"]([NavAnchoredVertical, "[data-toggle=\"" + prefix + "nav-anchored-vertical\"]"]);

// NAV LEFT DATA API
supports["push"]([NavLeft, "[data-toggle=\"" + prefix +"nav-left\"]"]);

// SKIPNAV DATA API
supports["push"]([NavSkip, "[data-toggle=\"" + prefix +"nav-skip\"]"]);

// PAGINATION DATA API
supports["push"]([Pagination, "[data-toggle=\"" + prefix + "pagination\"]"]);

// POPOVER DATA API
supports["push"]([Popover, "[data-toggle=\"" + prefix + "popover\"]"]);

// PRODUCTSTACK DATA API
supports["push"]([ProductStack, "[data-toggle=\"" + prefix + "product-stack\"]"]);

// PROGRESS DATA API
supports["push"]([Progress, "[data-toggle=\"" + prefix + "progress\"]"]);

// SKIPNAV DATA API
supports["push"]([SkipNav, "[data-toggle=\"" + prefix +"nav-skip\"]"]);

// Slider DATA API
supports["push"]([Slider, "[data-toggle=\"" + prefix + "slider\"]"]);

// Spinbox DATA API
supports["push"]([Spinbox, "[data-toggle=\"" + prefix + "spinbox\"]"]);

// TABLE DATA API
supports["push"]([Table, "[data-table=\"" + prefix + "table\"]"]);

// TAB DATA API
supports["push"]([Tab, "[data-toggle=\"" + prefix + "tab\"]"]);

// TOOLTIP DATA API
supports["push"]([Tooltip, "[data-toggle=\"" + prefix + "tooltip\"]"]);

// bulk initialize all components
DOC[body] ? initCallback() : DOC.addEventListener("DOMContentLoaded", function () {
    initCallback();
});

window.addEventListener("keydown", handleFirstTab);

export {
    Alert, BarSelect, Button, ButtonFilter, Carousel, Collapse, ContactDrawer, DatePicker, Dropdown, FilmstripCarousel, FilterCollection, FilterKeyword, Form, LinkPicker, Masthead, Modal, NavAnchored, NavAnchoredVertical, NavLeft, NavSkip,  Pagination, Popover, ProductStack, Progress, SkipNav, Slider, Spinbox, Tab, Table, Tooltip,
    handleFirstTab, loadURLSVGs
};
