import {createElement, prefix, uicoreCustomEvent, each, jsonOptionsInit, debounce, isIE, isSafari, isEdge} from "./utilities.js";

export default function ProductStack(element, options) {
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    options = options || {};
    options = jsonOptionsInit(element, options);
    options.lazyload = typeof options.lazyload === "boolean" ? options.lazyload : false;
    options.type = options.type ? options.type : "single";

    var stringProductStack = "ProductStack",
        itemsView,
        currentOffset,
        overflowContainer,
        childrenWidth,
        productStacks,
        rightCtrls,
        leftCtrls,
        productStackID,
        // handlers
        resizeHandler = function() {
            updateSectionHeights();
            updateItemsView();
        },
        controlClick = function(e) {
            var increment = childrenWidth;
            if (options.type == "page") {
                // increment = overflowContainer.offsetWidth;
                var leftNumber = Math.ceil((overflowContainer.scrollLeft - 5) / childrenWidth) + 1;
                var rightNumber = Math.floor((overflowContainer.scrollLeft + overflowContainer.offsetWidth + 5) / childrenWidth);
                if (rightNumber - leftNumber <= 0){
                    increment = childrenWidth;
                } else {
                    increment = childrenWidth * (rightNumber - leftNumber + 1);
                }
            }
            
            var isRight = (e.target.classList.contains(prefix + "overflow-control-right") || e.target.parentElement.classList.contains(prefix + "overflow-control-right")) ? true : false;
            
            if (isRight) {
                if ((currentOffset + increment) < childrenWidth * productStacks.length - overflowContainer.offsetWidth - 1) {
                    currentOffset += increment;
                }
                else {
                    currentOffset = childrenWidth * productStacks.length - overflowContainer.offsetWidth;
                }
            } else {
                if ((currentOffset - increment) > 1) {
                    currentOffset -= increment;
                }
                else {
                    currentOffset = 0;
                }
            }
            if(isIE || isSafari || isEdge) {
                overflowContainer.scrollLeft = currentOffset;
            }
            else {
                overflowContainer.scroll({ top: 0, left: currentOffset, behavior: "smooth" });
            }
            updateItemsView();
        },
        updateSectionHeights = function() {
            element.getElementsByTagName("style")[0].innerHTML = "";
            childrenWidth = productStacks[0].parentElement.offsetWidth;
            // tallestSections can be used in the future to optimize the performace on resize
            // var tallestSections = [];
            each(productStacks[0].sections, function(section) {
                // to optimize, we can extract querySelectorAll outside and save it somewhere instead of doing it over and over
                var sectionName = "." + section.className.replace(/\s/g, ".");
                var sectionMatches = element.querySelectorAll(sectionName);         
                if(sectionMatches.length > 1){
                    var maxHeight = 0;
                    each(sectionMatches, function(sectionMatch) {
                        if (sectionMatches.style){
                            sectionMatches.removeAttribute("style");
                        }
                        if (sectionMatch.offsetHeight > maxHeight) {
                            maxHeight = sectionMatch.offsetHeight;
                        }
                        else if (sectionMatch.offsetHeight == 0) {
                            sectionMatch.nextElementSibling.style.visibility = "hidden";
                        }
                    });
                    var styleTag = element.getElementsByTagName("style")[0].innerHTML;
                    element.getElementsByTagName("style")[0].innerHTML = styleTag + "#"+ productStackID + " " + sectionName + "{ height: " + maxHeight + "px }";
                }
            }); 
        },
        updateItemsView = function() {
            //can be optimized later on to avoid cycling through all sections to generate stylesheet 
            // adding and subtracting 5 pixels for flexibility on non-integer scrollLeft
            if (itemsView) {
                var leftNumber = Math.ceil((overflowContainer.scrollLeft - 5) / childrenWidth) + 1;
                var rightNumber = Math.floor((overflowContainer.scrollLeft + overflowContainer.offsetWidth + 5) / childrenWidth);
                each(itemsView, function(view){
                    view.innerHTML = "<span>" + (leftNumber) + (leftNumber < rightNumber ? " - " + (Math.min(rightNumber, productStacks.length)) : "") + " of "+ productStacks.length +"</span>";
                });
                uicoreCustomEvent("ProductStack", "ChangeEvent", element, {"left": leftNumber, "right": rightNumber, "totalItems": productStacks.length});
                if(leftNumber <= 1){
                    each(leftCtrls, function(leftCtrl){
                        leftCtrl.setAttribute("disabled", "");
                    });
                } else {
                    each(leftCtrls, function(leftCtrl){
                        leftCtrl.removeAttribute("disabled");
                    });
                }
                if (rightNumber >= productStacks.length) {
                    each(rightCtrls, function(rightCtrl){
                        rightCtrl.setAttribute("disabled", "");
                    });
                } else {
                    each(rightCtrls, function(rightCtrl){
                        rightCtrl.removeAttribute("disabled");
                    });
                }
                if(rightCtrls[0].disabled && leftCtrls[0].disabled){
                    each(rightCtrls, function(rightCtrl){
                        rightCtrl.style.display = "none";
                    });
                    each(leftCtrls, function(leftCtrl){
                        leftCtrl.style.display = "none";
                    });
                    each(itemsView, function(viewDiv){
                        viewDiv.style.display = "none";
                    });
                } else{
                    each(rightCtrls, function(rightCtrl){
                        rightCtrl.style.display = "block";
                    });
                    each(leftCtrls, function(leftCtrl){
                        leftCtrl.style.display = "block";
                    });
                    each(itemsView, function(viewDiv){
                        viewDiv.style.display = "block";
                    });
                }
            }
        };


    this.lazyLoad = function() {
        if (options.lazyload) {
            updateSectionHeights();
            updateItemsView();
            uicoreCustomEvent("ProductStack", "LazyLoadEvent", element, {"success": true});
        }
        else {
            uicoreCustomEvent("ProductStack", "LazyLoadEvent", element, {"success": false, "msg": "Carousel cannot be lazy loaded. Check usage or avoid mulitple lazy loads."});
        }
    };

    // prevent adding event handlers twice
    if (!(stringProductStack in element)) {
        productStackID = element.id ? element.id : "product-stack";
        var style = createElement("style");
        style.type = "text/css"; 
        element.insertBefore(style, element.firstElementChild);
        productStacks = element.querySelectorAll("UL." + prefix + "product-stack");
        each(productStacks, function(productStack) {
            productStack.sections = [];
            var i = 0;
            each(productStack.children, function(section) {
                if (section.tagName =="LI") {
                    section.classList.add(prefix + "ps-row-"+ i++);
                    productStack.sections.push(section);
                }
            });  
        });
        itemsView = element.parentElement.querySelectorAll("DIV." + prefix + "items-view");
        overflowContainer = element.querySelector("." + prefix + "product-stack-wrapper");
        overflowContainer.addEventListener("scroll", debounce(function() {
            if (overflowContainer.scrollLeft != currentOffset) {
                updateItemsView();
                currentOffset = overflowContainer.scrollLeft;
            }
        }), 15);
        window.addEventListener("load", updateSectionHeights, false); 
        window.addEventListener("resize", debounce(resizeHandler, 15));
        childrenWidth = element.querySelector("." + prefix + "product-stack").parentElement.offsetWidth;
        rightCtrls = element.querySelectorAll("[data-toggle='" + prefix + "product-stack-control-right']");
        each(rightCtrls, function(rightCtrl){
            rightCtrl.addEventListener("click", controlClick, true);
        });
        leftCtrls = element.querySelectorAll("[data-toggle='" + prefix + "product-stack-control-left']");
        each(leftCtrls, function(leftCtrl){
            leftCtrl.addEventListener("click", controlClick, true);
            leftCtrl.setAttribute("disabled", "");
        });
        if (isEdge) {
            rightCtrls[0].addEventListener("keydown", function(e){
                setTimeout(function(){
                    if ((e.keyCode == 13 || e.keyCode == 32) && rightCtrls[0].disabled) {
                        e.preventDefault();
                        leftCtrls[0].focus();
                    }
                }, 100);
            }, false);
            leftCtrls[0].addEventListener("keydown", function(e){
                setTimeout(function(){
                    if ((e.keyCode == 13 || e.keyCode == 32) && leftCtrls[0].disabled) {
                        e.preventDefault();
                        rightCtrls[0].focus();
                    }
                }, 100);
            }, false);
            leftCtrls[1].addEventListener("keydown", function(e){
                setTimeout(function(){
                    if ((e.keyCode == 13 || e.keyCode == 32) && leftCtrls[1].disabled) {
                        e.preventDefault();
                        rightCtrls[1].focus();
                    }
                }, 100);
            }, false);
            rightCtrls[1].addEventListener("keydown", function(e){
                setTimeout(function(){
                    if ((e.keyCode == 13 || e.keyCode == 32) && rightCtrls[1].disabled) {
                        e.preventDefault();
                        leftCtrls[1].focus();
                    }
                }, 100);
            }, false);
        }
        currentOffset = overflowContainer.scrollLeft;
        updateItemsView();
    }

    element[stringProductStack] = self;
}