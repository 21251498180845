import { prefix, DOC, getElementsByClassName, each, jsonOptionsInit } from "./utilities.js";
import InputMask from "./helpers/inputMask.js";

export default function Forms(element, options) {
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    
    options = options || {};
    options = jsonOptionsInit(element, options);
    options.validate = element.dataset.validate ? element.dataset.validate  === "true" ? true: false : options.validate ? options.validate : true;

    var self = this,
        stringForm = "Form",
        togglePassword = function (e) {
            var passwordState = DOC.getElementById(e.target.dataset["target"]);
            if (passwordState) {
                if (passwordState.type === "password") {
                    passwordState.type = "text";
                } else {
                    passwordState.type = "password";
                }
            } else {
                console.warn("The target password field does not exist.");
            }
        },
        submitHandler = function(event) {
            var formValidationErrorSuffix = "Feedback";
            if (element.checkValidity() === false) {
                each(element, function(el) {
                    if(el.tagName == "INPUT"){
                        var errorMsg = element.querySelector("#"+ el.getAttribute("id")+formValidationErrorSuffix);
                        setTimeout(function(){
                            if (!el.validity.valid) {
                                //if error message has not been turned on yet
                                if(errorMsg && !(getComputedStyle(errorMsg)["display"] == "block")){
                                    errorMsg.style.display = "block";
                                }
                                el.setAttribute("aria-invalid", "true");
                                el.setAttribute(
                                    "aria-describedby",
                                    el.getAttribute("id") + formValidationErrorSuffix
                                );
                            } else {
                                //if error message has not been turned off yet
                                if(errorMsg && (getComputedStyle(errorMsg)["display"] == "block")){
                                    errorMsg.style.display = "none";
                                } 
                                el.setAttribute("aria-invalid", "false");
                                el.setAttribute("aria-describedby", "");
                            }
                        }, 10);
                    }
                });
                event.preventDefault();
                event.stopPropagation();
            }

            element.classList.add("dds__was-validated");
        };
    
    //init
    if (!(stringForm in element)) {
        if (element.querySelectorAll("input")) {
            each(element.querySelectorAll("input"), function(child) {
                if (child.classList.contains(prefix + "input-masked")) {
                    new InputMask(child);
                }
            });
        }
        if  (!element.hasAttribute("novalidate")) {
            element.setAttribute("novalidate", "");
        } 
        if (options.validate) {
            element.addEventListener("submit", submitHandler);
        }

        var checkboxValidators = getElementsByClassName(element, prefix + "form-check-input");
        each(checkboxValidators, function(checkboxValidator) {
            if (checkboxValidator && !!checkboxValidator.dataset["target"]) {
                checkboxValidator.addEventListener("click", togglePassword);
            }
        });
    }
    element[stringForm] = self;
}
