import { prefix, debounce, jsonOptionsInit, getFullScreenOverlay, validateNum, uicoreCustomEvent, each, getFocusableElements, getClosest, isIE, isEdge, isFirefox, DOC, createElement, globalObject} from "./utilities.js";
import InputMask from "./helpers/inputMask.js";

export function Spinbox(element, options) {

    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    options = options || {};
    options = jsonOptionsInit(element, options);
    options.spinmin = element.dataset.spinmin ? validateNum(element.dataset.spinmin, 0) : validateNum(options.spinmin, 0);
    options.spindefault = element.dataset.spindefault ? validateNum(element.dataset.spindefault, 0) : validateNum(options.spindefault, 0);
    options.spinmax = element.dataset.spinmax ? validateNum(element.dataset.spinmax, 100) : validateNum(options.spinmax, 100);
    options.spinstep = element.dataset.spinstep ? validateNum(element.dataset.spinstep, 1) : validateNum(options.spinstep, 1);

    var msg= "";
    if (isNaN(options.spinmin)) {
        msg += "Min value is not a number.\n";
    }
    if (isNaN(options.spinmax)) {
        msg += "Max value is not a number.\n";
    }
    if (isNaN(options.spindefault)) {
        msg += "Default value is not a number.\n";
    } else if (options.spindefault < options.spinmin || options.spindefault > options.spinmax) {
        msg += "Default value falls outside of the min max values.\n";
    }
    if (isNaN(options.spinstep)) {
        msg += "Step value is not a number.\n";
    }
    if (msg.length) {
        throw new Error(msg);
    }
    
    var self = this,
        stringSpinbox = "Spinbox",
        input,
        plusCtrl,
        minusCtrl,
        // horizontal,
        // handlers
        handleKeypressEvent = debounce(function(e) {
            var charCode = (e.which) ? e.which : e.keyCode;
            // if not a number
            if ( input.value.length > options.spinmax.length || (charCode > 31 && (charCode < 48 || charCode > 57))) {
                if (charCode == 13){
                    input.blur();
                }
                else if (input.value.length == 0 && (options.spinmin < 0 && charCode == 45)) { // allow minus when input empty and spinmin less than zero
                    return;
                } else {
                    e.preventDefault();
                }
            } else {
                var newInput = input.value;
                if (parseInt(newInput) > options.spinmax) {
                    input.value = options.spinmax;
                    input.setAttribute("aria-valuenow", options.spinmax);
                    e.preventDefault();
                } else if (parseInt(newInput) < options.spinmin) {
                    input.value = options.spinmin;
                    input.setAttribute("aria-valuenow", options.spinmin);
                    e.preventDefault();
                } else {
                    if (input.value == "-" && e.charCode == 48) { // dont allow -0
                        e.preventDefault();
                    } else {
                        if (charCode == 13) { // Enter key
                            if (Math.abs(parseInt(input.value)) % options.spinstep > 0) {
                                input.value = parseInt(input.value) - (Math.abs(parseInt(input.value)) % options.spinstep);
                            }
                            input.blur();
                        } else {
                            input.setAttribute("aria-valuenow", input.value);
                        }
                    }
                }
            }
        }, 500),
        handleCtrlClickEvent = function(e, control) {
            var temp;
            if (control === minusCtrl) {
                temp = parseInt(input.value) - options.spinstep;
            } else {
                temp = parseInt(input.value) + options.spinstep;
            }
            if (!(temp < options.spinmin) && !(temp > options.spinmax)) {
                input.value = temp;
                input.setAttribute("aria-valuenow", temp);
                handleDisabling();
                uicoreCustomEvent("Spinbox", "ValueChangeEvent", element, {"value": input.value});
            }
        },
        handleDisabling = function() {
            if (input.value == options.spinmin || (parseInt(input.value) - options.spinstep) < options.spinmin) {
                minusCtrl.setAttribute("disabled", "");
            } else if (minusCtrl.hasAttribute("disabled")) {
                minusCtrl.removeAttribute("disabled");
            }
            if (input.value == options.spinmax || (parseInt(input.value) + options.spinstep) > options.spinmax) {
                plusCtrl.setAttribute("disabled", "");
            } else if (plusCtrl.hasAttribute("disabled")) {
                plusCtrl.removeAttribute("disabled");
            }
        },
        handleFocusOut = function() {
            // if input is empty, negative, or NaN
            if (input.value.length == 0 || input.value == "-" || !(/^\d+$/.test(input.value))) {
                input.value = options.spindefault;
            } else if (parseInt(input.value) < options.spinmin) {
                input.value = options.spinmin;
            } else if (parseInt(input.value) > options.spinmax) {
                input.value = options.spinmax;
            } else if (Math.abs(parseInt(input.value)) % options.spinstep > 0) {
                input.value = parseInt(input.value) - (Math.abs(parseInt(input.value)) % options.spinstep);
            } else if(input.value.match(/^0+/g)) {
                var match = input.value.match(/^0+/g);
                var temp = input.value.replace(match,"");
                if (temp.length == 0) {
                    input.value = "0";
                } else {
                    input.value = temp;
                }
            }
            handleDisabling();
            uicoreCustomEvent("Spinbox", "ValueChangeEvent", element, {"value": input.value});

        },
        handleArrowEvent = function(e) {
            switch (e.keyCode) {
            case 35: // end
                e.preventDefault();
                input.value = options.spinmax;
                uicoreCustomEvent("Spinbox", "ValueChangeEvent", element, {"value": input.value});
                break;
            case 36: // home
                e.preventDefault();
                input.value = options.spinmin;
                uicoreCustomEvent("Spinbox", "ValueChangeEvent", element, {"value": input.value});
                break;
            case 38: // up
                e.preventDefault();
                if (input.value.length) {
                    plusCtrl.click();
                } else {
                    input.value = options.spinmin + options.spinstep;
                    input.setAttribute("aria-valuenow", input.value);
                    uicoreCustomEvent("Spinbox", "ValueChangeEvent", element, {"value": input.value});
                }
                break;
            case 40: // down
                e.preventDefault();
                minusCtrl.click();
                break;
            case 37: // left
            case 39: // right 
                e.preventDefault(); 
                break;
            default:
                break;
            }
        };

    // init
    if (!(stringSpinbox in element)) {
        input = element.querySelector("." + prefix + "spinbox-input");
        input.addEventListener("keypress", handleKeypressEvent);
        input.addEventListener("keyup", handleDisabling);
        input.addEventListener("focusout", handleFocusOut);
        element.addEventListener("keydown", handleArrowEvent);

        input.value = options.spindefault;
        input.setAttribute("aria-valuenow", options.spindefault);
        input.setAttribute("aria-valuemin", options.spinmin);
        input.setAttribute("aria-valuemax", options.spinmax);

        var controls = element.querySelectorAll("button." + prefix + "spinbox-btn");
        if (element.classList.contains(prefix + "spinbox-horizontal")) {// horizontal spinbox
            // horizontal = true;
            minusCtrl = controls[0];
            plusCtrl = controls[1];
        } else {
            // horizontal = false;
            minusCtrl = controls[1];
            plusCtrl = controls[0];
        }

        minusCtrl.addEventListener("click", function(e) {
            handleCtrlClickEvent(e, minusCtrl);
        });
        plusCtrl.addEventListener("click", function(e) {
            handleCtrlClickEvent(e, plusCtrl);
        });
        handleDisabling();
    }


    element[stringSpinbox] = self;
}

export function DatePicker(element, options) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();
    
    // set option
    options = options || {};
    options = jsonOptionsInit(element, options);
    options.datesFilter = options.datesFilter ? options.datesFilter : false;
    options.pastDates = options.pastDates ? options.pastDates : false;
    options.availableWeekDays = options.availableWeekDays ? options.availableWeekDays : [{day: "monday"},{day: "tuesday"},{day: "wednesday"},{day: "thursday"},{day: "friday"},{day: "saturday"},{day: "sunday"}];
    options.notBeforeDate = options.notBeforeDate ? new Date(options.notBeforeDate) : null;
    options.notAfterDate = options.notAfterDate ? new Date(options.notAfterDate) : null;
    options.button_prev = options.button_prev ? options.button_prev : null; 
    options.button_next = options.button_next ? options.button_next : null; 

    var self = this,
        stringDatePicker = "DatePicker",
        calendarBtn = element.querySelector("." + prefix + "datepicker-btn"),
        target = element["getAttribute"]("data-target"),
        dateInput = element.querySelector("." + prefix + "form-control"),
        calendar = element.querySelector(target),
        pickerBtn = element.querySelector("." + prefix + "datepicker-btn"),
        form = null,
        monthEl = null,
        focusableEls,
        currentSelected,
        overlay = getFullScreenOverlay(),
        smallMedia = window.matchMedia("(max-width: 767.98px)"),
        calendarWrapper,
        tempSelected = new Date(),
        todaysDate = new Date(),
        monthLabel = null,
        datePtr = new Date(),
        monthsTexts = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        keyCode = Object.freeze({
            "TAB": 9,
            "ENTER": 13,
            "ESC": 27,
            "SPACE": 32,
            "PAGEUP": 33,
            "PAGEDOWN": 34,
            "END": 35,
            "HOME": 36,
            "LEFT": 37,
            "UP": 38,
            "RIGHT": 39,
            "DOWN": 40
        }),
        setFocusableElements = function() {
            focusableEls = getFocusableElements(calendar);
        },
        toggleCalendar = function() {
            if (calendar.classList.contains(prefix + "d-none")) {
                calendar.classList.remove(prefix + "d-none");
                calendarWrapper.classList.remove(prefix+"hide");
                if (smallMedia.matches) createOverlay();
                repositionIfNeeded();
                globalObject.addEventListener("resize", toggleCalendar);
                //check if calendar is within confines of page
                var dateText = dateInput.value;
                if (dateText && dateInput.validity.valid && checkDateValidity(new Date(dateText))) {
                    currentSelected = new Date(dateText);
                    tempSelected = new Date(dateText);
                    if (datePtr.getFullYear() != currentSelected.getFullYear()) {
                        datePtr.setFullYear(currentSelected.getFullYear());
                    }
                    if (datePtr.getMonth() != currentSelected.getMonth()) {
                        datePtr.setMonth(currentSelected.getMonth());
                    }
                    createMonth();
                    var newDateElem = element.querySelector("[data-calendar-date=\"" + currentSelected + "\"]");
                    if(!newDateElem.classList.contains(prefix + "datepicker-date-disabled")) {
                        newDateElem.classList.add(prefix + "datepicker-date-selected");
                        var nextBtn = newDateElem.querySelector("." + prefix + "datepicker-calendar-day");
                        nextBtn.removeAttribute("tabindex");
                        nextBtn.focus();
                    }
                    tempSelected = new Date(currentSelected.getTime());
                }
                else {
                    resetToDefaultDate();
                }
                calendar.addEventListener("focusout", blurHandler, false);
            } else {
                calendar.classList.add(prefix + "d-none");
                calendarWrapper.classList.add(prefix+"hide");
                globalObject.removeEventListener("resize", toggleCalendar);
                removeActiveClass();
                pickerBtn.focus();
                calendar.removeEventListener("focusout", blurHandler, false);
                removeOverlay();
            }
        },
        createWrapper = function() {
            var wrapper = createElement("div", {
                class: prefix + "calendar-wrapper " + prefix + "hide"
            });
            calendar.parentNode.appendChild(wrapper);
            wrapper.appendChild(calendar);
            return wrapper;
        },
        repositionIfNeeded = function() {
            //right+left checking
            calendar.style.right = "";
            calendar.style.top = "";
            if (calendar.getBoundingClientRect().right > document.body.offsetWidth) {
                calendar.style.right = (document.body.offsetWidth - calendarBtn.getBoundingClientRect().right) +"px";
            }

            //vertical+horizontal checking
            if (calendar.getBoundingClientRect().bottom > window.innerHeight) {
                var dist = calendar.getBoundingClientRect().bottom - window.innerHeight - window.pageYOffset;
                calendar.style.top = calendar.getBoundingClientRect().top - dist - 4 + "px"; //4px buffer as to not directly hug browser frame
            }
        },
        blurHandler = function() {
            setTimeout( function() {
                if(calendar && !calendar.contains(document.activeElement) && !calendarBtn.contains(document.activeElement)) {
                    toggleCalendar();
                    calendarBtn.focus();
                }
            }, 10);
        },
        getWeekDay = function (day) {
            return ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"][day];
        },  
        createDay = function (date) {
            var isAvailable = true;
            var newDayElem = document.createElement("div");
            var dateElem = document.createElement("button");
            dateElem.setAttribute("tabindex", "-1");
            dateElem.setAttribute("type", "button");
            dateElem.innerHTML = date.getDate();
            dateElem.addEventListener("keydown", dateKeydownHandler, false);
            dateElem.className = prefix + "datepicker-calendar-day";
            newDayElem.className = prefix + "datepicker-date";
            newDayElem.setAttribute("data-calendar-date", date);
            
            var available_week_day = options.availableWeekDays.filter(function(f) {
                return (f.day === date.getDay() || f.day === getWeekDay(date.getDay()));
            });
            if (date.getMonth() != datePtr.getMonth()) {
                newDayElem.classList.add(prefix + "datepicker-date-outdated");
            }
            if (datePtr.getTime() <= todaysDate.getTime() - 1 && !options.pastDates) {
                newDayElem.classList.add(prefix + "datepicker-date-disabled");
                dateElem.setAttribute("disabled", "");
            } else {
                if (options.datesFilter) {
                    if (options.notBeforeDate && date <= options.notBeforeDate) {
                        isAvailable = false;
                    }
                    if (options.notAfterDate && date > options.notAfterDate) {
                        isAvailable = false;
                    }
                    if (available_week_day.length && isAvailable) {
                        newDayElem.classList.add(prefix + "datepicker-date-active");
                        newDayElem.setAttribute("data-calendar-data", JSON.stringify(available_week_day[0]));
                        newDayElem.setAttribute("data-calendar-status", "active");
                    } else {
                        newDayElem.classList.add(prefix + "datepicker-date-disabled");
                        dateElem.setAttribute("disabled", "");
                    }
                } else {
                    newDayElem.classList.add(prefix + "datepicker-date-active");
                    newDayElem.setAttribute("data-calendar-status", "active");
                }
            }
            //set current selected date
            if (currentSelected && date.toString() == currentSelected.toString() && !newDayElem.classList.contains(prefix + "datepicker-date-disabled")) {
                newDayElem.classList.add(prefix + "datepicker-date-selected");
        
            }
            //if textbox is empty 
            if (date.toString() == tempSelected.toString() && !newDayElem.classList.contains(prefix + "datepicker-date-disabled")) {
                newDayElem.classList.add(prefix + "datepicker-date-temp-selected");
                dateElem.removeAttribute("tabindex");
            }
            //add date to the calendar
            newDayElem.appendChild(dateElem);
            monthEl.appendChild(newDayElem);
        },
        createOverlay = function () {
            DOC.body.style.overflow = "hidden";
            if (overlay && !(overlay.classList.contains(prefix + "show"))) {
                overlay.classList.add(prefix + "show");
                overlay.removeAttribute("hidden");
                if( overlay.style.visibility == "hidden"){
                    overlay.style.visibility = "";
                }
            } else {
                console.warn("MODAL: Overlay requested. Corresponding HTML missing. Please apply id 'dds__full-screen-overlay' and class 'dds__overlay' to an empty div");
            }

        },
        removeOverlay = function () {
            DOC.body.style.overflow = "";
            if (overlay) {
                overlay.classList.remove(prefix + "show");
            }
        },
        removeActiveClass = function () {
            each(element.querySelectorAll("." + prefix + "datepicker-date-selected"), function(day) {
                day.classList.remove(prefix + "datepicker-date-selected");
            });
        },
        removeTempClass = function() {
            each(element.querySelectorAll("." + prefix + "datepicker-date-temp-selected"), function(day) {
                day.classList.remove(prefix + "datepicker-date-temp-selected");
                day.firstElementChild.setAttribute("tabindex", "-1");
            });
        },
        resetToDefaultDate = function() {
            currentSelected = null;
            var defaultDate = getNearestAvailableDay(new Date(options.defaultDate));
            todaysDate = getNearestAvailableDay(todaysDate);
            tempSelected = new Date(defaultDate.getTime());
            if (datePtr.getFullYear() != tempSelected.getFullYear()) {
                datePtr.setFullYear(tempSelected.getFullYear());
            }
            if (datePtr.getMonth() != tempSelected.getMonth()) {
                datePtr.setMonth(tempSelected.getMonth());
            }
            createMonth();
            var newDateElem = element.querySelector("[data-calendar-date=\"" + tempSelected + "\"]");
            newDateElem.classList.add(prefix + "datepicker-date-temp-selected");
            var nextBtn = newDateElem.querySelector("." + prefix + "datepicker-calendar-day");
            nextBtn.removeAttribute("tabindex");
            nextBtn.focus();
        },
        selectDate = function () {
            var activeDates = element.querySelectorAll("[data-calendar-status=active]");
            each(activeDates, function(date) {
                date.addEventListener("click", function () {
                    removeActiveClass();
                    removeTempClass();
                    var datas = this.dataset;
                    this.classList.add(prefix + "datepicker-date-selected");
                    currentSelected = new Date(datas.calendarDate);
                    setNewDate();
                });
            });
        },
        createMonth = function () {
            clearCalendar();
            var currentMonth = datePtr.getMonth();
            monthLabel.innerHTML = monthsTexts[datePtr.getMonth()] + " " + datePtr.getFullYear();
            //check to see if previous month is out of range
            var monthCheckBefore = new Date(datePtr.getTime());
            monthCheckBefore.setDate(monthCheckBefore.getDate() - 1); 
            if ((options.notBeforeDate && monthCheckBefore <= options.notBeforeDate) || (!options.pastDates && monthCheckBefore.getTime() <= todaysDate.getTime() - 1)) {
                options.button_prev.classList.add(prefix + "disabled");
                options.button_prev.setAttribute("disabled", "");
            } else {
                options.button_prev.classList.remove(prefix + "disabled");
                options.button_prev.removeAttribute("disabled");
            }
            //add previous month days on calendar
            var daysPrev = datePtr.getDay();
            if(daysPrev > 0) {
                for(var i=daysPrev; i>0; i--) {
                    var prevDate = new Date(datePtr.getTime());
                    prevDate.setDate(datePtr.getDate() - i);
                    createDay(prevDate);
                }
            }
            //add current month days onto calendar
            while (datePtr.getMonth() === currentMonth) {
                createDay(datePtr);
                datePtr.setDate(datePtr.getDate() + 1);
            }
            //check to see if next month is out of range
            var monthCheckAfter = new Date(datePtr.getTime());
            if(options.notAfterDate && monthCheckAfter > options.notAfterDate) {
                options.button_next.classList.add(prefix + "disabled");
                options.button_next.setAttribute("disabled", "");
            } else {
                options.button_next.classList.remove(prefix + "disabled");
                options.button_next.removeAttribute("disabled");
            }
            //add next month days onto calendar
            datePtr.setDate(datePtr.getDate() - 1);
            var daysAfter = 6 - datePtr.getDay();
            if (daysAfter > 0) {
                for(var j=1; j<=daysAfter; j++) {
                    var futureDate = new Date(datePtr.getTime());
                    futureDate.setDate(datePtr.getDate() + j);
                    createDay(futureDate);
                }
            }
            datePtr.setDate(1);
            selectDate();
            setFocusableElements();
        },
        monthPrev = function (e) {
            if (e) {
                e.preventDefault();
            }
            datePtr.setMonth(datePtr.getMonth() - 1);
            createMonth();
        },
        monthNext = function (e) {
            if (e) {
                e.preventDefault();
            }
            datePtr.setMonth(datePtr.getMonth() + 1);
            createMonth();
        },
        checkDateValidity = function(date) {
            var available_week_day = options.availableWeekDays.filter(function(f) {
                return (f.day === date.getDay() || f.day === getWeekDay(date.getDay()));
            });
            //check if valid weekday
            if(available_week_day.length == 0) {
                return false;
            }
            //check if not before
            if (options.notBeforeDate && date <= options.notBeforeDate) {
                return false;
            }
            //check if not after
            if (options.notAfterDate && date > options.notAfterDate) {
                return false;
            }
            // check if before today
            if (!options.pastDates && date.getTime() <= todaysDate.getTime() - 1) {
                return false;
            }
            return true;
        },
        clearCalendar = function () {
            monthEl.innerHTML = "";
        },
        createCalendar = function () {
            element.querySelector(target).innerHTML = "<div class=\"dds__datepicker-header\"><button type=\"button\" class=\"dds__datepicker-nav-btn\" aria-label=\"previous month\" data-calendar-toggle=\"previous\"><svg focusable=\"false\" class=\"dds__svg-icons\"><use xlink:href=\"#dds__chevron-left\"></use></svg></button><div class=\"dds__datepicker-header__label\" data-calendar-label=\"month\"></div><button type=\"button\" class=\"dds__datepicker-nav-btn\" aria-label=\"next month\"data-calendar-toggle=\"next\"><svg focusable=\"false\" class=\"dds__svg-icons\"><use xlink:href=\"#dds__chevron-right\"></use></svg></button></div><div class=\"dds__datepicker-week\"></div><div class=\"dds__datepicker-body\" data-calendar-area=\"month\"></div><div class=\"dds__calendar-buttons\"></div>";
            element.querySelector("." + prefix + "datepicker-week").innerHTML = "<span>SUN</span><span>MON</span><span>TUE</span><span>WED</span><span>THU</span><span>FRI</span><span>SAT</span>";
            var bottomMenu = element.querySelector("." + prefix + "calendar-buttons");
            bottomMenu.innerHTML = "<button type=\"button\" class=\"dds__btn dds__btn-secondary\" aria-label=\"cancel\">Cancel</button>";
            bottomMenu.querySelector("." + prefix + "btn-secondary").addEventListener("click", toggleCalendar, false);
            bottomMenu.querySelector("." + prefix + "btn-secondary").addEventListener("keydown", buttonKeydownHandler, false);
        },
        setNewDate = function() {
            var deliminator = "/";
            dateInput.value = ("0" + (currentSelected.getMonth() + 1)).slice(-2) + deliminator + ("0" + currentSelected.getDate()).slice(-2) + deliminator + currentSelected.getFullYear();
            var e = document.createEvent("Event");
            e.initEvent("change", false, false);
            dateInput.dispatchEvent(e);
            dateInput.focus();
            uicoreCustomEvent("DatePicker", "SelectedDate", element, { "date": currentSelected});
            toggleCalendar();
        },
        setNewTempDate = function(prevDate, currentDate) {
            //look for the new date
            tempSelected = getNextAvailableDay(prevDate, currentDate);
            //remove current selected
            var oldSelected = element.querySelector("[data-calendar-date=\"" + prevDate + "\"]");
            if (oldSelected) {
                oldSelected.children[0].setAttribute("tabindex", "-1");
                oldSelected.classList.remove(prefix + "datepicker-date-temp-selected");
            }
            var newDateElem = element.querySelector("[data-calendar-date=\"" + tempSelected + "\"]");
            var nextBtn = newDateElem.querySelector("." + prefix + "datepicker-calendar-day");
            nextBtn.removeAttribute("tabindex");
            nextBtn.parentElement.classList.add(prefix + "datepicker-date-temp-selected");
            nextBtn.focus();
        },
        getNextAvailableDay = function(prevDate, currentDate) {
            var newDate = element.querySelector(":not(.dds__datepicker-date-disabled):not(.dds__datepicker-date-outdated)[data-calendar-date='" + currentDate + "']");
            var direction = prevDate < currentDate ? 1 : -1;
            //check day
            if (newDate) {
                return currentDate;
            }
            else {
                //check if not before
                if (options.notBeforeDate && currentDate <= options.notBeforeDate) {
                    return prevDate;
                }
                //check if not after
                if (options.notAfterDate && currentDate >= options.notAfterDate) {
                    return prevDate;
                }
                // check if before today
                if (currentDate.getTime() <= todaysDate.getTime() - 1 && !options.pastDates) {
                    return prevDate;
                }
                if (currentDate.getMonth() != prevDate.getMonth()) {
                    if (direction == 1) {
                        monthNext();
                    }
                    if (direction == -1) {
                        monthPrev(); 
                    }
                    if (element.querySelector(":not(.dds__datepicker-date-disabled)[data-calendar-date='" + currentDate + "']")) {
                        return currentDate;
                    }
                    else {
                        prevDate = new Date (currentDate.getTime());
                        currentDate.setDate(currentDate.getDate() + direction);
                        return getNextAvailableDay(prevDate, currentDate);
                    }
                } else {
                    prevDate = new Date (currentDate.getTime());
                    currentDate.setDate(currentDate.getDate() + direction);
                    return getNextAvailableDay(prevDate, currentDate);
                }
            }
        },
        getNearestAvailableDay = function(currentDate) {
            if (checkDateValidity(currentDate)) {
                return currentDate;
            } else {
                var nextDate = new Date(currentDate.getTime());
                nextDate.setDate(nextDate.getDate() + 1);
                var prevDate = new Date(currentDate.getTime());
                prevDate.setDate(prevDate.getDate() - 1); 
                var nearestRight = getNextAvailableDay(currentDate, nextDate);
                if (nearestRight.getMonth() > currentDate.getMonth()) {
                    monthPrev();
                    removeTempClass();
                } 
                var nearestLeft = getNextAvailableDay(currentDate, prevDate);
                if (nearestRight.getMonth() > currentDate.getMonth()) {
                    return nearestLeft;
                } 
                if (nearestLeft.getMonth() < currentDate.getMonth()) {
                    monthNext();
                    removeTempClass();
                    return nearestRight;
                }
                var daysRight = Math.abs(currentDate.getDate() - nearestRight.getDate());
                var daysLeft = Math.abs(currentDate.getDate() - nearestLeft.getDate());
                //if getnextavailableday is not in available range
                if(daysLeft == 0 && daysRight == 0) {
                    var od1 = new Date(currentDate.getTime());
                    var od2 = new Date(currentDate.getTime());
                    while (!checkDateValidity(od1) && !checkDateValidity(od2)) {
                        if(od1.getMonth() == currentDate.getMonth()) {
                            od1.setDate((od1.getDate()-1));
                        }
                        if (od2.getMonth() == currentDate.getMonth()) {
                            od2.setDate((od2.getDate()+1));
                        }
                    }
                    if (checkDateValidity(od1)) {
                        return od1;
                    } else{
                        return od2;
                    }
                } else if (daysLeft == 0) {
                    return nearestRight;
                } else if (daysRight == 0) {
                    return nearestLeft;
                } else if(daysRight < daysLeft) {
                    return nearestRight;
                } else {
                    return nearestLeft;
                }
            }
        },
        dateKeydownHandler = function(e) {
            var prevSelected = new Date (tempSelected.getTime());
            switch (e.keyCode) {
            case keyCode.UP: // up key
                e.preventDefault();
                tempSelected.setDate(tempSelected.getDate() - 7);
                setNewTempDate(prevSelected, tempSelected);
                break;
            case keyCode.DOWN: // down key
                e.preventDefault();
                tempSelected.setDate(tempSelected.getDate() + 7);
                setNewTempDate(prevSelected, tempSelected);
                break;
            case keyCode.LEFT: // left key
                e.preventDefault();
                tempSelected.setDate(tempSelected.getDate() - 1);
                setNewTempDate(prevSelected, tempSelected);
                break;
            case keyCode.RIGHT: // right key
                e.preventDefault();
                tempSelected.setDate(tempSelected.getDate() + 1);
                setNewTempDate(prevSelected, tempSelected);
                break;
            case keyCode.ESC: // esc key
                var oldSelected = element.querySelector("." + prefix + "datepicker-date-selected");
                if (oldSelected) {
                    oldSelected.classList.remove(prefix + "datepicker-date-selected");
                    oldSelected.children[0].setAttribute("tabindex", "-1");
                }
                toggleCalendar();
                break;
            case keyCode.TAB: // tab key
                if ( focusableEls.length === 1 ) {
                    e.preventDefault();
                    break;
                }
                if ( e.shiftKey ) {
                    if ( document.activeElement === focusableEls[0] ) {
                        e.preventDefault();
                        focusableEls[ focusableEls.length - 1 ].focus();
                    }
                } else {
                    if ( document.activeElement === focusableEls[ focusableEls.length - 1 ] ) {
                        e.preventDefault();
                        focusableEls[0].focus();
                    }
                }
                break;
            }
        },
        buttonKeydownHandler = function(e) {
            switch (e.keyCode) {
            case keyCode.ESC: // esc key
                toggleCalendar();
                break;
            case keyCode.TAB: // tab key
                if ( focusableEls.length === 1 ) {
                    e.preventDefault();
                    break;
                }
                if ( e.shiftKey ) {
                    if ( document.activeElement === focusableEls[0] ) {
                        e.preventDefault();
                        focusableEls[ focusableEls.length - 1 ].focus();
                    }
                } else {
                    if ( document.activeElement === focusableEls[ focusableEls.length - 1 ] ) {
                        e.preventDefault();
                        focusableEls[0].focus();
                    }
                }
                break;
            }
        },
        inputHandler = function() {
            if(checkDateValidity(new Date(dateInput.value)) && getComputedStyle(errorMsg).display == "block") {
                errorMsg.style.display = "none";
                dateInput.setCustomValidity("");
            } else if ((!checkDateValidity(new Date(dateInput.value)))) {
                if (!isEdge || (isEdge && calendar.classList.contains(prefix + "d-none") && document.activeElement !== calendarBtn)) {
                    toggleCalendar();
                }
                if (getComputedStyle(errorMsg).display == "none") {
                    errorMsg.style.display = "block";
                    dateInput.setCustomValidity("Invalid Date");
                }
            }
        },
        submitHandler = function(event) {
            var formValidationErrorSuffix = "Feedback";
            if (form.checkValidity() === false) {
                each(form, function(el) {
                    if(el.tagName == "INPUT") {
                        var errorMsg = form.querySelector("#"+ el.getAttribute("id")+formValidationErrorSuffix);
                        setTimeout(function() {
                            if (el.classList.contains("dds__datepicker-input")) {
                                if (checkDateValidity(new Date(el.value))) {
                                    el.setCustomValidity("");
                                } else {
                                    el.setCustomValidity("Invalid Date");
                                }
                            }
                            if (!el.validity.valid) {
                                //if error message has not been turned on yet
                                if(errorMsg && !(getComputedStyle(errorMsg)["display"] == "block")){
                                    errorMsg.style.display = "block";
                                }
                                el.setAttribute("aria-invalid", "true");
                                el.setAttribute(
                                    "aria-describedby",
                                    el.getAttribute("id") + formValidationErrorSuffix
                                );
                            } else {
                                //if error message has not been turned off yet
                                if(errorMsg && (getComputedStyle(errorMsg)["display"] == "block")){
                                    errorMsg.style.display = "none";
                                } 
                                el.setAttribute("aria-invalid", "false");
                                el.setAttribute("aria-describedby", "");
                            }
                        }, 10);
                    }
                });
                event.preventDefault();
                event.stopPropagation();
            }

            form.classList.add("dds__was-validated");
        };
    //public functions
    this.init = function () {
        createCalendar();
        options.button_prev = document.querySelector(target + " [data-calendar-toggle=previous]");
        options.button_next = document.querySelector(target + " [data-calendar-toggle=next]");
        monthEl = document.querySelector(target + " [data-calendar-area=month]");
        monthLabel = document.querySelector(target + " [data-calendar-label=month]");
        datePtr.setDate(1);
        createMonth();
        calendarWrapper = element.querySelector("."+prefix+"calendar-wrapper") ? element.querySelector("."+prefix+"calendar-wrapper") : createWrapper();
        options.button_prev.addEventListener("click", function() {
            monthPrev();
            removeTempClass();
            var prevMonthDate = new Date(tempSelected.getTime());
            prevMonthDate.setMonth(prevMonthDate.getMonth()-1);
            if (tempSelected.getMonth() === prevMonthDate.getMonth()) {
                prevMonthDate.setDate(0);
            }
            setNewTempDate(prevMonthDate, getNearestAvailableDay(prevMonthDate));
            if(!options.button_prev.classList.contains(prefix + "disabled")) {
                options.button_prev.focus();
            }
        }, false);
        options.button_next.addEventListener("click", function() {
            monthNext();
            removeTempClass();
            var nextMonthDate = new Date(tempSelected.getTime());
            nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
            if (nextMonthDate.getMonth() - tempSelected.getMonth() >= 2) {
                nextMonthDate.setDate(0);
            }
            setNewTempDate(nextMonthDate, getNearestAvailableDay(nextMonthDate));
            if(!options.button_next.classList.contains(prefix + "disabled")) {
                options.button_next.focus();
            }
        }, false);
        options.button_prev.addEventListener("keydown", buttonKeydownHandler, false);
        options.button_next.addEventListener("keydown", buttonKeydownHandler, false);
    };
    
    this.destroy = function () {
        options.button_prev.removeEventListener("click", monthPrev, false);
        options.button_next.removeEventListener("click", monthNext, false);
        options.button_prev.removeEventListener("keydown", buttonKeydownHandler, false);
        options.button_next.removeEventListener("keydown", buttonKeydownHandler, false);
        clearCalendar();
        document.querySelector(target).innerHTML = "";
    };
    this.reset = function () {
        this.destroy();
        this.init();
    };
    // this.set = function (options) {
    //     for (var k in options) {
    //         if (opts.hasOwnProperty(k)) {
    //             opts[k] = options[k];
    //         } 
    //     }
    //     createMonth();
    // }
    if (!(stringDatePicker in element)) {
        // validate options
        if (calendarBtn == null) {
            throw new Error("There was a problem found with date picker button, please correct and try again");
        }
        if (target == null) {
            throw new Error("There was a problem found with date picker target, please correct and try again");
        }
        if (calendar == null) {
            throw new Error("There was a problem found with calendar, please correct and try again");
        }
        //remove time from date
        datePtr.setHours(0, 0, 0, 0);
        todaysDate.setHours(0, 0, 0, 0);
        calendarBtn.addEventListener("click", toggleCalendar, false);
        if (isEdge) {
            calendarBtn.addEventListener("click", inputHandler, false);
            dateInput.addEventListener("focusout", inputHandler, false);
        }
        if(!dateInput.hasAttribute("placeholder")){
            console.warn("Placeholder was not set. Please set a placeholder value if you plan to use form validation. Setting to default placeholder value.");
            dateInput.setAttribute("placeholder", "__/__/____");
        }
        if (dateInput) {
            new InputMask(dateInput);
            var errorMsg = element.querySelector("." + prefix + "invalid-feedback");
            dateInput.addEventListener("change", inputHandler, false);
        }
        form = getClosest(element, "FORM", false);
        form.addEventListener("submit", submitHandler);
        this.init();
        setFocusableElements();
        // //set input mask
        options.defaultDate = options.defaultDate ? (checkDateValidity(new Date (options.defaultDate)) ? options.defaultDate : "" ) : ""; 
        if (!options.defaultDate) {
            options.defaultDate = new Date();
            options.defaultDate.setHours(0, 0, 0, 0);
        } else {
            var defaultDate = new Date (options.defaultDate);
            var defaultDay = defaultDate.getDate() < 10 ? "0" + defaultDate.getDate() : defaultDate.getDate();
            var defaultMonth = (defaultDate.getMonth() + 1) < 10 ? "0" + (defaultDate.getMonth() + 1) : (defaultDate.getMonth() + 1);

            dateInput.setAttribute("value", defaultMonth + "/" + defaultDay + "/" + defaultDate.getFullYear());
            var e = document.createEvent("Event");
            e.initEvent("change", false, false);
            dateInput.dispatchEvent(e);
            if (isIE || isEdge) {
                element.style.display = "none"; //IE needs a small timeout or cursor will appear in input box
                setTimeout(function() {
                    dateInput.blur();
                    element.style.display = "block";
                }, 300);
            }   
        }
    }
    element[stringDatePicker] = self;
}

export function BarSelect(element) {
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var self = this,
        stringBarSelect = "BarSelect",
        selects = null,
        value = 0,
        clickHandler = function (e) {
            var input = e.target.classList.contains(prefix + "bar-select") ? e.target.querySelector("input") : e.target.tagName == "LABEL" ? e.target.previousElementSibling : e.target;
            value = parseInt(input.getAttribute("value"));
            each(selects, function(select) {
                if (parseInt(select.querySelector("input").getAttribute("value")) <= value) {
                    select.classList.remove(prefix + "bar-select-hover");
                    select.classList.add(prefix + "bar-select-selected");
                } else {
                    select.classList.remove(prefix + "bar-select-hover");
                    select.classList.remove(prefix + "bar-select-selected");
                }
            });
            uicoreCustomEvent("BarSelect", "SelectedValue", element, { "value": value});
        },
        mouseOverHandler = function(e) {
            var input = e.target.classList.contains(prefix + "bar-select") ? e.target.querySelector("input") : e.target.tagName == "LABEL" ? e.target.previousElementSibling : e.target;
            value = parseInt(input.getAttribute("value"));
            each(selects, function(select) {
                if (parseInt(select.querySelector("input").getAttribute("value")) <= value) {
                    select.classList.add(prefix + "bar-select-hover");
                } else {
                    select.classList.remove(prefix + "bar-select-hover");
                }
            });
        },
        mouseOutHandler = function() {
            each(selects, function(select) {
                select.classList.remove(prefix + "bar-select-hover");
            });
        },
        focusHandler = function(e) {
            var input = e.currentTarget.nextElementSibling;
            if (!isInViewport(input)) {
                input.scrollIntoView(false);
            }
        },
        isInViewport = function(element) {
            var bounding = element.getBoundingClientRect();
            return (
                bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

    if (!(stringBarSelect in element)) {
        selects = element.querySelectorAll("." + prefix + "bar-select");
        element.addEventListener("mouseout", mouseOutHandler, false);
        each(selects, function(select) {
            select.addEventListener("click", clickHandler, false);
            select.addEventListener("mouseover", mouseOverHandler, false);
            if (isIE || isFirefox) {
                select.querySelector("input").addEventListener("focusin", focusHandler, false);
            }
        });
    }

    element[stringBarSelect] = self;
}