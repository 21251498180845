import {each, prefix, debounce, createElement, classAdd, isArray, isIE, uicoreCustomEvent, emulateTransitionEnd, isEdge, renderSvg } from "./utilities";

export default function Overflow(element, options) {

    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    // set options
    options = options || {};
    if (element.dataset["type"]) {
        options.type = element.dataset["type"];
    }
    if (element.dataset["position"]) {
        options.position = element.dataset["position"];
    }
    if (element.dataset["top"]) {
        options.top = parseInt(element.dataset["top"]);
    }
    if (element.dataset["style"]) {
        options.style = element.dataset["style"];
    }

    options.type = options.type ? options.type : "single";
    options.style = options.style ? options.style === "svg" ? options.style = "svg" : "button" : "button";
    options.position = options.position && options.position === "outset" ? "outset" : "inset";
    options.top = options.top && typeof(options.top) === "number" ? options.top : null;
    options.lazyload = options.lazyload ? options.lazyload : false;
    options.responsive = options.responsive ?  options.responsive : false;

    var self = this,
        stringOverflow = "Overflow",
        overflowElement, // The UL container div
        overflowContainer, // The UL element
        overflowChildren,
        overflowChildRight,
        overflowChildLeft,
        centered = false,
        leftCtrl,
        rightCtrl,
        atLeftEnd = true,
        atRightEnd = true,
        ctrlWidth,
        containerLeft,
        containerRight,
        centeredOffset = 0,
        containerHeight = 0,
        containerWidth = 0,
        // handlers
        handleSizeChange = function() {
            if (containerWidth == overflowElement.offsetWidth) return;
            if (containerHeight !== overflowChildren.children[0].offsetHeight) {
                handleResize();
            }
            setLeftAndRightIndex();
            containerWidth = overflowElement.offsetWidth;

        },
        handleResize = debounce(function() {
            getChildrenWidth();
            setLeftAndRightIndex();
            addHeightStyle([leftCtrl,rightCtrl]);
            if ( (Math.abs(overflowContainer.offsetLeft) + overflowContainer.offsetWidth) > overflowChildren.width) {
                if(leftCtrl.hasAttribute("active")){
                    var currentOffset = pageRight();
                    overflowContainer.style.left = currentOffset + "px";
                }
            }
        }, 10),
        handleClickEvent = function(e) {
            var isRight = e.target.classList.contains(prefix+"overflow-control-right") ? true : false;
            controlClick(isRight);
        },
        controlClick = function(isRight) {
            var currentOffset = null;
            if (options.type == "single") {
                if (isRight) {
                    atLeftEnd = false;
                    var rightChild = overflowChildren.children[overflowChildRight+1];
                    var rightElementEdge = (rightChild.offsetLeft + rightChild.offsetWidth) - (Math.abs(overflowContainer.offsetLeft) + overflowContainer.offsetWidth);
                    if (overflowChildRight+1 >= (overflowChildren.children.length - 1) ) {
                        atRightEnd = true;
                    }
                    currentOffset = (Math.abs(overflowContainer.offsetLeft) + rightElementEdge + (options.position === "inset" && !atRightEnd ? ctrlWidth : 0))*-1;
                } else {
                    atRightEnd = false;
                    var leftChild = overflowChildren.children[overflowChildLeft-1];
                    if (overflowChildLeft-1 === 0) {
                        atLeftEnd = true;
                        currentOffset = 0;
                    } else {
                        currentOffset = (leftChild.offsetLeft - (options.position === "inset" && !atLeftEnd ? ctrlWidth : 0))*-1;
                    }
                }
            } else {
                if (isRight) {
                    currentOffset = pageRight();
                } else {
                    atRightEnd = false;
                    if (overflowChildren.children[overflowChildLeft].offsetLeft < overflowContainer.offsetWidth) {
                        atLeftEnd = true;
                        currentOffset = 0;
                    } else {
                        var tabLeft = Math.abs(overflowContainer.offsetLeft) - overflowChildren.children[overflowChildLeft-1].offsetLeft;
                        var contSpace = overflowContainer.offsetWidth - (options.position === "inset" ? ctrlWidth : 0) - overflowChildren.children[overflowChildLeft-1].offsetWidth;
                        currentOffset = ( Math.abs(overflowContainer.offsetLeft) - (tabLeft + contSpace))*-1;
                    }
                }
            }
            overflowContainer.style.left = currentOffset + "px";
            emulateTransitionEnd(overflowContainer, setLeftAndRightIndex);
        },
        pageRight = function() {
            var rightChild;
            atLeftEnd = overflowChildren.width < overflowContainer.offsetWidth ? true : false;
            var currentOffset = null;
            var remainder = overflowChildren.width - (Math.abs(overflowContainer.offsetLeft) + overflowContainer.offsetWidth);
            if (overflowContainer.offsetWidth >= remainder) {
                atRightEnd = true;
                rightChild = overflowChildren.children[overflowChildren.children.length-1];
                var rightElementEdge = (rightChild.offsetLeft + rightChild.offsetWidth) - (Math.abs(overflowContainer.offsetLeft) + overflowContainer.offsetWidth);
                currentOffset = (Math.abs(overflowContainer.offsetLeft) + rightElementEdge)*-1;
            } else {
                atRightEnd = false;
                rightChild = overflowChildren.children[overflowChildRight+1];
                currentOffset = (rightChild.offsetLeft - (options.position === "inset" && !atRightEnd ? ctrlWidth : 0))*-1;
            }
            return currentOffset;
        },
        setLeftAndRightCtrl = function() {
            if (atRightEnd) {
                rightCtrl.removeAttribute("active");
                if(isIE && centered && atLeftEnd) {
                    setMinimumWidth();
                }
            } else if (!rightCtrl.hasAttribute("active") )  {
                rightCtrl.setAttribute("active","");
                if(isIE && centered) {
                    overflowContainer.style.minWidth = "";
                }
            }
            if (atLeftEnd) {
                leftCtrl.removeAttribute("active");
            } else if (!leftCtrl.hasAttribute("active") )  {
                leftCtrl.setAttribute("active","");
            }
        },
        createArrowHtml = function() {
            leftCtrl = createElement("button", {
                class: prefix+"overflow-control "+prefix+"overflow-control-left "+(options.style === "button" ? prefix+"overflow-control-btn" : prefix+"overflow-control-svg"),
                tabIndex: "-1"
            });
            leftCtrl.style.left = options.position === "outset" ? "-8px" : "0px";
            leftCtrl.addEventListener("click", handleClickEvent, false);
            rightCtrl = createElement("button", {
                class: prefix+"overflow-control "+prefix+"overflow-control-right "+(options.style === "button" ? prefix+"overflow-control-btn" : prefix+"overflow-control-svg"),
                tabIndex: "-1"
            });
            rightCtrl.style.right = options.position === "outset" ? "-8px" : "0px";
            rightCtrl.addEventListener("click", handleClickEvent, false);
            if (options.top) {
                rightCtrl.style.top = options.top+"px";
                leftCtrl.style.top = options.top+"px";
            }
            var controls = [leftCtrl,rightCtrl];
            if (!options.responsive) {
                each(controls, function(control) {
                    control.classList.add(prefix + "overflow-unresponsive");
                });
            }
            addHeightStyle(controls);
            
            var leftSvg = renderSvg([{name:"chevron-left", show: true}], {"aria-hidden": true});
            classAdd(leftSvg, prefix+"overflow-control-left");
            var rightSvg = renderSvg([{name:"chevron-right", show: true}], {"aria-hidden": true});
            classAdd(rightSvg, prefix+"overflow-control-right");
            leftCtrl.appendChild(leftSvg);
            rightCtrl.appendChild(rightSvg);
            
            element.insertBefore(leftCtrl, element.children[0]);
            element.insertBefore(rightCtrl, element.children[1]);
            setCtrlWidth();
        },
        getChildrenWidth = function() {
            overflowChildren.width = 0;
            overflowChildren.children = [];
            each(overflowContainer.children, function(child) {
                overflowChildren.width += child.offsetWidth+getMargin(child);
                overflowChildren.children.push(child);
            });
            overflowChildren.width -= (isEdge ? overflowChildren.children.length - 1 : 0);
        },
        getMargin = function(child) {
            var style = child.currentStyle || window.getComputedStyle(child),
                margin;
            if (isIE) {
                margin = convertRemToPixels(style.getPropertyValue("margin-left")) + convertRemToPixels(style.getPropertyValue("margin-right"));
            } else {
                margin = parseInt(style.marginLeft) + parseInt(style.marginRight);
            }
            return margin;
        },
        addHeightStyle = function(els) {
            if (!isArray(els)) {
                els = [els];
            }
            each(els, function(el) {
                if (options.style === "button") {
                    el.style.height = overflowChildren.children[0].offsetHeight +"px";
                    containerHeight = overflowChildren.children[0].offsetHeight;
                }
            });
        },
        toggleCentered = function(offSet) {
            if (offSet < 0) {
                if (overflowContainer.parentElement.classList.contains(prefix+"tabs-centered-container")) {
                    overflowContainer.parentElement.classList.remove(prefix+"tabs-centered-container");
                }
                if (overflowContainer.classList.contains(prefix+"tab-centered")) {
                    overflowContainer.classList.remove(prefix+"tab-centered");
                }
                return false;
            } else {
                if (!overflowContainer.parentElement.classList.contains(prefix+"tabs-centered-container")) {
                    overflowContainer.parentElement.classList.add(prefix+"tabs-centered-container");
                }
                if (!overflowContainer.classList.contains(prefix+"tab-centered")) {
                    overflowContainer.classList.add(prefix+"tab-centered");
                }
                overflowContainer.style.left = "0px"; 
                return true;    
            }
        },
        setContainerLeftRight = function(useCentered, offSet) {
            if (useCentered) {
                containerLeft = offSet > 0 ? 0 : offSet;
                containerRight = overflowElement.offsetWidth;
                centeredOffset = Math.floor(offSet);
            } else {
                containerLeft = Math.abs(overflowContainer.offsetLeft);
                containerLeft += options.position === "inset" && !atLeftEnd ? ctrlWidth : 0;
                containerRight = containerLeft + overflowContainer.offsetWidth;
            }
        },
        setLeftAndRightIndex = function() {
            overflowChildLeft = null;
            overflowChildRight = null;
            var overflowChildLeftSet = false,
                offSet = (overflowElement.offsetWidth - overflowChildren.width)/2,
                useCentered = false;
            if (centered) {
                useCentered = toggleCentered(offSet);
            }
            setContainerLeftRight(useCentered, offSet);
            each(overflowChildren.children, function(child, idx) {
                var childLeft;
                if (useCentered) {
                    childLeft = centeredOffset + (Math.floor(overflowChildren.width/overflowChildren.children.length)*idx);
                } else {
                    childLeft = child.offsetLeft;
                }
                if (!overflowChildLeftSet && childLeft >= containerLeft) {
                    overflowChildLeft = idx;
                    overflowChildLeftSet = true;
                }
                if (childLeft+child.offsetWidth <= containerRight) {
                    overflowChildRight = idx;
                }
            });
            if (overflowChildLeft > 0 ) {
                atLeftEnd = false;
            } else {
                atLeftEnd = true;
            }
            if (overflowChildRight < (overflowChildren.children.length-1)) {
                atRightEnd = false;
            } else {
                atRightEnd = true;
            }
            setLeftAndRightCtrl();
            uicoreCustomEvent("Overflow", "ChangeEvent", element, {
                "left": overflowChildLeft, 
                "right": overflowChildRight, 
                "totalItems": overflowChildren.children.length,
                "hasRightControl": rightCtrl.hasAttribute("active"),
                "hasLeftControl": leftCtrl.hasAttribute("active")
            });
        },
        convertRemToPixels = function(rem) {  
            if (rem.indexOf("rem") > 0) { 
                rem = rem.split("rem")[0];
                var computed = rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
                if (computed < 0) {
                    return Math.floor(computed);
                } else {
                    return Math.ceil(computed);
                }
            } else {
                return 0;
            }
        },
        setCtrlWidth = function() {
            var style = rightCtrl.currentStyle || window.getComputedStyle(rightCtrl, null);
            if (isIE) {
                ctrlWidth = convertRemToPixels(style.getPropertyValue("width"));
            } else {
                ctrlWidth = parseInt(style.getPropertyValue("width").match(/^\d.?/g)[0]);
            }
        },
        setMinimumWidth = function() {
            var width = 0;
            each(overflowContainer.querySelectorAll("LI"), function(child) {
                width += 120+getMargin(child);
            });
            overflowContainer.style.minWidth = (width-5) + "px";
        },
        frameListener = function() {
            handleSizeChange();
            window.requestAnimationFrame(frameListener);
        },
        init = function(){
            getChildrenWidth();
            createArrowHtml();
            setLeftAndRightIndex();
            if (overflowChildRight < overflowChildren.children.length-1) {
                rightCtrl.setAttribute("active","");
            }
            if (isIE && centered && atLeftEnd && atRightEnd) {
                setMinimumWidth();
            }
            window.addEventListener("resize", handleResize, false);
            containerWidth = overflowElement.offsetWidth;
            window.requestAnimationFrame(frameListener);
        };
    
    this.lazyLoad = function() {
        if (stringOverflow in element) {
            init();
        }
    };

    this.getCurrentDetails = function() {
        return {"left": overflowChildLeft, 
            "right": overflowChildRight,
            "totalItems": overflowChildren.children.length,
            "hasRightControl": rightCtrl.hasAttribute("active"),
            "hasLeftControl": leftCtrl.hasAttribute("active")
        };
    };
    this.clickRight = function() {
        controlClick(true);
    };

    this.clickLeft = function() {
        controlClick(false);
    };

    this.clickEnd = function() {
        atLeftEnd = overflowChildren.width < overflowContainer.offsetWidth ? true : false;
        atRightEnd = true;
        if(rightCtrl.hasAttribute("active")){
            overflowContainer.style.left = ((overflowChildren.width - overflowContainer.offsetWidth + (options.position === "inset" && !atRightEnd ? ctrlWidth : 0))*-1)+"px";
        }
        rightCtrl.removeAttribute("active");
        emulateTransitionEnd(overflowContainer, setLeftAndRightIndex);
    };

    this.clickHome = function() {
        atLeftEnd = true;
        atRightEnd = false;
        if(leftCtrl.hasAttribute("active")){
            overflowContainer.style.left = "0px";
        }
        leftCtrl.removeAttribute("active");
        emulateTransitionEnd(overflowContainer, setLeftAndRightIndex);
    };

    //init
    if (!(stringOverflow in element)) {
        overflowElement = element;
        if (element.children[0].classList.contains(prefix + "tabs-centered-container")) {
            centered = true;
        }

        overflowElement.style.overflow = "hidden";
        overflowContainer = overflowElement.querySelector("UL");
        overflowContainer.classList.add(prefix + "overflow-transition");
        overflowContainer.setAttribute("style","position: relative; flex-wrap: nowrap; white-space: nowrap; left: 0px");
        overflowChildren = {};
        // Some child elements contain images so wait for page load to initialize.
        if (!options.lazyload) {
            init();
        }
        
    }

    element[stringOverflow] = self;
}
