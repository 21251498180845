import {getElementsByClassName,globalObject,supportTransitions,HTML,DOC,getTransitionDurationFromElement,prefix,uicoreCustomEvent, isIE, ieMosaicPolyfill, each, validateNum, getClosest, jsonOptionsInit, swipeInit, direction} from "./utilities.js";
import Overflow from "./overflow.js";

export function Carousel(element, options) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // set options
    options = options || {};
    options = jsonOptionsInit(element, options);
    options.interval = element.dataset.interval ? validateNum(element.dataset.interval, 5000) : validateNum(options.interval, 5000);
    options.pause = element.dataset.pause ? element.dataset.pause === "hover" ? element.dataset.pause : false : options.pause === "hover" ? options.pause : false;
    options.keyboard = element.dataset.keyboard ? element.dataset.keyboard === "true" ? true: false : options.keyboard === "true" ? true : false;
    options.mosaic = false;
    
    // DATA API
    var stringCarousel = "Carousel",
        visibilityState = "visible",
        smallMediaSize = window.matchMedia("(max-width: 767.98px)"),
        // strings
        paused = "paused",
        dataSlideTo = "data-slide-to",
        mouseHover = "onmouseleave" in DOC ? ["mouseenter", "mouseleave"] : ["mouseover", "mouseout"];

    // bind, event targets
    var self = this,
        stopAutoRotate = false,
        index = (element.index = 0),
        timer = (element.timer = 0),
        isSliding = false, // isSliding prevents click event handlers when animation is running
        slides = getElementsByClassName(element, prefix+"carousel-item"),
        total = slides["length"],
        slideDirection = (this["direction"] = "left"),
        // Arrow capability
        leftArrow = getElementsByClassName(
            element,
            prefix+"carousel-control-prev"
        )[0],
        rightArrow = getElementsByClassName(
            element,
            prefix+"carousel-control-next"
        )[0],
        // SVG indicators
        svgIndicator = element.querySelector("." + prefix+"carousel-indicators"),
        svgIndicators = (svgIndicator && svgIndicator["getElementsByTagName"]("LI")) || [],
        // Button indicatos
        btnIndicator = element.querySelector("." + prefix+"carousel-indicators-btn"),
        btnIndicators = (btnIndicator && btnIndicator["getElementsByTagName"]("LI")) || [],
        indicator = svgIndicator ? svgIndicator : btnIndicator,
        indicators = svgIndicators["length"] > 0 ? svgIndicators : btnIndicators;

    // invalidate when not enough items
    if (total < 2) {
        return;
    }

    // handlers
    var pauseHandler = function() {
            if (options.interval !== false && !element.classList["contains"](paused)) {
                element.classList.add(paused);
                !isSliding && clearTimer(timer);
            }
        },
        resumeHandler = function() {
            if (options.interval !== false && element.classList["contains"](paused)) {
                element.classList.remove(paused);
                !isSliding && clearTimer(timer);
                !isSliding && self.cycle();
            }
        },
        indicatorHandler = function(e) {
            e["preventDefault"]();
            if (isSliding) return;

            var eventTarget = e["target"]; // event target | the current active item

            if (eventTarget && !eventTarget.classList["contains"](prefix+"active") && eventTarget["getAttribute"](dataSlideTo)) {
                index = parseInt(eventTarget["getAttribute"](dataSlideTo), 10);
                stopAutoRotate = true;
            } else {
                return false;
            }

            self.slideTo(index); //Do the slide
        },
        controlsHandler = function(e) {
            e["preventDefault"]();
            if (isSliding) return;

            var eventTarget = e.currentTarget || e.srcElement;

            if (eventTarget === rightArrow) {
                index++;
            } else if (eventTarget === leftArrow) {
                index--;
            }

            self.slideTo(index); //Do the slide
        },
        keyHandler = function(e) {
            if (isSliding) return;
            switch (e.which) {
            case 39: index++; break; 
            case 37: index--; break;
            default: return;
            }
            self.slideTo(index); //Do the slide
        },
        swipeHandler =  function (el, d) {
            switch (d) {
            case direction.LEFT:
                self.slideTo(index+1);
                break;
            case direction.RIGHT:
                self.slideTo(index-1);
                break;                    
            }
        },
        // private methods
        isElementInScrollRange = function() {
            var rect = element["getBoundingClientRect"](),
                viewportHeight = globalObject[innerHeight] || HTML["clientHeight"];
            return rect["top"] <= viewportHeight && rect["bottom"] >= 0; // bottom && top
        },
        setActivePage = function(pageIndex) {
            //indicators
            each (indicators, function(indicator) {
                indicator.classList.remove(prefix+"active");
            });
            if (indicators[pageIndex]) indicators[pageIndex].classList.add(prefix+"active");
        },
        clearTimer = function(timer) {
            clearInterval(timer);
            timer = null;
        },
        toggleMosaic = function(e) {
            pauseHandler();
            if (e.matches) {
                stopAutoRotate = false;
                element.addEventListener(mouseHover[0], pauseHandler, false);
                element.addEventListener(mouseHover[1], resumeHandler, false);
                element.addEventListener("touchstart", pauseHandler, false);
                element.addEventListener("touchend", resumeHandler, false);
                resumeHandler();
            } else {
                stopAutoRotate = true;
                element.removeEventListener(mouseHover[0], pauseHandler, false);
                element.removeEventListener(mouseHover[1], resumeHandler, false);
                element.removeEventListener("touchstart", pauseHandler, false);
                element.removeEventListener("touchend", resumeHandler, false);
            }
        };

    // public methods
    this.cycle = function() {
        if(!stopAutoRotate) {
            if (timer) {
                clearTimer(timer);
            }
            timer = setInterval(function() {
                isElementInScrollRange() && (index++, self.slideTo(index));
            }, options.interval);
        } else {
            if (timer) {
                clearTimer(timer);
            }
        }
    };
    this.slideTo = function(next) {
        if (isSliding) return; // when controled via methods, make sure to check again

        var activeItem = this.getActiveIndex(); // the current active
        // orientation;
        // chaeck to see if we need to reset carousel
        
        // first return if we're on the same item #227
        if (activeItem === next) {
            return;
        }

        // determine slide direction
        if (activeItem < next) {
            slideDirection = "right";
        } else {
            slideDirection = "left";
        }

        // find the right next index
        if (next < 0) {
            next = total - 1;
        } else if (next >= total) {
            next = 0;
        }

        // update index
        index = next;

        uicoreCustomEvent("Carousel", "SlideEvent", element, { "direction": slideDirection, "slide-to" : next });

        isSliding = true;
        clearTimer(timer);
        setActivePage(next);

        if (supportTransitions && element.classList["contains"]("slide")) {
            // apply appropriate animation
            if (slideDirection === "right") {
                slides[next].classList.add(prefix+"carousel-item-next");
                slides[next]["offsetWidth"];
                slides[next].classList.add(prefix+"carousel-item-left");
                slides[activeItem].classList.add(prefix+"carousel-item-left");
            } else {
                slides[next].classList.add(prefix+"carousel-item-prev");
                slides[next]["offsetWidth"];
                slides[next].classList.add(prefix+"carousel-item-right");
                slides[activeItem].classList.add(prefix+"carousel-item-right");
            }
            var timeout = getTransitionDurationFromElement(slides[next]);

            isSliding &&
            setTimeout(function() {
                isSliding = false;

                slides[next].classList.add(prefix+"active");
                slides[activeItem].classList.remove(prefix+"active");

                slides[next].classList.remove(prefix+"carousel-item-next");
                slides[next].classList.remove(prefix+"carousel-item-prev");
                slides[next].classList.remove(prefix+"carousel-item-left");
                slides[next].classList.remove(prefix+"carousel-item-right");
                slides[activeItem].classList.remove(prefix+"carousel-item-left");
                uicoreCustomEvent("Carousel", "SlideEvent", element, { "direction": slideDirection, "slide-to" : next });
                
                if (!(visibilityState === "hidden") && options.interval && !element.classList["contains"](paused)) {
                    self.cycle();
                } else {
                    if(visibilityState === "hidden") {
                        pauseHandler();
                    }
                }
            }, timeout);

        } else {
            slides[next].classList.add(prefix+"active");
            slides[next]["offsetWidth"];
            slides[activeItem].classList.remove(prefix+"active");
            setTimeout(function() {
                isSliding = false;
                if (options.interval && !element.classList["contains"](paused)) {
                    self.cycle();
                }
                uicoreCustomEvent("Carousel", "SlideEvent", element, { "direction": slideDirection, "slide-to" : next });
            }, 100);
        }
    };
    this.getActiveIndex = function() {
        return (
            slides["indexOf"](
                getElementsByClassName(element, prefix+"carousel-item" + " " + prefix + "active")[0]
            ) || 0
        );
    };

    // init
    if (!(stringCarousel in element)) {
        
        if (element.classList.contains(prefix + "mosaic")){
            options.mosaic = true;
        }
      
        DOC.addEventListener("visibilitychange", function(){
            if (visibilityState === "hidden" && document.visibilityState === "visible") {
                resumeHandler();
            }
            visibilityState = document.visibilityState;
        });

        if (options.pause && options.interval) {
            if (!options.mosaic || (options.mosaic && smallMediaSize.matches)) {
                element.addEventListener(mouseHover[0], pauseHandler, false);
                element.addEventListener(mouseHover[1], resumeHandler, false);
                element.addEventListener("touchstart", pauseHandler, false);
                element.addEventListener("touchend", resumeHandler, false);
            } 
            if (options.mosaic) {
                if (isIE) {
                    ieMosaicPolyfill(slides);
                }
                if (!smallMediaSize.matches) {
                    stopAutoRotate = true;
                }
                smallMediaSize.addListener(toggleMosaic);
            }
        }

        swipeInit(element, swipeHandler);

        rightArrow && rightArrow.addEventListener("click", controlsHandler, false);
        leftArrow && leftArrow.addEventListener("click", controlsHandler, false);

        indicator && indicator.addEventListener("click", indicatorHandler, false);

        if (!svgIndicator) {
            each (indicators, function(indicator) {
                each (indicator.children, function(childIndicator) {
                    childIndicator.addEventListener("click", indicatorHandler);
                });
                indicator.addEventListener("click", indicatorHandler);
            });
        }

        options.keyboard === true && globalObject.addEventListener("keydown", keyHandler, false);
        if (self.getActiveIndex() < 0) {
            slides[length] && slides[0].classList.add(prefix+"active");
            indicators[length] && setActivePage(0);
        }
    
        if (options.interval) {
            self.cycle();
        }
    }
    element[stringCarousel] = self;
}

export function FilmstripCarousel(element, options) {
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    options = options || {};
    options = jsonOptionsInit(element, options);
    options.lazyload = typeof options.lazyload === "boolean" ? options.lazyload : false;

    var stringFilmstrip = "Filmstrip",
        activeElement,
        itemsView,
        overflow = {},
        overflowContainer,
        // handlers
        handleOverflowChange = function(e) {
            overflow.detail = e.detail;
            updateItemsView();
        },
        clickEventHandler = function(e) {
            e.preventDefault();
            var target = e.target;
            if (target && target.parentElement.tagName === "BUTTON") {
                target = target.parentElement;
            }
            if (activeElement) {
                activeElement.classList.remove(prefix + "active");
                activeElement.setAttribute("aria-pressed","false");
            }
            target.classList.add(prefix + "active");
            target.setAttribute("aria-pressed","true");
            activeElement = target;
            // Emit filter event
            uicoreCustomEvent("FilmstripCarousel", "ItemClick", element, {"target": target.dataset["filterValue"]});
        },
        updateItemsView = function() {
            if(itemsView){
                var rightNumber = overflow.detail.hasRightControl ? overflow.detail.right+1 : overflow.detail.totalItems,
                    leftNumber = overflow.detail.hasLeftControl ? overflow.detail.left+1 : 1;
                itemsView.innerHTML = "<span>Viewing " + (leftNumber) + (leftNumber < rightNumber ? " - " + (rightNumber) : "") + " of "+ overflow.detail.totalItems +" items </span>";
            }
        };

    this.lazyLoad = function() {
        if (options.lazyload && overflow) {
            overflow.lazyLoad();
            overflow.detail = overflow.getCurrentDetails();
            updateItemsView();
            overflowContainer.addEventListener("uicOverflowChangeEvent", handleOverflowChange, false);
            uicoreCustomEvent("FilmstripCarousel", "LazyLoadEvent", element, {"success": true});
        }
        else {
            uicoreCustomEvent("FilmstripCarousel", "LazyLoadEvent", element, {"success": false, "msg": "Carousel cannot be lazy loaded. Check usage or avoid mulitple lazy loads."});
        }
    };

    // prevent adding event handlers twice
    if (!(stringFilmstrip in element)) {
        itemsView = element.parentElement.querySelector("DIV.dds__items-view");
        each(element.querySelector("UL").querySelectorAll("LI BUTTON"), function(el) {
            if (el.classList.contains(prefix + "active")) {
                activeElement = el;
            }
            el.addEventListener("click", clickEventHandler, false);
        });

        overflowContainer = getClosest(element, "." + prefix + "container-overflow");
        if (overflowContainer) {
            overflow = new Overflow(overflowContainer, {
                position: "outset",
                style: "svg",
                top: 50,
                lazyload: options.lazyload
            });
            if (!options.lazyload) {
                overflow.detail = overflow.getCurrentDetails();
                updateItemsView();
                overflowContainer.addEventListener("uicOverflowChangeEvent", handleOverflowChange, false);
            }
        }
        
    }

    element[stringFilmstrip] = self;

}
