import { ariaAnnounce, classAdd, classRemove, createElement, debounce, each, DOC, getClosest, getFocusableElements, getNextFocusableElement, isIE, isIOS, isSafari, jsonOptionsInit, one, prefix, setFocus, uicoreCustomEvent } from "./utilities.js";

import Collapse from "./collapse.js";
import ResizeElement from "./helpers/resizeElement.js";

export function NavAnchored(element) {
    // initialization element, the element we spy on
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var self = this,
        items,
        tabs,
        modalElement,
        backButton,
        activeTab = null,
        focusableEls,
        stringNav = "Nav",
        sticky,
        isSticky = false,
        targetItems,
        tabbing = false,
        targetNav,
        modalSize = window.matchMedia("(max-width: 767.98px)"),
        exitFocus,
        // Event Handlers
        handleClickEvent = function(e) {
            activeTab = e.target;
            tabbing = false;
            if (modalSize.matches && modalElement && !(modalElement.classList.contains(prefix + "show"))) {
                showModal(activeTab);
                for (var index = 0; index < items["length"]; index++) {
                    var item = items[index];
                    if(index == activeTab.idx) {
                        activateTab(item);
                    } else {
                        deactivateTab(item);
                    }
                }
                e.preventDefault();
            } else {
                window.addEventListener("scroll", handleScrollEvent, false);
                if (!isSticky) {
                    if (isIE) {
                        element.scrollIntoView(true);
                    } else {
                        element.scrollIntoView({behavior: "smooth"});
                    }
                } 
                if (isIE) {
                    targetItems[activeTab.idx].scrollIntoView(true);
                } else {
                    targetItems[activeTab.idx].scrollIntoView({behavior: "smooth"});
                }
            }
        },
        handleScrollEvent = function() {
            var last_known_scroll_position = window.pageYOffset;
            stickNav(last_known_scroll_position);
            if (!tabbing) {
                window.requestAnimationFrame(updateItems);
            }
        },
        stickNav = function(itemTop) {
            var targetBottom = targetNav.getBoundingClientRect().bottom,
                spacer;
            if (itemTop > sticky && 0 <= targetBottom) {
                if (!isSticky) {
                    if(isIE) { // bug fix for position fixed on IE
                        spacer = createElement("div", {
                            class: prefix + "nav-spacer"
                        });
                        spacer.style.height = element.offsetHeight+"px";
                        setElementWidth(element);
                        element.parentElement.insertBefore(spacer, element);
                    }
                    element.style.zIndex = 1030;
                    element.classList.add(prefix + "active");
                    isSticky = true;
                }
            } else {
                if (isSticky) {
                    if (isIE) {
                        spacer = element.parentElement.querySelector("." + prefix + "nav-spacer");
                        if (spacer) {
                            element.parentElement.removeChild(spacer);
                        }
                        removeElementWidth();
                    }
                    element.style.zIndex = "";
                    element.classList.remove(prefix + "active");
                    isSticky = false;
                }
            }
        },
        dismissHandler = function(e) {
            if (modalElement.classList.contains(prefix + "show")) {
                hideModal();
                e.preventDefault();
                setFocus(activeTab);
            }
        },
        handleModalKeydown = function(e) {            
            var KEY_TAB = 9;
            var KEY_ESC = 27;
    
            switch(e.keyCode) {
            case KEY_TAB:
                if ( focusableEls.length === 1 ) {
                    e.preventDefault();
                    break;
                }
                if ( e.shiftKey ) {
                    handleBackwardTab(e);
                } else {
                    handleForwardTab(e);
                }
                break;
            case KEY_ESC: 
                hideModal();
                setFocus(activeTab);
                break;
            default:
                break;
            }
        },
        showModal = function(item) {
            createModal(item);
            modalElement.style.display = "block";
            modalElement.setAttribute("aria-hidden", false);
            triggerShow();
            modalElement.classList.add(prefix + "show");

            if (isSafari && isIOS) {
                modalElement.classList.add(prefix + "is-safari");
            }

            DOC.body.classList.add(prefix + "modal-open");

            backButton = modalElement && modalElement.querySelector("[data-dismiss='"+prefix+"modal']");
            backButton.addEventListener("click", dismissHandler, false);
            setFocusableElements();
            setTimeout(function() {
                setFocus(backButton);
            }, 50);
            modalElement.addEventListener("keydown", handleModalKeydown);
        },
        hideModal = function() {
            modalElement.classList.add(prefix + "slide-right");
            modalElement.classList.remove(prefix + "show");
            modalElement.setAttribute("aria-hidden", true);
            if (isSafari && isIOS && modalElement.classList.contains(prefix + "is-safari")) {
                modalElement.classList.remove(prefix + "is-safari");
            }
            setTimeout(function() {
                triggerHide();
                
                DOC.body.classList.remove(prefix + "modal-open");
                if (backButton) {
                    backButton.removeEventListener("click", dismissHandler, false);
                }
            }, 200);
        },
        createModal = function(tab) {
            var controls = tab.getAttribute("aria-controls");
            var isIconElement =
                (tab === tab.parentElement
                    .querySelector(".dds__icons.dds__chevron-right.dds__tab-icon"));

            if (!controls && isIconElement) {
                controls = tab.parentElement.getAttribute("aria-controls");
            }

            var tabContent = document.getElementById(controls).innerHTML;
    
            var modalBody = modalElement.querySelector("." + prefix+ "modal-body");
            modalBody.innerHTML = tabContent;
        },
        // triggers
        triggerShow = function() {
            setFocus(modalElement);
        },
        triggerHide = function() {
            modalElement.style.display = "";
        },
        setFocusableElements = function() {
            focusableEls = getFocusableElements(modalElement);
            modalElement.firstFocusableEl = focusableEls[0];
            modalElement.lastFocusableEl = focusableEls[ focusableEls.length - 1 ];
        },
        handleBackwardTab = function (e) {
            if ( document.activeElement === modalElement.firstFocusableEl ) {
                e.preventDefault();
                setFocus(modalElement.lastFocusableEl);
            }
        },
        handleForwardTab = function (e) {
            if ( document.activeElement === modalElement.lastFocusableEl ) {
                e.preventDefault();
                setFocus(modalElement.firstFocusableEl);
            }
        },
        setElementWidth = function(el) {
            element.style.width = el.offsetWidth+"px";
        },
        removeElementWidth = function() {
            element.style.width = "";
        },
        toggleElementWidth = debounce(function() {
            if (isSticky) {
                setElementWidth(element.parentElement);
            } else {
                removeElementWidth();
            }
        }, 10),
        // takes a number to slighly adjust scroll offset if needed        
        determineSection = function(scrollOffset) {
            scrollOffset = scrollOffset || 0;
            for (var i = 0; i < targetItems.length; i++) {
                if (targetItems[i].getBoundingClientRect().top + scrollOffset > 0) {
                    return i;
                }
            }
            return targetItems.length >= 1 ? targetItems.length : 1;
        },
        handleEnterEvent = function(e) {
            var target = e.target;
            tabbing = true;
            if (e.keyCode == 9) {
                if (!modalSize.matches) {
                    var currentSection = (determineSection(-5) -1);
                    currentSection = currentSection < 0 ? 0 : currentSection;
                    if (isIE) {
                        targetItems[target.idx].scrollIntoView(true);
                    } else {
                        targetItems[target.idx].scrollIntoView({behavior: "smooth"});
                    }
                    toggleTab(tabs[currentSection],tabs[target.idx]);
                }
            }
            tabbing = false;
        },
        handleKeydownEvent = function(e) {
            var key = e.keyCode,
                idx = e.target.idx;
            
            switch(key) {
            case 9: if (!e.shiftKey) {
                if (modalSize.matches) {
                    e.preventDefault(); 
                    setFocus(exitFocus);
                } else {
                    tabbing = true;
                    var focusEl;
                    each(targetItems, function(target, iter) {
                        if (iter >= idx && target.focusableEls.length > 0 && !focusEl) {
                            focusEl = target.focusableEls;
                        }
                    });
                    if (focusEl) { e.preventDefault(); setFocus(focusEl[0]); }
                    else if (exitFocus) { e.preventDefault(); setFocus(exitFocus); }
                }
            }
                break;
            case 13:
            case 32: // enter and spacebar keys
                e.preventDefault();
                tabbing = false;
                setFocus(e.target);
                e.target.click();
                break;
            case 35:    e.preventDefault();
                setFocus(items[tabs.length-1]);
                items[tabs.length-1].click();
                break;
            case 36:    e.preventDefault();
                setFocus(items[0]);
                items[0].click();
                break;
            case 39:    e.preventDefault();
                if (idx === items.length-1) {
                    setFocus(items[0]);
                } else {
                    setFocus(items[idx+1]);
                }
                break;
            case 37:    e.preventDefault();
                if (idx === 0) {
                    setFocus(items[items.length-1]);
                } else {
                    setFocus(items[idx-1]);
                }
                break;
            }
        },
        updateItems = function() {
            var section = (determineSection(-5) -1);
            section = section < 0 ? 0 : section;
            for (var index = 0; index < items["length"]; index++) {
                var item = items[index];
                if (isSticky) {
                    if(index == section) {
                        activateTab(item);
                    } else {
                        deactivateTab(item);
                    }
                } else {
                    if (index == 0) {
                        activateTab(item);
                    } else {
                        deactivateTab(item);
                    }
                }
            }
            return item;
        },
        toggleTab = function(oldItem, newItem) {
            deactivateTab(oldItem);
            activateTab(newItem);
        },
        activateTab = function(item) {
            item.setAttribute("tabindex","0");
            item.classList.add(prefix + "active");
            item.setAttribute("aria-selected","true");
        },
        deactivateTab = function(item) {
            item.setAttribute("tabindex","-1");
            item.classList.remove(prefix + "active");
            item.setAttribute("aria-selected","false");
        },
        activateItems = function() {
            element.setAttribute("style", "display: none !important;");
            each(targetItems, function(item) {
                item.setAttribute("style", "display: block;");
            });
        };
        
    // init - prevent adding event handlers twice
    if (!(stringNav in element)) {

        modalElement = element.dataset["target"] && DOC.getElementById(element.dataset["target"].substr(1));

        targetNav = element.nextElementSibling;
        
        tabs = element.querySelectorAll("li button");

        sticky = element.getBoundingClientRect().top;
        items = [];
        targetItems = [];
        
        if (isIE) {
            window.addEventListener("resize", toggleElementWidth, false);
        }
        
        // populate items and targets
        for (var i=0; i < tabs.length; i++) {
            tabs[i].idx = i;
            items.push(tabs[i]);
            items[i].addEventListener("click", handleClickEvent, false);
            items[i].addEventListener("keydown", handleKeydownEvent, false);
            var panel = document.getElementById(tabs[i].dataset["target"].slice(1));
            panel.scrollTop += element.offsetHeight;
            panel.focusableEls = getFocusableElements(panel);
            each(panel.focusableEls, function(field) {
                field.addEventListener("keyup", handleEnterEvent, false);
                field.idx = i;
            });
            targetItems.push(panel);
        }
        if (targetItems[targetItems.length-1].focusableEls.length == 0) { // last panel and no focusable items
            DOC.addEventListener("DOMContentLoaded", function() { // make sure all HTML is available prior to checking
                exitFocus = getNextFocusableElement(panel);
            });
        }

        if (modalSize.matches) {
            window.removeEventListener("scroll", handleScrollEvent, false);
            if (!modalElement) {
                activateItems();
            }
        } else {
            window.addEventListener("scroll", handleScrollEvent, false);
        }
        modalSize.addListener(function() {
            if (!modalSize.matches) {
                element.removeAttribute("style");
                window.addEventListener("scroll", handleScrollEvent, false);
                if (modalElement) {
                    hideModal();
                }
            } else {
                element.classList.remove(prefix + "active");
                var spacer = element.parentElement.querySelector("." + prefix + "nav-spacer");
                if (spacer) {
                    element.parentElement.removeChild(spacer);
                }
                window.removeEventListener("scroll", handleScrollEvent, false);
                if (!modalElement) {
                    activateItems();
                }
            }
        });

    }

    element[stringNav] = self;
}

export function NavSkip(element) {

    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var self = this,
        stringNavSkip = "NavSkip",
        hiddenZindex = "-1000",
        visibleZindex = "1080",
        tabindex,
        borderStyle,
        dataInset,
   
        // handlers
        focusInHandler = function(e) {
            if (element === e.target || element.contains(e.target)) {
                element.style.zIndex = visibleZindex;
                element.style.opacity = "1";
                each(element.querySelectorAll("a." + prefix + "btn"), function(button) {
                    button.addEventListener("click", clickHandler, false);
                });
            }
            element.addEventListener("focusout", focusOutHandler);
        },

        focusOutHandler = function(e) {
            var target = e.relatedTarget ? e.relatedTarget : DOC.activeElement;
            if (!element.contains(target) && !(element === target)) {
                toggleZindex();
            }
        },    
        
        toggleZindex = function() {
            element.style.zIndex = hiddenZindex;
            element.style.opacity = "0";
            each(element.querySelectorAll("a." + prefix + "btn"), function(button) {
                button.removeEventListener("click", clickHandler, false);
            });
            element.removeEventListener("focusout", focusOutHandler);

        },

        clickHandler = function(e) {
            // element.style.zIndex = hiddenZindex;
            toggleZindex();
            var focusElement = document.getElementById(e.target.getAttribute("href").substr(1));
            tabindex = focusElement.getAttribute("tabIndex");
            if (tabindex !== 0) {
                focusElement.setAttribute("tabIndex", "0"); 
            } 
            focusElement.addEventListener("focusout", blurHandler, false);
            setTimeout( function() {
                focusElement.focus();
            }, 100); //firefox needs a little timeout to register tabindex
            if (e.target.getAttribute("data-inset") === "true") { //only add special border if pass in this attr
                dataInset = true;
                borderStyle = focusElement.style.border;
                setTimeout( function() {  
                    focusElement.style.border = "2px inset #000000";
                }, 10);
            } else {
                dataInset = false;
            }
        },

        blurHandler = function(e) { 
            if (dataInset) {
                e.target.style.border = borderStyle;
            }
            if (tabindex !== 0) {
                tabindex == null ? e.target.removeAttribute("tabIndex") : e.target.setAttribute("tabIndex", tabindex);
            }
            e.target.removeEventListener("focusout", blurHandler, false); //once one blur event occurs, we want to remove the event listener
        };

    // init
    if (!(stringNavSkip in element)) {
        // prevent adding event handlers twice
        element.addEventListener("focusin", focusInHandler);
        // window.addEventListener("keyup", keydownZindexHandler);
        // var focusableEls = document.querySelectorAll("a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex='0']");
        // focusableEls[0].addEventListener("keyup", keydownZindexHandler);
        // if using Mac, keyboard accessbility  will only work if you set up this setting: https://stackoverflow.com/questions/11704828/how-to-allow-keyboard-focus-of-links-in-firefox
        // On Mac: In System Preferences → Keyboard, in the Shortcuts pane, check the “all controls” radio at the bottom.
        // Safari on Mac: additionally, go to safari preferences-> Advanced -> Accessibility "Press Tab to highlight each item on a webpage"
        // Firefox on Mac: additionally, type "about:config" in the URL bar. There is no accessibility.tabfocus preference on the mac, so you'll have to make one. Right click in the window, create a new "integer" pref, and set it to 7.
    }


    element[stringNavSkip] = self;
}

export function NavAnchoredVertical(element, options) { 
    // initialization element, the element we spy on
    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    var opt = {};

    if (options && Object.keys(options).length) {
        opt.showModal = typeof options.showModal === "boolean" && options.showModal;
    }

    var self = this,
        activeCSSClassName = prefix + "active",
        activeTab = null,
        backButton,
        exitFocus,
        focusableEls,
        modalElement,
        modalSize = window.matchMedia("(max-width: 767.98px)"),
        isSticky = false,
        items,
        stringNav = "Nav",
        sticky,
        tabs,
        targetItems,
        tabbing = false,
        targetNav,
        // Event Handlers
        handleClickEvent = function (e) {
            var scrollViewType = !isIE ? { behavior: "smooth" } : true;
            var shouldShowModal = opt.showModal && 
                modalSize.matches &&
                modalElement && 
                !(modalElement.classList.contains(prefix + "show"));

            var isClickOnIconElement = (e.target === e.target.parentElement
                .querySelector(".dds__icons.dds__chevron-right.dds__tab-icon"));

            activeTab = isClickOnIconElement ? e.target.parentElement : e.target;
            tabbing = false;

            if (shouldShowModal) {
                showModal(activeTab);
                for (var index = 0; index < items["length"]; index++) {
                    var item = items[index];
                    if (index == activeTab.idx) {
                        activateTab(item);
                    } else {
                        deactivateTab(item);
                    }
                }
                return e.preventDefault();
            } 

            window.addEventListener("scroll", handleScrollEvent, false);
            
            if (!isSticky) {
                element.scrollIntoView(scrollViewType);
            }

            targetItems[activeTab.idx].scrollIntoView(scrollViewType);
        },
        handleScrollEvent = function () {
            var lastKnownScrollPosition = window.pageYOffset;
            stickNav(lastKnownScrollPosition);
            if (!tabbing) {
                window.requestAnimationFrame(updateItems);
            }
        },
        stickNav = function (lastKnownScrollPosition) {
            // var lastTargetContent = targetItems[targetItems.length - 1];

            var target = targetNav.getBoundingClientRect();
            var navPositionCoords = element.parentElement.getBoundingClientRect();

            var navOnTopScreen = (navPositionCoords.top <= 0);
            var elemClassList = element.classList;

            if (lastKnownScrollPosition > sticky && 0 <= target.bottom) {
                elemClassList.add(activeCSSClassName);
                element.style.zIndex = 1030;
                isSticky = true;
            } else if (isSticky) {
                elemClassList.remove(activeCSSClassName);
                element.style.zIndex = "";
                isSticky = false;
            }

            if (isIE) {
                var navItem = element.querySelectorAll(".dds__nav-anchored-vertical-item");
                var lastNavItem = navItem[navItem.length - 1];
                
                var positionOflastNavItemBottom = lastNavItem.getBoundingClientRect().bottom;
                var negativeTop = target.bottom - lastNavItem.parentElement.getBoundingClientRect().height;
                var isNavAfterLastContentBottom = (target.bottom >= positionOflastNavItemBottom);

                if (navOnTopScreen) {
                    element.style.top = isNavAfterLastContentBottom ? "0px" : negativeTop + "px";
                    element.style.position = "fixed";
                    element.style.height = target.bottom +"px";

                    targetNav.style.marginLeft ="270px";
                    return;
                }

                element.style.height ="auto";
                element.style.position ="relative";
                targetNav.style.marginLeft ="0";
            }
        },
        dismissHandler = function (e) {
            if (modalElement.classList.contains(prefix + "show")) {
                hideModal();
                e.preventDefault();
                setFocus(activeTab);
            }
        },
        handleModalKeydown = function (e) {
            var KEY_TAB = 9;
            var KEY_ESC = 27;

            switch (e.keyCode) {
            case KEY_TAB:
                if (focusableEls.length === 1) {
                    e.preventDefault();
                    break;
                }
                if (e.shiftKey) {
                    handleBackwardTab(e);
                } else {
                    handleForwardTab(e);
                }
                break;
            case KEY_ESC:
                hideModal();
                setFocus(activeTab);
                break;
            default:
                break;
            }
        },
        showModal = function (item) {
            createModal(item);
            modalElement.style.display = "block";
            modalElement.setAttribute("aria-hidden", false);
            triggerShow();
            modalElement.classList.add(prefix + "show");

            if (isSafari && isIOS) {
                modalElement.classList.add(prefix + "is-safari");
            }

            DOC.body.classList.add(prefix + "modal-open");

            backButton = modalElement && modalElement.querySelector("[data-dismiss='" + prefix + "modal']");
            backButton.addEventListener("click", dismissHandler, false);
            setFocusableElements();
            setTimeout(function () {
                setFocus(backButton);
            }, 50);
            modalElement.addEventListener("keydown", handleModalKeydown);
        },
        hideModal = function () {
            modalElement.classList.add(prefix + "slide-right");
            modalElement.classList.remove(prefix + "show");
            modalElement.setAttribute("aria-hidden", true);
            if (isSafari && isIOS && modalElement.classList.contains(prefix + "is-safari")) {
                modalElement.classList.remove(prefix + "is-safari");
            }
            setTimeout(function () {
                triggerHide();

                DOC.body.classList.remove(prefix + "modal-open");
                if (backButton) {
                    backButton.removeEventListener("click", dismissHandler, false);
                }
            }, 200);
        },
        createModal = function (tab) {
            var controls = tab.getAttribute("aria-controls");
            var isIconElement = 
                (tab === tab.parentElement
                    .querySelector(".dds__icons.dds__chevron-right.dds__tab-icon"));

            if (!controls && isIconElement) {
                controls = tab.parentElement.getAttribute("aria-controls");
            }

            var tabContent = document.getElementById(controls).innerHTML;

            var modalBody = modalElement.querySelector("." + prefix + "modal-body");
            modalBody.innerHTML = tabContent;
        },
        // triggers
        triggerShow = function () {
            setFocus(modalElement);
        },
        triggerHide = function () {
            modalElement.style.display = "";
        },
        setFocusableElements = function () {
            focusableEls = getFocusableElements(modalElement);
            modalElement.firstFocusableEl = focusableEls[0];
            modalElement.lastFocusableEl = focusableEls[focusableEls.length - 1];
        },
        handleBackwardTab = function (e) {
            if (document.activeElement === modalElement.firstFocusableEl) {
                e.preventDefault();
                setFocus(modalElement.lastFocusableEl);
            }
        },
        handleForwardTab = function (e) {
            if (document.activeElement === modalElement.lastFocusableEl) {
                e.preventDefault();
                setFocus(modalElement.firstFocusableEl);
            }
        },
        setElementWidth = function (el) {
            element.style.width = el.offsetWidth + "px";
        },
        removeElementWidth = function () {
            element.style.width = "";
        },
        toggleElementWidth = debounce(function () {
            if (isSticky) {
                setElementWidth(element.parentElement);
            } else {
                removeElementWidth();
            }
        }, 10),
        toogleContent = debounce(function () {
            each(targetItems, function (panel) {
                if (opt.showModal && !modalSize.matches) {
                    panel.classList.remove("modal-off");
                    panel.classList.remove("modal-on");
                } else {
                    panel.classList.add("modal-off");
                    panel.classList.add("modal-on");
                }
            });
        }, 10),
        // takes a number to slighly adjust scroll offset if needed        
        determineSection = function (scrollOffset) {
            scrollOffset = scrollOffset || 0;
            for (var i = 0; i < targetItems.length; i++) {
                if (targetItems[i].getBoundingClientRect().top + scrollOffset > 0) {
                    return i;
                }
            }

            return targetItems.length >= 1 ? targetItems.length : 1;
        },
        handleEnterEvent = function (e) {
            var target = e.target;
            tabbing = true;
            if (e.keyCode == 9) {
                if (!modalSize.matches) {
                    var currentSection = (determineSection(-5) - 1);
                    currentSection = currentSection < 0 ? 0 : currentSection;
                    if (isIE) {
                        targetItems[target.idx].scrollIntoView(true);
                    } else {
                        targetItems[target.idx].scrollIntoView({ behavior: "smooth" });
                    }
                    toggleTab(tabs[currentSection], tabs[target.idx]);
                }
            }
            tabbing = false;
        },
        handleKeydownEvent = function (e) {
            var key = e.keyCode,
                idx = e.target.idx;

            switch (key) {
            case 9: if (!e.shiftKey) {
                if (modalSize.matches) {
                    e.preventDefault();
                    setFocus(exitFocus);
                } else {
                    tabbing = true;
                    var focusEl;
                    each(targetItems, function (target, iter) {
                        if (iter >= idx && target.focusableEls.length > 0 && !focusEl) {
                            focusEl = target.focusableEls;
                        }
                    });
                    if (focusEl) { e.preventDefault(); setFocus(focusEl[0]); }
                    else if (exitFocus) { e.preventDefault(); setFocus(exitFocus); }
                }
            }
                break;
            case 13:
            case 32: // enter and spacebar keys
                e.preventDefault();
                tabbing = false;
                setFocus(e.target);
                e.target.click();
                break;
            case 35: e.preventDefault();
                setFocus(items[tabs.length - 1]);
                items[tabs.length - 1].click();
                break;
            case 36: e.preventDefault();
                setFocus(items[0]);
                items[0].click();
                break;
            case 39: e.preventDefault();
                if (idx === items.length - 1) {
                    setFocus(items[0]);
                } else {
                    setFocus(items[idx + 1]);
                }
                break;
            case 37: e.preventDefault();
                if (idx === 0) {
                    setFocus(items[items.length - 1]);
                } else {
                    setFocus(items[idx - 1]);
                }
                break;
            }
        },
        updateItems = function () {
            var section = (determineSection(-5) - 1);
            section = section < 0 ? 0 : section;
            for (var index = 0; index < items["length"]; index++) {
                var item = items[index];
                if (isSticky) {
                    if (index == section) {
                        activateTab(item);
                    } else {
                        deactivateTab(item);
                    }
                } else {
                    if (index == 0) {
                        activateTab(item);
                    } else {
                        deactivateTab(item);
                    }
                }
            }
            return item;
        },
        toggleTab = function (oldItem, newItem) {
            deactivateTab(oldItem);
            activateTab(newItem);
        },
        activateTab = function (item) {
            item.setAttribute("tabindex", "0");
            item.classList.add(prefix + "active");
            item.setAttribute("aria-selected", "true");
        },
        deactivateTab = function (item) {
            item.setAttribute("tabindex", "-1");
            item.classList.remove(prefix + "active");
            item.setAttribute("aria-selected", "false");
        },
        activateItems = function () {
            element.setAttribute("style", "display: none !important;");
            each(targetItems, function (item) {
                item.setAttribute("style", "display: none;");
            });
        };

    // init - prevent adding event handlers twice
    if (!(stringNav in element)) {

        modalElement = element.dataset["target"] && DOC.getElementById(element.dataset["target"].substr(1));

        targetNav = element.nextElementSibling;

        tabs = element.querySelectorAll("li button");
        sticky = element.getBoundingClientRect().top;
        items = [];
        targetItems = [];

        // populate items and targets
        for (var i = 0; i < tabs.length; i++) {
            tabs[i].idx = i;
            items.push(tabs[i]);
            items[i].addEventListener("click", handleClickEvent, false);
            items[i].addEventListener("keydown", handleKeydownEvent, false);

            var panel = document.getElementById(tabs[i].dataset["target"].slice(1));
            panel.scrollTop += element.offsetHeight;
            panel.focusableEls = getFocusableElements(panel);

            each(panel.focusableEls, function (field) {
                field.addEventListener("keyup", handleEnterEvent, false);
                field.idx = i;
            });

            targetItems.push(panel);
            
            if (opt.showModal && modalSize.matches) {
                panel.classList.add("modal-on");
                panel.classList.remove("modal-off");
            } else {
                panel.classList.add("modal-off");
                panel.classList.remove("modal-off");
            }
        }

        if (targetItems[targetItems.length - 1].focusableEls.length == 0) { // last panel and no focusable items
            DOC.addEventListener("DOMContentLoaded", function () { // make sure all HTML is available prior to checking
                exitFocus = getNextFocusableElement(panel);
            });
        }

        if (modalSize.matches && !opt.showModal) {    
            window.removeEventListener("scroll", handleScrollEvent, false);

            if (!modalElement) {
                activateItems();
            }

            element
                .setAttribute("style", "display: none !important;");
        } else {
            window.addEventListener("scroll", handleScrollEvent, false);
        }

        window.addEventListener("resize", function () {
            if (modalSize.matches && !opt.showModal) {
                return element
                    .setAttribute("style", "display: none !important;");
            }

            opt.showModal && toogleContent();

            if (isIE) {
                opt.showModal && stickNav(window.pageYOffset);
                toggleElementWidth();
            }
        }, false);

        modalSize.addListener(function () {
            if (!modalSize.matches) {
                element.removeAttribute("style");
                window.addEventListener("scroll", handleScrollEvent, false);
                if (modalElement) {
                    hideModal();
                }
            } else {
                element.classList.remove(prefix + "active");
                var spacer = element.parentElement.querySelector("." + prefix + "nav-spacer");
                if (spacer) {
                    element.parentElement.removeChild(spacer);
                }
                window.removeEventListener("scroll", handleScrollEvent, false);
                if (!modalElement) {
                    activateItems();
                }
            }
        });
    }

    element[stringNav] = self;
}

export function NavLeft(element, options) {
    // initialization element, the element we spy on
    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    // set options
    options = options || {};
    options = jsonOptionsInit(element, options);
    options.menu = element.dataset.menu ? element.dataset.menu : options.menu ? options.menu : "navLeft-list";
    options.main = element.dataset.main ? element.dataset.main : options.main ? options.main : null;
    options.suffix = element.dataset.suffix ? element.dataset.suffix : options.suffix ? options.suffix : null;
    options.arrows = element.dataset.arrows ? element.dataset.arrows : options.arrows ? options.arrows : "right"; // can be "left", "right", or "center"
    options.selected = element.dataset.selected ? element.dataset.selected : options.selected ? options.selected : null;
    options.altmenu = element.dataset.altmenu ? element.dataset.altmenu : options.altmenu ? options.altmenu : null; 
    options.replace = element.dataset.replace ? element.dataset.replace == "true" : options.replace ? options.replace == "true" : false; 

    // warn if required options are not provided
    if (!options.main) {
        throw new Error("Left Nav 'main' option must be set.");
    }

    var self = this,
        stringNavLeft = "NavLeft",
        masthead,
        desktopLeftNav,
        siteBody,
        offCanvas,
        suffix,
        footer,
        buttonToggle,
        footerHeight,
        mastheadHeight,
        visibleFooter,
        parentAccordions = [],
        focusableElms,
        KEY_TAB = 9,
        KEY_ESC = 27,
        defaultMain = options.menu === "navLeft-list",
        adjustHeightForFooter = function () {
            if (!footer) {
                return;
            }
            visibleFooter = (document.documentElement.clientHeight - footer.getBoundingClientRect().top);
            visibleFooter = visibleFooter < 0 ? 0 : visibleFooter;
            var calcHeight = (document.documentElement.clientHeight - visibleFooter);
            if (visibleFooter > 0) {
                buttonToggle.style.height = element.style.height = calcHeight + "px";
            }
            if (suffix) {
                if (visibleFooter > 0) {
                    suffix.style.bottom = visibleFooter + "px";
                }
                desktopLeftNav.style.maxHeight = (document.documentElement.clientHeight - suffix.getBoundingClientRect().height - footerHeight) + "px";
            }
        },
        adjustHeightForMasthead = function () {
            if (!masthead) {
                return;
            }
            var calcHeight;
            mastheadHeight = masthead.getBoundingClientRect().height;
            if (window.pageYOffset > mastheadHeight) {
                // if masthead not is visible
                element.style.top = "0px";
                if (!isFooterVisible()) {
                    buttonToggle.style.height = element.style.height = document.documentElement.clientHeight + "px";
                } else {
                    buttonToggle.style.height = element.style.height = (document.documentElement.clientHeight  - visibleFooter)+ "px";
                }
            } else {
                element.style.top = (mastheadHeight - window.pageYOffset) + "px";
                if (isFooterVisible()) {
                    calcHeight = (parseInt(window.getComputedStyle(element).height, 10) - (mastheadHeight - window.pageYOffset));
                    buttonToggle.style.height = element.style.height = calcHeight + "px";
                } else {
                    calcHeight = (document.documentElement.clientHeight - mastheadHeight + window.pageYOffset);
                    buttonToggle.style.height = element.style.height = calcHeight + "px";
                }
            }
        },
        alignChevrons = function(accordionButtons) {
            if (accordionButtons) {
                if (options.arrows === "right") {
                    each(accordionButtons, function (accordionButton) {
                        classAdd(accordionButton, prefix + "navLeft-button-optionRight");
                    });
                } else if (options.arrows === "center") {
                    each(accordionButtons, function (accordionButton) {
                        classAdd(accordionButton, prefix + "navLeft-button-optionCenter");
                        accordionButton.children[1].after(accordionButton.children[0]);
                    });
                }
            }
        },
        cloneLeftNavToOffCanvas = function () {
            if (defaultMain && !options.altmenu) {
                return;
            }
            var offCanvasCloneSuffix = "-offCanvas";
            if (options.altmenu) {
                each(DOC.getElementById(options.altmenu).children, function (li) {
                    offCanvas.leftMenu.querySelector("ul").appendChild(li.cloneNode(true));
                });
                DOC.getElementById(options.altmenu).parentElement.removeChild(DOC.getElementById(options.altmenu));
            } else {
                each(element.querySelectorAll("." + prefix + "navLeft-list>li"), function (li) {
                    offCanvas.leftMenu.querySelector("ul").appendChild(li.cloneNode(true));
                });
            }
            each(offCanvas.leftMenu.querySelectorAll("." + prefix + "accordion"), function(lnAccordion) {
                lnAccordion.id = lnAccordion.id + offCanvasCloneSuffix;
                lnAccordion.querySelector("." + prefix + "accordion-btn").setAttribute("data-parent", 
                    lnAccordion.querySelector("." + prefix + "accordion-btn").getAttribute("data-parent").substr(1) + offCanvasCloneSuffix);
            });
            each(offCanvas.leftMenu.querySelectorAll("." + prefix + "accordion-card-header"), function(lnCardHeader) {
                lnCardHeader.id = lnCardHeader.id + offCanvasCloneSuffix;
                lnCardHeader.nextElementSibling.setAttribute("aria-labelledby", 
                    lnCardHeader.nextElementSibling.getAttribute("aria-labelledby") + offCanvasCloneSuffix);
            });
            each(offCanvas.leftMenu.querySelectorAll("." + prefix + "collapse"), function(lnCollapse) {
                lnCollapse.id = lnCollapse.id + offCanvasCloneSuffix;
                if (lnCollapse.previousElementSibling.querySelector("." + prefix + "accordion-btn")) {
                    lnCollapse.previousElementSibling.querySelector("." + prefix + "accordion-btn").setAttribute("data-target", 
                        lnCollapse.previousElementSibling.querySelector("." + prefix + "accordion-btn").getAttribute("data-target") + offCanvasCloneSuffix);
                    lnCollapse.previousElementSibling.querySelector("." + prefix + "accordion-btn").setAttribute("aria-controls", 
                        lnCollapse.previousElementSibling.querySelector("." + prefix + "accordion-btn").getAttribute("aria-controls") + offCanvasCloneSuffix);
                }
            });
            each(offCanvas.leftMenu.querySelectorAll("a"), function(lnAnchor) {
                if(lnAnchor.id) {
                    lnAnchor.id = lnAnchor.id + offCanvasCloneSuffix;
                }
            });
            
            // define focusable offcanvas elements
            focusableElms = getFocusableElements(DOC.querySelector("." + prefix + "msthd-top ." + prefix + "navLeft"));

            
            // reinit offcanvas collapses
            each(offCanvas.leftMenu.querySelectorAll("[data-toggle='" + prefix + "collapse']"), function (collapse) {
                new Collapse(collapse);
            });
        },
        createOffCanvasMenu = function () {
            if (defaultMain && !options.altmenu) {
                return;
            }
            // remove header menu
            offCanvas = {
                mastheadMend: masthead.querySelectorAll("." + prefix + "msthd-offcanvas-menu")[0],
                parent: undefined,
                storedId: undefined,
                leftMenu: element.querySelectorAll("." + prefix + "msthd-offcanvas-menu")[0]
            };
            if (offCanvas.mastheadMend) {
                offCanvas.parent = offCanvas.mastheadMend.parentElement;
                offCanvas.storedId = offCanvas.mastheadMend.id;
                offCanvas.parent.removeChild(offCanvas.mastheadMend);
            } else {
                throw new Error("Left Nav cannot initialize without a masthead component in the header element.");
            }

            // reassign ID to leftNav's offCanvas
            offCanvas.leftMenu.id = offCanvas.storedId;
            if (offCanvas.parent) {
                offCanvas.parent.appendChild(offCanvas.leftMenu);
            }
        },
        expandSelection = function () {
            if (!options.selected) {
                return;
            }
            each(parentAccordions, function(stepAccord) {
                var aButton = stepAccord.querySelector("." + prefix + "accordion-btn"),
                    aChevron = stepAccord.querySelector("." + prefix + "accordion-btn i"),
                    aCollapse = stepAccord.querySelector("." + prefix + "collapse");
                aButton.setAttribute("aria-expanded", true);
                classAdd(aChevron, prefix + "navLeft-icon-rotate");
                one(aButton, "click", function(event) {
                    each(getClosest(event.target, "." + prefix + "accordion", true).querySelectorAll("." + prefix + "accordion-btn i"), function(thisChevron) {
                        classRemove(thisChevron, prefix + "navLeft-icon-rotate");
                    });
                });
                aCollapse.setAttribute("aria-expanded", true);
                classAdd(aCollapse, prefix + "show");
            });

            setTimeout(function() {
                each(element.querySelectorAll("." + prefix + "navLeft-list"), function(list) {
                    var selectedLink = list.querySelector("." + prefix + "navLeft-link-selected");
                    if (selectedLink) {
                        selectedLink.scrollIntoView();
                    }
                });
            }, 500);
        },
        handleAdjustmentEvent = function () {
            adjustHeightForFooter();
            adjustHeightForMasthead();
        },
        handleBackClick = function () {
            var activeContainer = masthead.querySelector("." + prefix + "msthd-offcanvas-menu");
            var activeButton = masthead.querySelector("[data-toggle='" + prefix + "msthd-offcanvas']");
            uicoreCustomEvent("LeftNav", "CloseEvent", element, {"button": activeButton, "container": activeContainer});
        },
        handleOffCanvasKeyDown = function (e) {
            switch(e.keyCode) {
            case KEY_TAB:
                if ( focusableElms.length === 1 ) {
                    e.preventDefault();
                    break;
                }
                if ( e.shiftKey ) {
                    handleBackwardTab(e);
                } else {
                    handleForwardTab(e);
                }
                break;
            case KEY_ESC: {
                handleBackClick();
                break;   
            }
            default:
                break;
            }
        },
        handleBackwardTab = function (e) {
            if ( DOC.activeElement === focusableElms[0] ) {
                e.preventDefault();
                focusableElms[focusableElms.length-1].focus();
            }
        },
        handleForwardTab = function (e) {
            if ( DOC.activeElement === focusableElms[focusableElms.length-1] ) {
                e.preventDefault();
                focusableElms[0].focus();
            }
        },
        handleOffCanvasItemEsc = function (e) {
            switch(e.keyCode) {
            case KEY_ESC: {
                handleBackClick();
                break;   
            }
            default:
                break;
            }    
        },
        handleOffCanvasOpenEvent = function () {
            each(focusableElms, function(fElement) { // top level categories
                fElement.addEventListener("keydown", handleOffCanvasKeyDown, false);
            });
            // add eventListener to offCanvas menu choices, but not categories
            each(offCanvas.leftMenu.querySelectorAll("li a"), function(aItem) {
                aItem.addEventListener("keydown", handleOffCanvasItemEsc);
                aItem.addEventListener("click", handleBackClick);
            });
            each(offCanvas.leftMenu.querySelectorAll("li button"), function(aItem) {
                aItem.addEventListener("keydown", handleOffCanvasItemEsc);
            });
            if (DOC.body.classList.contains("user-is-tabbing")) {
                setTimeout(function() {
                    setFocus(focusableElms[0]);
                }, 200);
            }
        },
        handleOffCanvasCloseEvent = function () {
            each(focusableElms, function(fElement) {
                fElement.removeEventListener("keydown", handleOffCanvasKeyDown, false);
            });
            each(offCanvas.leftMenu.querySelectorAll("li a"), function(aItem) {
                aItem.removeEventListener("keydown", handleOffCanvasItemEsc);
                aItem.removeEventListener("click", handleBackClick);
            });
            each(offCanvas.leftMenu.querySelectorAll("li button"), function(aItem) {
                aItem.removeEventListener("keydown", handleOffCanvasItemEsc);
            });
            if (DOC.body.classList.contains("user-is-tabbing")) {
                setTimeout(function() {
                    setFocus(masthead.querySelector("[data-toggle='" + prefix + "msthd-offcanvas']"));
                }, 200);
            }
            ariaAnnounce(
                masthead.querySelector("[data-toggle='" + prefix + "msthd-offcanvas']").getAttribute("aria-label") + " " + 
                masthead.querySelector("[data-toggle='" + prefix + "msthd-offcanvas']").getAttribute("aria-expanded")
            );
        },
        handleToggleClick = function () { // active in this case means the site body is fully expanded, or that the leftnav has been altered from its initial state (thus, collapsed)
            if (element.classList["contains"](prefix + "active")) {
                classRemove(element, prefix + "active");
                classRemove(siteBody, prefix + "active");
                classRemove(buttonToggle.querySelector("svg"), prefix + "rotate-180");
                buttonToggle.setAttribute("aria-expanded", "true");
                element.querySelector("#navLeft-list").style.display = "";
            } else {
                classAdd(element, prefix + "active");
                classAdd(siteBody, prefix + "active");
                classAdd(buttonToggle.querySelector("svg"), prefix + "rotate-180");
                buttonToggle.setAttribute("aria-expanded", "false");
                element.querySelector("#navLeft-list").style.display = "none";
            }
            setTimeout(function () {
                adjustHeightForFooter();
                adjustHeightForMasthead();
            }, 200);
        },
        highlightSelection = function () {
            if (!options.selected) {
                return;
            }
            var anchortags = DOC.querySelectorAll("a[href*='" + options.selected + "']");
            each(anchortags, function (tag) {
                var ps = tag.parentElement.previousElementSibling;
                if (ps && ps.tagName.toLowerCase() === "li") {
                    classAdd(ps, prefix + "navLeft-link-selectedPrev");
                }
                classAdd(tag.parentElement, prefix + "navLeft-link-selected");
                // take note of parent accordions
                var parentAccordion = getClosest(tag, "." + prefix + "accordion", true);
                do {
                    parentAccordions.push(parentAccordion);
                    parentAccordion = getClosest(parentAccordion.parentElement, "." + prefix + "accordion", true);
                } while (parentAccordion);
                parentAccordions = parentAccordions.reverse();
            });
        },
        isFooterVisible = function () {
            return footer ? DOC.body.offsetHeight >= footer.getBoundingClientRect().top : false;
        };

    // init
    if (!(stringNavLeft in element)) { // prevents adding event handlers twice
        var pageReadyCount = 0,
            pageReadyInterval = 50,
            maxPageReadyTry = 5000;
        // assign global variables
        desktopLeftNav = element.querySelector("." + prefix + "navLeft");
        siteBody = DOC.getElementById(options.main);
        buttonToggle = element.querySelector("." + prefix + "navLeft-btn-toggle");

        // add event handlers
        window.addEventListener("scroll", handleAdjustmentEvent);
        window.addEventListener("resize", handleAdjustmentEvent);
        if (options.menu.length > 0 || options.altmenu.length > 0) { // otherwise let masthead continue to manage offcanvas
            DOC.addEventListener("uicMastheadOffCanvasOpen", handleOffCanvasOpenEvent, false);
            DOC.addEventListener("uicMastheadOffCanvasClose", handleOffCanvasCloseEvent, false);
        }
        buttonToggle.addEventListener("click", handleToggleClick);

        // adjust initial page elements
        if (!defaultMain) {
            if (DOC.getElementById(options.menu)) {
                DOC.getElementById(options.menu).hidden = true;
            } else {
                throw new Error("Element not found by ID ('" + options.menu + "') for left nav menu choices.");
            }
        }
        if (options.altmenu) {
            if (DOC.getElementById(options.altmenu)) {
                DOC.getElementById(options.altmenu).hidden = true;
            } else {
                throw new Error("Element not found by ID ('" + options.altmenu + "') for left nav (offcanvas) menu choices.");
            }
        }
        classAdd(siteBody, prefix + "body-ml-20");
        if (options.suffix) {
            suffix = DOC.getElementById(options.suffix);
            element.querySelector("nav").appendChild(suffix);
            classAdd(suffix, prefix + "navLeft-suffix");
        }

        // move user's leftNav options into our HTML structure
        if (!defaultMain) {
            each(DOC.getElementById("navLeft-list").querySelectorAll("li"), function(oneLi) {
                oneLi.parentElement.removeChild(oneLi);
            });
            var developerLinks = DOC.querySelectorAll("#" + options.menu + ">ul>li").length ? 
                DOC.querySelectorAll("#" + options.menu + ">ul>li") : 
                DOC.querySelectorAll("#" + options.menu + ">li");
            each(developerLinks, function(thisLi) {
                element.querySelector("." + prefix + "navLeft-list").appendChild(thisLi);
            });
            DOC.getElementById(options.menu).parentElement.removeChild(DOC.getElementById(options.menu));
        }

        // Remove masthead horizontal menu if it exists
        if (options.replace) {
            var headerHorizontalMenu = DOC.querySelector("." + prefix + "msthd-navbar-bottom");
            if (headerHorizontalMenu) {
                headerHorizontalMenu.parentElement.removeChild(headerHorizontalMenu);
            }
        }

        // align accordion chevrons
        alignChevrons(element.querySelectorAll("." + prefix + "accordion button"));
        if (options.altmenu) {
            alignChevrons(document.getElementById(options.altmenu).querySelectorAll("." + prefix + "accordion button"));
        }

        // assign global variables that have dependencies
        var pageReady = function() {
            footer = DOC.querySelector("footer");
            masthead = DOC.querySelector("header");
            footerHeight = footer.getBoundingClientRect().height;
            createOffCanvasMenu();
            cloneLeftNavToOffCanvas();
            // set initial heights of elements
            adjustHeightForFooter();
            adjustHeightForMasthead();
            element.style.top = mastheadHeight;
            if (!isIE) { // this is a bonus check for guaranteed initial display. IE doesn't Promise, so it's skip IE, or use a polyfill.
                Promise.all(Array.from(DOC.images).filter(function (img) { !img.complete; }).map(function (img) { new Promise(function (resolve) { { img.onload = img.onerror = resolve; } }); })).then(function () {
                    adjustHeightForFooter();
                    adjustHeightForMasthead();
                });
            }
            highlightSelection();
            expandSelection();
            ResizeElement();
        };

        // wait for header and footer. Will be instant unless page load time is abnormally lengthy
        var waitForLeftNav = function() {
            if (pageReadyCount < maxPageReadyTry) {
                if (DOC.querySelector("footer") && DOC.querySelector("header")) {
                    setTimeout(pageReady, pageReadyInterval);
                } else {
                    setTimeout(waitForLeftNav, pageReadyInterval);
                }
                pageReadyCount += pageReadyInterval;
            } else {
                throw new Error("Unable to initialize Left Nav component due to page load timeout.");
            }
        };

        waitForLeftNav();
    }


    element[stringNavLeft] = self;
}
