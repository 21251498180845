import { each, sortItems, isArray, createElement } from "../utilities.js";
/**
 * Columns API
 * @param {Object} instance DataTable instance
 * @param {Mixed} columns  Column index or array of column indexes
 */
export default function Columns(dt) {
    var hiddenHeader = false;
    /**
     * Swap two columns
     * @return {Void}
     */
    this.swap = function(columns) {
        if (columns.length && columns.length === 2) {
            var cols = [];

            // Get the current column indexes
            each(dt.headings, function (h, i) {
                cols.push(i);
            });

            var x = columns[0];
            var y = columns[1];
            var b = cols[y];
            cols[y] = cols[x];
            cols[x] = b;

            this.order(cols);
        }
    };

    /**
     * Reorder the columns
     * @return {Array} columns  Array of ordered column indexes
     */
    this.order = function(columns) {
            
        var a, b, c, d, h, s, cell,
            temp = [
                [],
                [],
                [],
                []
            ];

        // Order the headings
        each(columns, function (column, x) {
            h = dt.headings[column];
            s = h.getAttribute("data-sortable") !== "false";
            a = h.cloneNode(true);
            a.originalCellIndex = x;
            a.sortable = s;

            temp[0].push(a);

            if (dt.hiddenColumns.indexOf(column) < 0) {
                b = h.cloneNode(true);
                b.originalCellIndex = x;
                b.sortable = s;

                temp[1].push(b);
            }
        });

        // Order the row cells
        each(dt.data, function (row, i) {
            c = row.cloneNode();
            d = row.cloneNode();

            c.dataIndex = d.dataIndex = i;
            if(dt.expandable){
                c.details = d.details = row.details;
            }

            
            if (row.searchIndex !== null && row.searchIndex !== undefined) {
                c.searchIndex = d.searchIndex = row.searchIndex;
            }

            // Append the cell to the fragment in the correct order
            each(columns, function (column) {
                cell = row.cells[column].cloneNode(true);
                cell.data = row.cells[column].data;
                c.appendChild(cell);
                
                if (dt.hiddenColumns.indexOf(column) < 0) {
                    cell = row.cells[column].cloneNode(true);
                    cell.data = row.cells[column].data;
                    d.appendChild(cell);
                }
            });
            
            temp[2].push(c);
            temp[3].push(d);
        });

        dt.headings = temp[0];
        dt.activeHeadings = temp[1];

        each(dt.headings, function(header) {
            if (header.classList.contains("desc") || header.classList.contains("asc")) {
                dt.lastTh = header;
            }
        });

        dt.data = temp[2];
        dt.activeRows = temp[3];
    };

    /**
     * Hide columns
     * @return {Void}
     */
    this.hide = function(columns) {
        if (columns.length) {
            each(columns, function (column) {
                if (dt.hiddenColumns.indexOf(column) < 0) {
                    dt.hiddenColumns.push(column);
                }
            });

            this.rebuild();
        }
    };

    /**
     * Show columns
     * @return {Void}
     */
    this.show = function(columns) {
        if (columns.length) {
            var index;

            each(columns, function (column) {
                index = dt.hiddenColumns.indexOf(column);
                if (index > -1) {
                    dt.hiddenColumns.splice(index, 1);
                }
            });

            this.rebuild();
        }
    };

    /**
     * Check column(s) visibility
     * @return {Boolean}
     */
    this.visible = function(columns) {
        var cols;

        columns = columns || dt.headings.map(function (th) {
            return th.originalCellIndex;
        });

        if (!isNaN(columns)) {
            cols = dt.hiddenColumns.indexOf(columns) < 0;
        } else if (isArray(columns)) {
            cols = [];
            each(columns, function (column) {
                cols.push(dt.hiddenColumns.indexOf(column) < 0);
            });
        }

        return cols;
    };

    /**
     * Add a new column
     * @param {Object} data
     */
    this.add = function(data) {
        var that = this,
            td, th = createElement("th");

        if (!dt.headings.length) {
            dt.insert({
                headings: [data.heading],
                rows: data.rows.map(function (i) {
                    return [i];
                })
            });
            this.rebuild();
            return;
        }

        if (!hiddenHeader) {
            if (data.heading.nodeName) {
                th.appendChild(data.heading);
            } else {
                th.innerHTML = data.heading;
            }
        } else {
            th.innerHTML = "";
        }

        dt.headings.push(th);

        each(dt.data, function (row, i) {
            if (data.data[i]) {
                td = createElement("td");

                if (data.data[i].nodeName) {
                    td.appendChild(data.data[i]);
                } else {
                    td.innerHTML = data.data[i];
                }

                td.data = td.innerHTML;

                if (data.render) {
                    td.innerHTML = data.render.call(that, td.data, td, row);
                }

                row.appendChild(td);
            }
        });

        if (data.type) {
            th.setAttribute("data-type", data.type);
        }
        if (data.format) {
            th.setAttribute("data-format", data.format);
        }
        if (Object.prototype.hasOwnProperty.call(data, "sortable")) {
            th.sortable = data.sortable;
            th.setAttribute("data-sortable", data.sortable === true ? "true" : "false");
        }

        this.rebuild();

        dt.renderHeader();
    };

    /**
     * Remove column(s)
     * @param  {Array|Number} select
     * @return {Void}
     */
    this.remove = function(select) {
        if (isArray(select)) {
            // Remove in reverse otherwise the indexes will be incorrect
            select.sort(function (a, b) {
                return b - a;
            });

            each(select, function (column) {
                this.remove(column);
            }, this);
        } else {
            dt.headings.splice(select, 1);

            each(dt.data, function (row) {
                row.removeChild(row.cells[select]);
            });
        }

        this.rebuild();
    };

    /**
     * Sort by column
     * @param  {int} column - The column no.
     * @param  {string} direction - asc or desc
     * @return {void}
     */
    this.sort = function(column, direction) {
        // Check column is present
        if (dt.hasHeadings && (column < 0 || column > dt.activeHeadings.length - 1 )) {
            return false;
        }
        
        dt.sorting = true;
        
        var rows = [],
            alpha = [],
            numeric = [],
            a = 0,
            n = 0,
            th = dt.activeHeadings[column];

        rows = dt.data;
        column = th.originalCellIndex;
        
        each(rows, function (tr) {
            var num, content;
            
            var cell = tr.cells[column];
            content = cell.hasAttribute("data-content") ? cell.getAttribute("data-content") : cell.data;
            num = content.replace(/(\$|,|\s|%)/g, "");
            
            if (th.getAttribute("data-type") === "date") {                
                num = Date.parse(content);
                if (num.isNaN) {
                    num = 0;
                }
            }
            
            if (parseFloat(num) == num) {
                numeric[n++] = {
                    value: Number(num),
                    row: tr
                };
            } else {
                alpha[a++] = {
                    value: content.toLowerCase(),
                    row: tr
                };
            }

        });
        
        /* Sort according to direction (ascending or descending) */
        var top, btm;
        if (th.classList.contains("asc") || direction == "desc") {
            top = sortItems(alpha, -1);
            btm = sortItems(numeric, -1);
        } else {
            top = sortItems(numeric, 1);
            btm = sortItems(alpha, 1);

        }
        this.updateSortDirection(th, direction);
        
        dt.lastTh = th;
        
        /* Reorder the table */
        rows = top.concat(btm);
        dt.data = [];
        dt.selectedRows = [];
        var indexes = [];
            
        each(rows, function (v, i) {
            dt.data.push(v.row);

            if (v.row.searchIndex !== null && v.row.searchIndex !== undefined) {
                indexes.push(i);
            }

        }, dt);
        
        dt.searchData = indexes;

        this.rebuild();
    };

    this.updateSortDirection = function(th, direction){
        if (th.classList.contains("asc") || direction == "desc") {
            // top = sortItems(alpha, -1);
            // btm = sortItems(numeric, -1);
            th.classList.remove("asc");
            th.classList.add( "desc");
            th.setAttribute("aria-sort","descending");
        } else {
            // top = sortItems(numeric, 1);
            // btm = sortItems(alpha, 1);
            th.classList.remove("desc");
            th.classList.add("asc");
            th.setAttribute("aria-sort","ascending");
        }

        if (dt.lastTh && th != dt.lastTh) {
            dt.lastTh.classList.remove("desc");
            dt.lastTh.classList.remove("asc");
        }
    };

    /**
     * Rebuild the columns
     * @return {Void}
     */
    this.rebuild = function() {
        var a, b, c, d, temp = [];

        dt.activeRows = [];
        dt.activeHeadings = [];

        each(dt.headings, function (th, i) {
            th.originalCellIndex = i;
            th.sortable = th.getAttribute("data-sortable") !== "false";
            if (dt.hiddenColumns.indexOf(i) < 0) {
                dt.activeHeadings.push(th);
            
            } 
        });
        // Loop over the rows and reorder the cells
        each(dt.data, function (row, i) {
            a = row.cloneNode();
            b = row.cloneNode();

            a.dataIndex = b.dataIndex = i;
            a.details = b.details =  row.details;

            if (row.searchIndex !== null && row.searchIndex !== undefined) {
                a.searchIndex = b.searchIndex = row.searchIndex;
            }

            // Append the cell to the fragment in the correct order
            each(row.cells, function (cell) {
                c = cell.cloneNode(true);
                c.data = cell.data;
                a.appendChild(c);

                if (dt.hiddenColumns.indexOf(cell.cellIndex) < 0) {
                    d = cell.cloneNode(true);
                    d.data = cell.data;
                    b.appendChild(d);
                
                }
            });

            // Append the fragment with the ordered cells
            temp.push(a);
            dt.activeRows.push(b);
        });
        
        dt.data = temp;
    };
}
