// parse the package.json file to get needed variables
import { ddsSpace, ddsToken } from "../../package.json";

export const DOC = document;
export const HTML = DOC.documentElement;
export const globalObject = typeof global !== "undefined" ? global : window;
export const prefix = ddsSpace + ddsToken;
export const supportTransitions =
    "Webkit" + "Transition" in HTML["style"] ||
    "Transition"["toLowerCase"]() in HTML["style"];
export const transitionEndEvent =
    "Webkit" + "Transition" in HTML["style"] ?
        "Webkit"["toLowerCase"]() + "Transition" + "End" :
        "Transition"["toLowerCase"]() + "end";
export const transitionDuration =
    "Webkit" + "Duration" in HTML["style"] ?
        "Webkit"["toLowerCase"]() + "Transition" + "Duration" :
        "Transition"["toLowerCase"]() + "Duration";
export const tipPositions = /\b(top|bottom|left|right)+/;
export const isIPhone = (/(IPhone)/i.test(navigator.platform));
export const isIOS = (/iPad|iPhone|iPod|Macintosh/i.test(navigator.userAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) && !window.MSStream; // /(/(IPad)/i.test(navigator.platform)) || (/(IPhone)/i.test(navigator.platform));
export const isIE = (/msie|trident|Windows Phone/i.test(navigator.userAgent));
export const isSafari = (/Version\/[\d.]+.*Safari/i.test(navigator.userAgent));
export const isEdge = (/Edge\/\d./i.test(navigator.userAgent));
export const isFirefox = (/firefox/i.test(navigator.userAgent));
export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
export const direction = {
    UP: 1,
    RIGHT: 2,
    DOWN: 3,
    LEFT: 4,
    NONE: 5
};

// selection methods
export function getFullScreenOverlay() {
    var overlay = DOC.getElementById(prefix + "full-screen-overlay");
    if (!overlay) {
        console.warn("OFF-CANVAS: Overlay requested. Corresponding HTML missing. Please apply id 'dds__full-screen-overlay' and class 'dds__overlay' to an empty div");
        return false;
    }
    return overlay;
}


export function getElementsByClassName(element, classNAME) {
    // returns Array
    return [].slice.call(element["getElementsByClassName"](classNAME));
}

export function getSibling(element, selector) {

    var firstChar = selector.charAt(0);
    var selectorSubstring = selector.substr(1);

    if (firstChar === ".") {
        if (element.nextElementSibling && element.nextElementSibling.classList) {
            if(element.nextElementSibling.classList.contains(selectorSubstring)) {
                return element.nextElementSibling;
            } else {
                return getSibling(element.nextElementSibling, selector);
            }
        }
    }  else if (firstChar === "#") {
        
        if (element.nextElementSibling && element.nextElementSibling.id) {
            if(element.nextElementSibling.id === selectorSubstring) {
                return element.nextElementSibling;
            } else {
                return getSibling(element.nextElementSibling, selector);
            }
        }
    } else {
        if (element.nextElementSibling && element.nextElementSibling.tagName) {
            if(element.nextElementSibling.tagName.toLowerCase() === selector.toLowerCase()) {
                return element.nextElementSibling;
            } else {
                return getSibling(element.nextElementSibling, selector);
            }
        }
    }
}

export function getFocusableElements(element) {
    var arr = Array.prototype.slice.call(element.querySelectorAll("*:not([aria-expanded='false']) a[href],*:not([aria-expanded='false']) area[href],*:not([aria-expanded='false']) input:not([disabled]),*:not([aria-expanded='false']) select:not([disabled]),*:not([aria-expanded='false']) textarea:not([disabled]),*:not([aria-expanded='false']) button:not([disabled]):not(.dds__d-none), *:not([aria-expanded='false']) > .dds__accordion-card-body > ul > [tabindex='0']"));
    return arr.filter(filterCollapsedParents);
}

function filterCollapsedParents(element) {
    for(;element && element !== DOC; element = element.parentElement) {
        if (element.parentElement) {
            if ((element.parentElement.classList.contains("dds__collapse") && element.parentElement.getAttribute("aria-expanded") == "false") || element.parentElement.classList.contains(prefix + "d-none")) {
                return false;
            } 
        } else {
            return element; //if scaled entire DOM tree and no parents are collapsed, element is focusable
        }
    }
}

export function getNextFocusableElement(element) {
    for(;element && element !== DOC; element = element.nextElementSibling) {
        if (element.nextElementSibling) {
            if (window.getComputedStyle(element.nextElementSibling).getPropertyValue("display") != "none") {
                if (["A","AREA","INPUT","SELECT","TEXTAREA","BUTTON"].indexOf(element.nextElementSibling.tagName) != -1
                && element.nextElementSibling.tabIndex >= 0) {
                    return element.nextElementSibling;
                } else {
                    var focusable = getFocusableElements(element.nextElementSibling);
                    if (focusable && focusable.length > 0) {
                        return focusable[0];
                    }
                }
            }
        } else {
            return getNextFocusableElement(element.parentElement);
        }
    }
}

/**
 * Creates a temporary, assertive element to announce a message for text reader clients, then removes that element.
 * @param  {String}   theMessage The short text to announce. Consider translation requirements.
 * @return void
 */
export function ariaAnnounce (theMessage) {
    var msgEl,
        randomNum = Math.floor(Math.random() * 999999999) + 1;
    document.body.appendChild(createElement("div", {
        style: "position:absolute; left:-1000px; width: 0px; height: 0px;",
        id: "ariaAnnouncement" + randomNum,
        "aria-live": "assertive"
    }));
    msgEl = document.getElementById("ariaAnnouncement" + randomNum);
    window.setTimeout(function() {
        msgEl.textContent = theMessage;
    }, 15);
    window.setTimeout(function () {
        msgEl.parentElement.removeChild(msgEl);
    }, 5000);
}


/**
 * @method getClosest   Crawls the DOM to find the closest targeted element
 * @param element       {DOM element}   the DOM element from which to start searching
 * @param selector      {string}        the class, ID, or tag name for which to search
 * @param parentsOnly   {boolean}       if false or not set, modifies TAG search to include children elements of the currently-inspected target
 * @return {element} or false
 */
export function getClosest(element, selector, parentsOnly) {
    // source http://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/
    var firstChar = selector.charAt(0);

    var selectorSubstring = selector.substr(1);
    if (firstChar === ".") {
        // If selector is a class
        for (; element && element !== DOC; element = element.parentNode) {
            // Get closest match
            if (
                element["parentNode"].querySelector(selector) !== null &&
                // hasClass(element, selectorSubstring)) {
                element.classList["contains"](selectorSubstring)
            ) {
                return element;
            } else if (parentsOnly === false) {
                if (element.querySelector(selector)) {
                    return element.querySelector(selector);
                }
            }
        }
    } else if (firstChar === "#") {
        // If selector is an ID
        for (; element && element !== DOC; element = element.parentNode) {
            // Get closest match
            if (element.id === selectorSubstring) {
                return element;
            } else if (parentsOnly === false) {
                if (element.querySelector(selector)) {
                    return element.querySelector(selector);
                }
            }
        }
    } else {
        // If selector is a tagName
        for (; element && element !== DOC; element = element.parentNode) {
            // Get closest match
            if (element.tagName && element.tagName.toLowerCase() === selector.toLowerCase() ) {
                return element;
            } else if (element.previousElementSibling && element.previousElementSibling.tagName.toLowerCase() === selector.toLowerCase()) {
                return element.previousElementSibling;
            } else if (parentsOnly === false) {
                if (element.querySelector(selector)) {
                    return element.querySelector(selector);
                }
            }
        }
    }
    return false;

}

export function one(element, event, handler) {
    element.addEventListener(
        event,
        function handlerWrapper(e) {
            handler(e);
            element.removeEventListener(event, handlerWrapper, false);
        },
        false
    );
}

export function getTransitionDurationFromElement(element) {
    var duration = supportTransitions ?
        globalObject["getComputedStyle"](element)[transitionDuration] :
        0;
    duration = parseFloat(duration);
    duration =
        typeof duration === "number" && !isNaN(duration) ? duration * 1000 : 0;
    return duration + 50; // we take a short offset to make sure we fire on the next frame after animation
}

export function getAnimationDurationFromElement(element) {
    var duration = supportTransitions ?
        globalObject["getComputedStyle"](element)["animationDuration"] :
        0;
    duration = parseFloat(duration);
    duration =
        typeof duration === "number" && !isNaN(duration) ? duration * 1000 : 0;
    return duration + 50; // we take a short offset to make sure we fire on the next frame after animation
}

export function emulateTransitionEnd(element, handler) {
    // emulateTransitionEnd since 2.0.4
    var called = 0;

    var duration = getTransitionDurationFromElement(element);
    supportTransitions &&
        one(element, transitionEndEvent, function (e) {
            handler(e);
            called = 1;
        });
    setTimeout(function () {
        !called && handler();
    }, duration);
}

export function emulateAnimationnEnd(element, handler) {
    // emulateTransitionEnd since 2.0.4
    var called = 0;

    var duration = getAnimationDurationFromElement(element);
    supportTransitions &&
        one(element, transitionEndEvent, function (e) {
            handler(e);
            called = 1;
        });
    setTimeout(function () {
        !called && handler();
    }, duration);
}

export function uicoreCustomEvent(componentName, eventName, related, details) {
    var evt;
    if (isIE){
        evt = document.createEvent("CustomEvent");
        evt.initCustomEvent( "uic" + componentName + eventName, true, true, details );
    }
    else{
        evt = new CustomEvent("uic" + componentName + eventName, {
            bubbles: true,
            detail: details
        });
    }
    evt.relatedTarget = related;
    related.dispatchEvent(evt);
}


// tooltip / popover stuff
export function getScroll() {
    // also Affix and ScrollSpy uses it
    return {
        y: globalObject.pageYOffset || HTML["scrollTop"],
        x: globalObject.pageXOffset || HTML["scrollLeft"]
    };
}

// set new focus element since 2.0.3
export function setFocus(element) {
    element.focus ? element.focus() : element.setActive();
}

export function offWindowLeft(element, link) {
    var elementDimensions = {
        w: element["offsetWidth"],
        h: element["offsetHeight"]
    };
    var rect = link["getBoundingClientRect"]();
    if (elementDimensions.w < 50) {
        elementDimensions.w = 50;
    }
    return rect["left"] - elementDimensions.w < 0;
}

export function styleTip(link, element, position, parent, useArrow) {
    // both popovers and tooltips (target,tooltip,placement,elementToAppendTo)

    // element.style.height = elementDimensions.h + 'px';
    // element.style.width = elementDimensions.w + 'px';
    element.style.maxWidth = "125px";

    useArrow = typeof useArrow === "boolean" ? useArrow : true;

    var windowWidth = HTML["clientWidth"] || DOC["body"]["clientWidth"];

    var windowHeight = HTML["clientHeight"] || DOC["body"]["clientHeight"];

    var rect = link["getBoundingClientRect"]();

    var scroll =
        parent === DOC["body"] ?
            getScroll() :
            {
                y: parent["offsetTop"] + parent["scrollTop"],
                x: parent["offsetLeft"] + parent["scrollLeft"]
            };

    var linkDimensions = {
        w: link.offsetWidth,
        h: link.offsetHeight
    };

    var maxWidth = 125;
    while(element["offsetHeight"] >= maxWidth) {
        maxWidth = maxWidth * 1.5;
        element.style.maxWidth = maxWidth + "px";
    }

    var elementDimensions = {
        w: element["offsetWidth"],
        h: element["offsetHeight"]
    };

    var isPopover = element.classList["contains"]("dds__popover");

    var topPosition;

    var leftPosition;

    var arrow = element.querySelector(".dds__arrow");

    var arrowTop;

    var arrowLeft;

    var arrowWidth;

    var arrowHeight;

    var halfTopExceed =
        rect["top"] + linkDimensions.h / 2 - elementDimensions.h / 2 < 0;

    var halfLeftExceed =
        rect["left"] + linkDimensions.w / 2 - elementDimensions.w / 2 < 0;

    var halfRightExceed =
        rect["left"] + elementDimensions.w / 2 + linkDimensions.w / 2 >= windowWidth;

    var halfBottomExceed =
        rect["top"] + elementDimensions.h / 2 + linkDimensions.h / 2 >= windowHeight;

    var topExceed = rect["top"] - elementDimensions.h < 0;

    var leftExceed = rect["left"] - elementDimensions.w < 0;

    var bottomExceed =
        rect["top"] + elementDimensions.h + linkDimensions.h >= windowHeight;

    var rightExceed =
        rect["left"] + elementDimensions.w + linkDimensions.w >= windowWidth;

    // recompute position
    var posChanged;
    if (position === "right"){
        if (!rightExceed && !halfTopExceed && !halfBottomExceed) {
            position === "right";
        } else if (rightExceed && !leftExceed && !halfTopExceed && !halfBottomExceed) { //If exceeds right, but fits on the left
            posChanged = true;
            position = "left";
        } else if ((!halfTopExceed || !halfBottomExceed) && (!halfRightExceed || !halfLeftExceed)) { //If half of top or bottom doesnt fit, try to fit top or bottom
            if (!topExceed) {
                posChanged = true;
                position = "top";
            } else if (!bottomExceed) {
                posChanged = true;
                position = "bottom"; 
            }
        }
    }
    else if (position === "left") {
        if (!leftExceed && !halfTopExceed && !halfBottomExceed) {
            position === "left";
        } else if (leftExceed && !rightExceed && !halfTopExceed && !halfBottomExceed) {
            posChanged = true;
            position = "right";
        } else if ((!halfTopExceed || !halfBottomExceed) && (!halfRightExceed || !halfLeftExceed)) {
            if (!topExceed) {
                posChanged = true;
                position = "top";
            } else if (!bottomExceed) {
                posChanged = true;
                position = "bottom"; 
            }
        }
    } else if (position === "top") {
        if (!topExceed && !halfRightExceed && !halfLeftExceed) {
            position === "top";
        }else if (topExceed && !bottomExceed && !halfRightExceed && !halfLeftExceed) {
            posChanged = true;
            position = "bottom";
        } else if ((!halfRightExceed || !halfLeftExceed) && (!halfTopExceed || !halfBottomExceed)) {  //If half of right or left doesnt fit, try to fit right or left
            if (!rightExceed) {
                posChanged = true;
                position = "right";
            } else if (!leftExceed) {
                posChanged = true;
                position = "left"; 
            }
        }
    }  else if (position === "bottom") {
        if (!bottomExceed && !halfRightExceed && !halfLeftExceed) {
            position === "bottom";
        } else if (bottomExceed && !topExceed && !halfRightExceed && !halfLeftExceed) {
            posChanged = true;
            position = "top";
        } else if ((!halfRightExceed || !halfLeftExceed) && (!halfTopExceed || !halfBottomExceed)) { //Can't be top
            if (!rightExceed) {
                posChanged = true;
                position = "right";
            } else if (!leftExceed) {
                posChanged = true;
                position = "left"; 
            }
        }
    }

    // update tooltip/popover class
    element.className["indexOf"](position) === -1 &&
        (element.className = element.className.replace(tipPositions, position));

    // update tooltip/popover dimensions
    if (posChanged) {
        elementDimensions = {
            w: element["offsetWidth"],
            h: element["offsetHeight"]
        };
    }
    // we check the computed width & height and update here
    var elMargin;
    try {
        elMargin = Number(window.getComputedStyle(element).margin.match(/\d+/)[0]); // get the margin value from style as a number
    } catch (e) {
        elMargin = 0;
    }
    arrowWidth = arrow["offsetWidth"] + (elMargin * 2);
    arrowHeight = arrow["offsetHeight"] + (elMargin * 2);

    var rectLeft = rect["left"], rectTop = rect["top"];
    if (!useArrow) {
        if (position === "left") {
            rectLeft = rect["left"] + arrowWidth;
        } else if( position === "right") {
            rectLeft = rect["left"] - arrowWidth;
        } else if (position === "top") {
            rectTop = rect["top"] + arrowHeight;
        } else if (position === "bottom") {
            rectTop = rect["top"] - arrowHeight;
        }
    }

    // apply styling to tooltip or popover
    if (position === "left" || position === "right") {
        // secondary|side positions
        if (position === "left") {
            // LEFT
            leftPosition =
                rectLeft +
                scroll.x -
                elementDimensions.w -
                (isPopover ? arrowWidth : 3); // subtract to move more left
            window.addEventListener("resize", function () {
                arrow["style"]["left"] = "initial";
            });
        } else {
            // RIGHT
            leftPosition = rectLeft + linkDimensions.w + (isPopover ? 0 : 3); // add to move more right
        }

        topPosition =
            rect["top"] - elementDimensions.h / 2 + linkDimensions.h / 2 + scroll.y;
        arrowTop =
            elementDimensions.h / 2 - 
            (isPopover ? arrowHeight * 0.9 : arrowHeight / 2);
    } else if (position === "top" || position === "bottom") {
        // primary|vertical positions
        if (position === "top") {
            // TOP
            topPosition =
                rectTop +
                scroll.y -
                elementDimensions.h - 
                (isPopover ? arrowHeight : 3); // subtract to move more up
        } else {
            // BOTTOM
            topPosition = rectTop + linkDimensions.h + scroll.y + (isPopover ? 0 : 3);
        }
        leftPosition =
            rect["left"] - elementDimensions.w / 2 + linkDimensions.w / 2 + scroll.x;
        arrowLeft = elementDimensions.w / 2 - arrowWidth / 2;
    }

    // apply style to tooltip/popover and its arrow
    element["style"]["top"] = topPosition + "px";
    element["style"]["left"] = leftPosition + "px";

    arrowTop && (arrow["style"]["top"] = arrowTop + "px");
    arrowLeft && (arrow["style"]["left"] = arrowLeft + "px");

}

export function handleFirstTab(e) {
    if (e.keyCode === 9) {
        document.body.classList.add("user-is-tabbing");

        window.removeEventListener("keydown", handleFirstTab);
        window.addEventListener("mousedown", handleMouseDownOnce);
    }
}

export function handleMouseDownOnce(e) {
    //NVDA in browse mode swallows the enter key as mouse click: https://github.com/nvaccess/nvda/issues/4903
    //This breaks our visual focus toggle. Enter key event has x,y values of 0,0 whereas click has positive x,y values.
    //Capture enter key when treated as mousedown by NVDA browse mode.
    if (e.x === 0 && e.y === 0) {
        return;
    }
    document.body.classList.remove("user-is-tabbing");

    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
}

// Form--passwords validation
//================================================================
export function passwordVerification() {
    var myInput = document.getElementById("psw");
    var letter = document.getElementById("letter");
    var capital = document.getElementById("capital");
    var number = document.getElementById("number");
    var length = document.getElementById("length");

    // When the user clicks on the password field, show the message box
    myInput.onfocus = function () {
        document.getElementById("message").style.display = "block";
    };

    // When the user clicks outside of the password field, hide the message box
    myInput.onblur = function () {
        document.getElementById("message").style.display = "none";
    };

    // When the user starts to type something inside the password field
    myInput.onkeyup = function () {
        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g;
        if (myInput.value.match(lowerCaseLetters)) {
            letter.classList.add("valid");
            letter.classList.remove("invalid");
        } else {
            letter.classList.remove("valid");
            letter.classList.add("invalid");
        }

        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if (myInput.value.match(upperCaseLetters)) {
            capital.classList.remove("invalid");
            capital.classList.add("valid");
        } else {
            capital.classList.remove("valid");
            capital.classList.add("invalid");
        }

        // Validate numbers
        var numbers = /[0-9]/g;
        if (myInput.value.match(numbers)) {
            number.classList.remove("invalid");
            number.classList.add("valid");
        } else {
            number.classList.remove("valid");
            number.classList.add("invalid");
        }

        // Validate length
        if (myInput.value.length >= 8) {
            length.classList.remove("invalid");
            length.classList.add("valid");
        } else {
            length.classList.remove("valid");
            length.classList.add("invalid");
        }
    };
}

/**
 * Check is item is object
 * @return {Boolean}
 */
export function isObject(val) {
    return Object.prototype.toString.call(val) === "[object Object]";
}

/**
 * Check is item is array
 * @return {Boolean}
 */
export function isArray(val) {
    return Array.isArray(val);
}

/**
 * Check for valid JSON string
 * @param  {String}   str
 * @return {Boolean|Array|Object}
 */
export function isJson(str) {
    var t = !1;
    try {
        t = JSON.parse(str);
    } catch (e) {
        return !1;
    }
    return !(null === t || (!isArray(t) && !isObject(t))) && t;
}

/**
 * Iterator helper
 * @param  {(Array|Object)}   arr     Any object, array or array-like collection.
 * @param  {Function}         fn      Callback
 * @param  {Object}           scope   Change the value of this
 * @return {Void}
 */
export function each(arr, fn, scope) {
    var n;
    if (arr) {
        if (isObject(arr)) {
            for (n in arr) {
                if (Object.prototype.hasOwnProperty.call(arr, n)) {
                    fn.call(scope, arr[n], n);
                }
            }
        } else if (arr.length > 0) {
            for (n = 0; n < arr.length; n++) {
                fn.call(scope, arr[n], n);
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

/**
 * Bubble sort algorithm
 */
export function sortItems(a, b) {
    var c, d;
    if (1 === b) {
        c = 0;
        d = a.length;
    } else {
        if (b === -1) {
            c = a.length - 1;
            d = -1;
        }
    }
    for (var e = !0; e;) {
        e = !1;
        for (var f = c; f != d; f += b) {
            if (a[f + b] && a[f].value > a[f + b].value) {
                var g = a[f],
                    h = a[f + b],
                    i = g;
                a[f] = h;
                a[f + b] = i;
                e = !0;
            }
        }
    }
    return a;
}

/**
 * Create DOM element node
 * @param  {String}   a nodeName
 * @param  {Object}   b properties and attributes
 * @return {Object}
 */
export function createElement(a, b) {
    var d = DOC.createElement(a);
    if (b && "object" == typeof b) {
        var e;
        for (e in b) {
            if ("html" === e) {
                d.innerHTML = b[e];
            } else {
                if (e.slice(0,5) === "aria_" || e.slice(0,5) === "data_") {
                    var attr = e.slice(0,4) + "-" + e.slice(5);
                    d.setAttribute(attr, b[e]); 
                } else {
                    d.setAttribute(e, b[e]);
                }
            }
        }
    }
    return d;
}

export function loadURLSVGs(paths, lazyload) {
    var lazyloading = typeof(lazyload) === "boolean" ? lazyload : true,
        execute = function(paths) {
            if (paths.length > 0 && !Array.isArray(paths)) {
                paths = new Array(paths);
            }
            if (!paths || paths.length < 1) {
                console.error("The File path(s) supplied were either empty or null.");
                return false;
            }
            var defs,
                style,
                svg,
                handleSVGResponse = function(response) {
                    if (response.match(/<script|javascript:/g)) {
                        throw Error("Possible malicous scripting code found!\n"+response);
                    } else {
                        var frag = DOC.createRange().createContextualFragment(response);
                        if (DOC["body"].firstChild.tagName != "svg") {
                            DOC["body"].insertBefore(frag, DOC["body"].firstChild);
                        } else {
                            svg = DOC["body"].firstChild;
                            defs = defs ? defs : svg.querySelector("defs");
                            style = style ? style : svg.querySelector("style");
                            var fragSymbol = frag.querySelector("symbol");
                            if (!defs.querySelector("#"+fragSymbol.id)) {
                                defs.appendChild(fragSymbol);
                                var fragStyle = frag.querySelector("style");
                                if (style && fragStyle) {
                                    style.innerHTML += fragStyle.innerHTML;
                                } else if (fragStyle) {
                                    svg.insertBefore(fragStyle, defs);
                                }
                            }
                        }
                    }
                };
            Array.prototype.forEach.call(paths, function(url) {
                var xhr = new XMLHttpRequest();
                xhr.addEventListener("error", function() {
                    console.error("The File path supplied [ "+paths+" ] was invalid.");
                });
                xhr.addEventListener("load", function() {
                    if (xhr.readyState == 4 && xhr.status == 200) {
                        handleSVGResponse(xhr.responseText);
                    }
                });
                xhr.open("GET", url, true);
                xhr.send();
            });
        };
    if (lazyloading) {
        DOC.addEventListener("DOMContentLoaded", function () {
            execute(paths);
        });
    } else {
        execute(paths);
    }
}

/**
 * Function created to support the ability to add classes
 * to an SVG Element / Node. The classList for Elements and
 * Nodes in IE using createElementNS does not support classList  
 * so the class attribute needs to be used
 * @param {Element/Node} elem 
 */
export function classAdd(elem, classes) {
    var classList;
    if (!isArray(classes)) {
        classList = [classes];
    } else {
        classList = classes;
    }
 
    each(classList, function(clazz) {
        if (!elem.classList) { // Element does not support classList
            var newClasses = elem.getAttribute("class");
            if (!newClasses) {
                elem.setAttribute("class", clazz);
            } else {
                if (newClasses.indexOf(clazz) == -1)
                    elem.setAttribute("class", newClasses + " " + clazz);
            }
        } else {
            elem.classList.add(clazz);
        }
    });
}

/**
 * Function created to support the ability to remove classes
 * to an SVG Element / Node. The classList for Elements and
 * Nodes in IE using createElementNS does not support classList 
 * so the class attribute needs to be used
 * @param {*} elem 
 */
export function classRemove(elem, classes) {
    var classList;
    if (!isArray(classes)) {
        classList = [classes];
    } else {
        classList = classes;
    }
    each(classList, function(clazz) {
        if (!elem.classList) { // Element does not support classList
            var newClasses = elem.getAttribute("class").replace(clazz, "");
            elem.setAttribute("class", newClasses);
        } else {
            elem.classList.remove(clazz);
        }
    }); 
}

export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function ieMosaicPolyfill(slides) {
    var divTag;
    for (var i = 0; i < slides.length; i++) {
        var imgTag = slides[i].querySelector("img.dds__card-img-top");
        if (imgTag) {
            divTag = slides[i].querySelector("div.dds__card-img-top");
            divTag.style.backgroundImage = "url('" + imgTag.src + "')";
            imgTag.style.display = "none";
            slides[i].parentNode.classList.add("dds__background-img");
        }
    }
}

export function altFind(arr, callback) {
    var ret;
    each(arr, function(val) {
        var match = callback(val);
        if (match) {
            ret = val;
        }
    });
    return ret;
}
  
export function validateNum(num, defaultVal) {
    if (num) {
        // check to see if page is a not a number but can be a string
        if ( isNaN(num) && typeof num != "string") {
            return defaultVal ? defaultVal : false;
        // if it is a string can it be converted to a number
        } else if (typeof num === "string") {
            if(isNaN(parseInt(num, 10))) {
                return defaultVal ? defaultVal : false;
            }
            num = parseInt(num, 10);
        }
        
        return num;
    }
    else{
        return defaultVal;
    }
}

export function jsonOptionsInit(element, options) {
    var jsonParams = element.dataset.options;
    if (jsonParams) {
        var jsonOptions = JSON.parse(jsonParams);
        return jsonOptions;
    }
    else{
        return options;
    }
    
}

export function swipeInit(el, callback) {
    var swipe = new Object();
    swipe.start = {
        x: 0,
        y: 0
    };
    swipe.end = {
        x: 0,
        y: 0
    };
    swipe.params = {
        min: {
            x: 50,
            y: 90
        },
        max: {
            x: 95,
            y: 100
        }
    };
    var getTouch = function (e) {
            if (isEdge || isIE) {
                return {
                    screenX: e.screenX,
                    screenY: e.screenY
                };
            } else {
                return e.touches[0];
            }
        },
        downListener = function (e) {
            var touch = getTouch(e);
            swipe.start.x = swipe.end.x = touch.screenX;
            swipe.start.y = swipe.end.y = touch.screenY;
        },
        moveListener = function (e) {
            // preventDefault if you want to completely stop default touch behavior
            // e.preventDefault();
            var touch = getTouch(e);
            swipe.end.x = touch.screenX;
            swipe.end.y = touch.screenY;
        },
        upListener = function () {
            if (
                ((swipe.end.x - swipe.params.min.x > swipe.start.x) || (swipe.end.x + swipe.params.min.x < swipe.start.x)) &&
                ((swipe.end.y < swipe.start.y + swipe.params.max.y) && (swipe.start.y > swipe.end.y - swipe.params.max.y))
            ) {
                if (swipe.end.x > swipe.start.x) {
                    swipe.direction = direction.RIGHT;
                } else {
                    swipe.direction = direction.LEFT;
                }
            }
            if (
                ((swipe.end.y - swipe.params.min.y > swipe.start.y) || (swipe.end.y + swipe.params.min.y < swipe.start.y)) &&
                ((swipe.end.x < swipe.start.x + swipe.params.max.x) && (swipe.start.x > swipe.end.x - swipe.params.max.x))
            ) {
                if (swipe.end.y > swipe.start.y) {
                    swipe.direction = direction.DOWN;
                } else {
                    swipe.direction = direction.UP;
                }
            }
            if (swipe.direction !== direction.NONE) {
                if (typeof callback === "function") {
                    callback(el, swipe.direction);
                }
            }
            swipe.direction = direction.NONE;
        };
    if (isEdge) {
        el.addEventListener("mousedown", downListener);
        el.addEventListener("mousemove", moveListener);
        el.addEventListener("mouseup", upListener);
    } else if (isIE) {
        el.addEventListener("pointerdown", downListener);
        el.addEventListener("pointermove", moveListener);
        el.addEventListener("pointerup", upListener);
    } else {
        el.addEventListener("touchstart", downListener);
        el.addEventListener("touchmove", moveListener);
        el.addEventListener("touchend", upListener);
    }
}
export function renderSvg(svg, attributes) {
    var svgWrapper = DOC.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgWrapper.setAttribute("focusable", false);
    classAdd(svgWrapper, prefix + "svg-icons");
    classAdd(svgWrapper, prefix + "icon-svg");
    if (!isArray(svg)) {
        svg = [svg];
    }
    each(svg, function(elm) {
        var svgElem = DOC.createElementNS("http://www.w3.org/2000/svg", "use");

        svgElem.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "#" + prefix + elm.name);
        classAdd(svgElem, [prefix + "svg-icons-item"]); //are these two classes the same
        classAdd(svgElem, prefix + "icon-svg-item");
        svgElem.setAttribute("tabindex", "-1"); //does this do anything
        if (elm.show) {
            classAdd(svgElem, [ prefix + "show"]);
        }

        svgWrapper.appendChild(svgElem);
    });

    for (var key in attributes) {
        if (Object.prototype.hasOwnProperty.call(attributes, key)) {
            svgWrapper.setAttribute(key, attributes[key]);
        }
    }
    return svgWrapper;
}

export function flush(el, ie) {
    if (el instanceof NodeList) {
        each(el, function (e) {
            flush(e, ie);
        });
    } else {
        if (ie) {
            while (el.hasChildNodes()) {
                el.removeChild(el.firstChild);
            }
        } else {
            el.innerHTML = "";
        }
    }
}

export function getText(value) {
    var text, div = DOC.createElement("div");
    div.innerHTML = value;

    function findText(div) {
        each(div.childNodes, function(cNode) {
            if (cNode.nodeType === 3) {
                text = cNode.data;
            }
            findText(cNode);
        }, this);
    }
    findText(div);
    return text;
}
