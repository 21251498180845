import { DOC, prefix, createElement, isArray, uicoreCustomEvent, isIE, each, validateNum, renderSvg, flush } from "./utilities.js";

export default function Pagination(element, options) {

    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    var jsonParams = element.dataset.page;
    if (jsonParams) {
        options = JSON.parse(jsonParams);
    }

    // set options
    options = options || {};
    options.items =  validateNum(options.items, 1);
    options.filter = element.classList.contains(prefix + "pagination-justified-filter") ? true : false;
    options.pageText = typeof options.pageText === "string" ? options.pageText : "Page";
    options.itemsPerPageText = typeof options.itemsPerPageText === "string" ? options.itemsPerPageText : "Items per page";
    options.perPageSelect = options.perPageSelect ? options.perPageSelect : [12, 24, 48, 96];
    if (isArray(options.perPageSelect)) {
        if (typeof options.perPageSelect[0] === "number") {
            options.perPageSelect = options.perPageSelect.map(function(i) { 
                i = Math.abs(parseInt(i));
                return i;
            });
        } else if (validateNum(options.perPageSelect[0])) {
            options.perPageSelect = options.perPageSelect.map(function(i) { 
                i = Math.abs(parseInt(i));
                return i;
            });
        } else {
            throw new Error("Invalid perPageSelect. Should be a non-empty array of integer");
        }
    } else {
        options.perPageSelect = [12,24,28,96];
    }
    options.perPage = options.perPageSelect.indexOf(options.perPage) > -1 ? validateNum(options.perPage) : options.perPageSelect[0];
    options.hidePages = options.hidePages && typeof options.hidePages === "boolean" ? options.hidePages : false;
    options.external = options.external != null && typeof options.external === "boolean" ? options.external : false;
    options.buttonLabelLeft = options.buttonLabelLeft ? options.buttonLabelLeft : "Previous";
    options.buttonLabelRight = options.buttonLabelRight ? options.buttonLabelRight : "Next";
    options.disablePaginationInput = options.disablePaginationInput != null && typeof options.disablePaginationInput === "boolean" ? options.disablePaginationInput : false;
    options.showTotal = options.showTotal && typeof options.showTotal === "string" ? options.showTotal : false;

    var self = this,
        stringPagination = "Pagination",
        template = "{total}{pager}{select}",
        pagination,
        prevText = options.buttonLabelLeft,
        nextText = options.buttonLabelRight,
        currentPage,
        origPerPage = options.perPage,
        previousPerPage,
        newPage,
        totalPages,
        newTotalPages,
        lastPage,
        onFirstPage,
        onLastPage,
        links,
        // handlers
        handlePaginationEvent = function(e) {
            var target;
            if (e.target.dataset["page"]) {
                target = e.target;
            } else {
                target = e.target.parentNode;
            }
            var name = target.getAttribute("aria-label");
            var focusBtn;

            var buttons = pagination.querySelectorAll("button");
            for (var idx = 0;idx < buttons.length;idx++) {
                if (target === buttons[idx]) {
                    focusBtn = buttons[idx];
                    break;
                } 
            }

            if (!options.external) {
                if (target.dataset["page"]) {
                    self.page(target.dataset["page"]);
                }
            } else {
                uicoreCustomEvent("Pagination", "PageChangeEvent", element, { "page": target.dataset["page"], "perPage" : options.perPage, "pages" : totalPages });
            }

            focusBtn = pagination.querySelector("[aria-label=\""+name+"\"]"),
            focusBtn.focus();

        },
        handleKeydownEvent = function(e) {
            if(e.keyCode == 13) {
                if(e.target.value <= totalPages && e.target.value > 0) {
                    if (!options.external) {
                        self.page(e.target.value);
                    } else {
                        uicoreCustomEvent("Pagination", "PageChangeEvent", element, { "page": e.target.value, "perPage" : options.perPage, "pages" : totalPages });
                    }
                } else {
                    e.target.value = currentPage;
                }
                var queryStr = e.target.tagName;
                each(e.target.classList, function(clazz) {
                    queryStr += "." + clazz;
                });
                pagination.querySelector(queryStr).focus();
            }
        },
        handleFocusoutEvent = function(e) {
            if(e.target.value != currentPage) {
                e.target.value = currentPage;
            } else {
                e.preventDefault();
            }
        },
        handleChangeEvent = function(e) {
            if (e.target.value != options.perPage) {
                previousPerPage = options.perPage;
                options.perPage = parseInt(e.target.value);
                if (!options.external) {
                    update();
                } else {
                    renderPage(); 
                    uicoreCustomEvent("Pagination", "PerPageChangeEvent", element, { "page": currentPage, "perPage" : options.perPage, "pages" : totalPages });
                }
                var queryStr = e.target.tagName;
                each(e.target.classList, function(clazz) {
                    queryStr += "." + clazz;
                });
                element.querySelector(queryStr).focus();
            }
        },
        // dynamic ui
        render = function() {
            if (options.showTotal) {
                var totalItems = createElement("div", {
                    class: prefix + "pagination-total-items"
                });
                var spanItems = createElement("span", {
                    html: options.items + " " + options.showTotal
                });
                totalItems.appendChild(spanItems);
                template = template.replace("{total}", totalItems.outerHTML);
            } else {
                template = template.replace("{total}", "");
            }

            var paginator = createElement("ul", {
                class: prefix + "pagination-list",
                role: "presentation"
            });
    
            // Pager placement
            template = template.replace("{pager}", paginator.outerHTML);

            // Per Page Select placement
            if (options.filter) {
                var wrap = "<div class='" + prefix + "perpage'></div>";
    
                // Selector placement
                template = template.replace("{select}", wrap);
            } else {
                template = template.replace("{select}", "");
            }

            element.innerHTML = template;

            pagination = element.querySelector("." + prefix + "pagination-list");

            if (options.items < options.perPage) {
                pagination.classList.add(prefix + "pagination-hidden");
            }

            update();
        },
        renderPage = function () {
            var origCurrentPage = currentPage;

            if (newPage) {
                currentPage = newPage;
                newPage = null;
            } else if (previousPerPage) {
                var firstItemOnPage = ( (origCurrentPage - 1) * previousPerPage) + 1;
                currentPage = Math.ceil(firstItemOnPage / options.perPage);
                previousPerPage = null;
            } else if (newTotalPages && currentPage > newTotalPages) {
                currentPage = newTotalPages;
            }

            onFirstPage = currentPage === 1;
            onLastPage = currentPage === lastPage;

            if (origCurrentPage != currentPage) {
                uicoreCustomEvent("Pagination", "PageUpdateEvent", element, { "page": currentPage, "perPage" : options.perPage, "pages" : totalPages });
            }
        },
        renderPageInput = function() {
            var pageInputWrapper = createElement("li", {
                class: prefix + "pagination-input-ctnr"
            });
            if (options.hidePages) {
                pageInputWrapper.classList.add(prefix + "d-none");
            }
            var pageInputTextBefore = createElement("label", {
                html: options.pageText
            });
            var pageInputField = createElement("input", {
                class: prefix + "form-control " + prefix + "text-center" ,
                type: "text",
                value: currentPage,
                "aria-label": "Page " + currentPage + " of " +  (newTotalPages ? newTotalPages : totalPages)
            });
            pageInputField.disabled = options.disablePaginationInput;
            var pageInputTextAfter = createElement("label", {
                html: "of " +  (newTotalPages ? newTotalPages : totalPages)
            });
            
            pageInputWrapper.appendChild(pageInputTextBefore);
            pageInputWrapper.appendChild(pageInputField);
            pageInputWrapper.appendChild(pageInputTextAfter);
            return pageInputWrapper;
        },
        renderPager = function () {
            var origTotalPages = totalPages,
                pages = newTotalPages ? newTotalPages : totalPages;

            flush(pagination, isIE);
    
            if (pages > 1) {
                var frag = DOC.createDocumentFragment(),
                    prev = onFirstPage ? 1 : currentPage - 1,
                    next = onLastPage ? pages : currentPage + 1;
    
                frag.appendChild(button("dds__btn dds__btn-secondary dds__page-item", prev, prevText));
    
                each(links, function(p) {
                    frag.appendChild(p);
                });
    
                frag.appendChild(button("dds__btn dds__btn-secondary dds__page-item", next, nextText));
            
                pagination.appendChild(frag.cloneNode(true));
                // add in click and keydown listeners
                each(pagination.querySelectorAll("li"), function(el) {
                    var hasInput = el.querySelector("input");
                    var hasButton = el.querySelector("button");
                    if(hasButton) {
                        el.addEventListener("click", handlePaginationEvent, true);
                    }
                    if(hasInput) {
                        hasInput.addEventListener("keydown", handleKeydownEvent, false);
                        hasInput.addEventListener("focusout", handleFocusoutEvent, false);
                    }
                });
            }

            if (pages != origTotalPages || newTotalPages) {
                totalPages = pages;
                newTotalPages = null;
                uicoreCustomEvent("Pagination", "PerPageUpdateEvent", element, { "page": currentPage, "perPage" : options.perPage, "pages" : totalPages });
            }
        },
        renderSelect = function() {
            var el = element.querySelector("." + prefix + "perpage");
            if (el) { flush(el, isIE); }
            var select = createElement("select",{
                class: prefix + "form-control"
            });

            // Create the options
            each(options.perPageSelect, function(val) {
                var option;
                if (val == options.perPage) {
                    option = new Option(val, val, true, true);
                } else {
                    option = new Option(val, val, false, false);
                }
                select.add(option);
            });

            var label = createElement("label", {
                html: options.itemsPerPageText
            });

            // Custom label
            el.innerHTML = "";
            el.appendChild(select);
            el.appendChild(label);

            select.addEventListener("change", handleChangeEvent, false);

        },
        renderTotalItems = function() {
            var totalItemsWrapper = element.querySelector("." + prefix + "pagination-total-items");
            flush(totalItemsWrapper, isIE);
            totalItemsWrapper.appendChild(createElement("span", {html: options.items + " " + options.showTotal}));
        },
        button = function (c, p, t) {
            var el = createElement("li");

            var bEl = createElement("button", {
                class: c + " " + prefix + "pager-link",

                "aria-label": t,
                "data-page": p
            });
            
            var span = createElement("span", {
                html: t
            });
            bEl.appendChild(span);
            var svgElem;
            if (t === prevText) {
                svgElem = renderSvg([{name:"chevron-left", show:true}]);
                svgElem.setAttribute("name","chevron-left");
                bEl.insertBefore(svgElem, span);
                if (onFirstPage) {
                    bEl.setAttribute("disabled","");
                }
            } else if (t === nextText) {
                svgElem = renderSvg([{name:"chevron-right", show:true}]);
                svgElem.setAttribute("name","chevron-right");
                bEl.appendChild(svgElem);
                if (onLastPage) {
                    bEl.setAttribute("disabled","");
                }
            }
            el.appendChild(bEl);

            return el;
        },
        paginate = function () {
            var pages;

            if (options.items < options.perPage) {
                pages = 1;
            } else {
                pages = Math.ceil( (options.items / options.perPage) );
            }
            if (!totalPages) {
                totalPages = lastPage = pages;
            } else {
                newTotalPages = lastPage = pages;
            }
        },
        update = function() {

            if (options.items < options.perPage) {
                pagination.classList.add(prefix + "pagination-hidden");
            } else if (pagination.classList.contains(prefix + "pagination-hidden")) {
                pagination.classList.remove(prefix + "pagination-hidden");
            }

            paginate(); 

            renderPage();
    
            links = [renderPageInput()];
    
            renderPager();

            if(options.filter) {
                renderSelect();
            }

            if (options.showTotal) {
                renderTotalItems();
            }
        };



    /**
     * Changes the page to the number passed in
     * @param  {int} num
     * @return {void}
     */
    this.page = function(num) {
        
        newPage = validateNum(num);

        if (!newPage
            || newPage == currentPage 
            || newPage > totalPages 
            || newPage < 0
        ) {
            return false;
        }

        update();
    };

    /**
     * Changes the value chosen in the per-page selection input
     * @param  {int} num
     * @return {void}
     */
    this.perPage = function(num) {
        var newPerPage = validateNum(num);

        if (!newPerPage) {
            return false;
        } else {
            options.perPage = newPerPage;
        }

        update();
    };
    
    /**
     * Adds the amount of items passed in
     * @param  {int} num
     * @return {void}
     */
    this.addItems = function(num) {
        
        var addItems = validateNum(num);

        if (!addItems) {
            return false;
        } else {
            options.items += addItems;
        }

        update();
    };

    /**
     * Removes the amount of items passed in
     * @param  {int} num
     * @return {void}
     */
    this.removeItems = function(num) {
        
        var remItems = validateNum(num);

        if (!remItems) {
            return false;
        } else {
            options.items -= remItems;
        }

        update();
    };

    /**
     * Sets the total number of items to the number passed in
     * @param  {int} num
     * @return {void}
     */
    this.setItems = function(num) {
        
        var newItems = validateNum(num);
        if (newItems == undefined || newItems == null) {
            if (num === 0) options.items = 0;
            pagination.classList.add(prefix + "pagination-hidden");
            return false;
        } else {
            options.items = newItems;
            if (options.items === 0) {
                options.perPage = origPerPage;
                previousPerPage = null;
            }
        }

        update();
    };

    /**
     * Return the calculated pages
     * @return {int} totalPages
     */
    this.pages = function() {
        return totalPages;
    };

    //init
    if (!(stringPagination in element)) {
        currentPage = 1;
        render();
    }

    element[stringPagination] = self;
}