import { uicoreCustomEvent, prefix, debounce, createElement, getClosest, classAdd, getFocusableElements, getAnimationDurationFromElement, altFind, renderSvg, each, setFocus, ariaAnnounce} from "./utilities";
import Collapse from "./collapse.js";
import Dropdown from "./dropdown.js";
import Modal from "./modal.js";

export function FilterKeyword(element) {
    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    // bind, target alert, duration and stuff
    var self = this,
        stringFilterKeyword = "FilterKeyword",
        addButton,
        filterInput,
        filterCntr,

        // handlers
        handleClickEvent = function (e) {
            if ((e.target == addButton || addButton.contains(e.target)) && filterInput.value.trim() != "") {
                uicoreCustomEvent("FilterKeyword", "CreateEvent", element, { "filter": filterInput.value } );
                filterInput.value = "";
                filterInput.focus();
                window.removeEventListener("click", clickOutHandler, true);
            }
        },

        handleKeyEvent = debounce(function (e){
            window.addEventListener("click", clickOutHandler, true);
            if (e.keyCode == 13){
                uicoreCustomEvent("FilterKeyword", "CreateEvent", element, { "filter": filterInput.value } );
                filterInput.value = "";
                filterInput.focus();
            }
        }, 50),

        clickOutHandler = function(e)  {
            if (filterCntr.contains(e.target)) {
                return; 
            }
            filterInput.value = "";
            window.removeEventListener("click", clickOutHandler, true);
        };

    // init
    if (!(stringFilterKeyword in element)) {
        addButton = element.querySelector("." + prefix + "filter-btn");
        filterInput = element.querySelector("." + prefix + "filter-input");
        filterCntr = element.querySelector("." + prefix + "filter-container");

        addButton.addEventListener("click", handleClickEvent, true);
        filterInput.addEventListener("keydown", handleKeyEvent, false);
    }

    element[stringFilterKeyword] = self;

}

export function FilterCollection(element) {
    element = element instanceof HTMLElement ? element : (function () {
        return false;
    })();

    var horizontal = true;

    // bind, target alert, duration and stuff
    var self = this,
        stringFilterCollection = "FilterCollection",
        filterResults,
        filterOffCanvasResults,
        clearAllItem,
        clearAllOffCanvas,
        resultsLabelDesktop,
        filters = [],
        filtersTemp = [],
        filterOffCanvas,
        keywordFilter,
        visibileIdx,
        mediaIncrementforDropdowns,
        showMoreDiv,
        mobileModal,
        dropdownObjects = [],
        accordionObjects = [],
        digit = "digit",
        collectionInputsContainer,
        dropdowns,
        collectionInputs,
        targetCollection,
        filterCollection,
        isOffCanvasState = false,
        isApplyingState = false,
        smallWindow,
        smallMediaSize = window.matchMedia("(max-width: 767.98px)"),
        mediumMediaSize = window.matchMedia("(max-width: 991.98px)"),

        // handlers 
        handleAddEvent = function (e) {
            if (e.type === "uicDropdownAddEvent" || e.type === "uicFilterKeywordCreateEvent") {
                if(!isOffCanvasState){
                    createDesktopFilterItem(e.detail.filter, e.detail.filterInput);
                } else{
                    createOffCanvasFilterItem(e.detail.filter, e.detail.filterInput);
                }
            } else {
                var checkbox = e.srcElement;    
                if (checkbox.tagName != "INPUT") {
                    if (checkbox.tagName === "SPAN" && checkbox.previousElementSibling.tagName === "INPUT") {
                        checkbox = checkbox.previousElementSibling;
                    } else {
                        checkbox = checkbox.querySelector("input");
                    }
                }
                createDesktopFilterItem(checkbox.nextElementSibling.innerText, checkbox);
                if (!horizontal && 
                    (element.getBoundingClientRect().y && element.getBoundingClientRect().y< 0) 
                        || (element.getBoundingClientRect().top && element.getBoundingClientRect().top < 0 )) { //Edge FF use top, not y
                    element.scrollIntoView({behavior: "smooth"});
                }
            }
        },

        handleClearAllEvent = function (e) {
            if (e.target == clearAllOffCanvas || clearAllOffCanvas.contains(e.target) || e.target === clearAllItem || clearAllItem.contains(e.target)) {
                toggleClearAllButtons(false);
                ariaAnnounce(
                    document.querySelector("." + prefix + "filter-btn-clear").innerText // will say "Clear all"
                );
            } else {
                ariaAnnounce(
                    getClosest(e.target, "." + prefix + "svg-close-x", false).getAttribute("aria-label") // will say filter-close
                );
            }
            each(filterResults.querySelectorAll("button."+prefix + "filter-item"), function(filter) {
                clearFilter(filter);
            });
            resultsLabelDesktop.classList.add(prefix + "d-none");
            if (horizontal) { clearMultiSelects(); }
            updateFiltersCount();
            uicoreCustomEvent("FilterCollection", "RemoveAllEvent", element, { "filters": filtersAsStrings()} );
        },
        
        handleDesktopCheckboxClick = function (e){

            if ( e.type === "uicDropdownAddEvent" || e.type === "uicDropdownRemoveEvent") {
                if (e.detail.filterInput.checked === true){
                    handleAddEvent(e);
                }  else {
                    var deselected = filterResults.querySelector("#" + e.detail.filter.replace(/\s/g, "").toLowerCase());
                    clearFilter(deselected);
                    if(filters && filters.length === 0) {
                        toggleClearAllButtons(false);
                        uicoreCustomEvent("FilterCollection", "RemoveAllEvent", element, { "filters": filtersAsStrings()} );
                    }
                }
            } else if (e.type === "click") {
                e.preventDefault();
                var checkbox = e.srcElement;
                if (checkbox.tagName != "INPUT") {
                    if (checkbox.tagName === "SPAN" && checkbox.previousElementSibling.tagName === "INPUT") {
                        checkbox = checkbox.previousElementSibling;
                    } else {
                        checkbox = checkbox.querySelector("input");
                    }
                }
                if (!checkbox.hasAttribute("disabled") && !checkbox.classList.contains(prefix+"disabled")) {
                    setTimeout(function() {
                        if (checkbox.checked) {
                            checkbox.checked = false;
                            getClosest(checkbox, "li", true).setAttribute("aria-checked", "false");
                        } else {
                            checkbox.checked = true;
                            getClosest(checkbox, "li", true).setAttribute("aria-checked", "true");
                        }
                        if (checkbox.checked === true) {
                            handleAddEvent(e); 
                        } else {
                            var value = checkbox.name.toLowerCase();
                            var deselected = filterResults.querySelector("[value=\"" + value + "\"]");
                            clearFilter(deselected);
                            if(filters && filters.length === 0) {
                                toggleClearAllButtons(false);
                                uicoreCustomEvent("FilterCollection", "RemoveAllEvent", element, { "filters": filtersAsStrings()} );
                            }
                        }
                    },25);
                }
            }
        },

        handleFilterItemClickEvent = function (e) {
            var target = e.target.classList.contains("." + prefix + "filter-item") ? e.target : getClosest(e.target, "." + prefix + "filter-item");
            if(filters.length && filters.length === 1) {
                handleClearAllEvent(e);
                resultsLabelDesktop.classList.add(prefix + "d-none");
                accordionObjects[0] ? setFocus(accordionObjects[0]) : setFocus(collectionInputs[0]);
            } else {
                ariaAnnounce(
                    getClosest(e.target, "." + prefix + "svg-close-x", false).getAttribute("aria-label") // will say filter-close
                );
                var nextFocusableEl = target.previousElementSibling && target.previousElementSibling.classList.contains(prefix+"filter-item") ?
                    target.previousElementSibling :
                    target.nextElementSibling && target.nextElementSibling.classList.contains(prefix+"filter-item") ? 
                        target.nextElementSibling :
                        accordionObjects ?
                            accordionObjects[0] :
                            collectionInputs[0];
                setTimeout(function() {
                    clearFilter(target);
                    uicoreCustomEvent("FilterCollection", "RemoveEvent", element, { "filters": filtersAsStrings(), "removedFilter": target.innerText } );
                    setFocus(nextFocusableEl);
                }, 50);
            }
        },
        
        handleOffCanvasApplyEvent = function () {
            isApplyingState = true;
            isOffCanvasState = false;
            
            // add label
            if (filtersTemp.length > 1) {
                resultsLabelDesktop.classList.remove(prefix + "d-none");
            }
            // if temp filter isn't in filters (create)
            each(filtersTemp, function(filterTemp){
                if (!arrayOfObjectsContains(filters, filterTemp.value.toLowerCase())) {
                    var newFilter = filterTemp.cloneNode(true);
                    newFilter.origin = filterTemp.origin;
                    newFilter.offCanvasOrigin = filterTemp.offCanvasOrigin;
                    newFilter.value = filterTemp.value;
                    newFilter.addEventListener("click", handleFilterItemClickEvent, false);
                    horizontal ? filterResults.insertBefore(newFilter, clearAllItem) : filterResults.appendChild(newFilter);
                    if(newFilter.origin){
                        newFilter.origin.checked = true;
                        getClosest(newFilter.origin, "li", true).setAttribute("aria-checked", "true");
                    }
                }
            });

            // if filter is no longer in temp filters (delete)
            each(filters.slice(), function(temp) {
                if (!arrayOfObjectsContains(filtersTemp, temp.value.toLowerCase())) {
                    var filterToRemove = altFind(filters, function(result) {
                        return result.value === temp.value;
                    });
                    clearFilter(filterToRemove);
                }
            });
            filters = filtersTemp.slice();
            
            // reset
            isApplyingState = false;

            if (filters.length > 0) {
                resultsLabelDesktop.classList.remove(prefix + "d-none");
                toggleClearAllButtons(true);
            } else {
                resultsLabelDesktop.classList.add(prefix + "d-none");
                toggleClearAllButtons(false);
            }

            // update html filter count values
            updateFiltersCount();
            if (horizontal) {
                each(dropdownObjects, function(dropdown) {
                    dropdown.recountChecked();
                });
            } else {
                updateVerticalDesktopAccordionCount();
            }

            mobileModal.hide();
            uicoreCustomEvent("FilterCollection", "UpdateEvent", element, { "filters": filtersAsStrings()} );
        },

        handleOffCanvasCancelEvent = function(){
            //clear off canvas and reset to filters
            handleOffCanvasClearAllEvent();
            each(filters, function(filter){
                if (filter.offCanvasOrigin) {
                    filter.offCanvasOrigin.click();
                } 
                else {
                    createOffCanvasFilterItem(filter, filter.origin);
                }
            });
            if (filters.length === 0) {
                toggleClearAllButtons(false);
            }

            isOffCanvasState = false;
        },

        handleOffCanvasCheckboxClick = function(e){
            var checkboxId = e.target.id.replace("OffCanvas", "");
            var desktopCheckbox = document.getElementById(checkboxId);
            getClosest(e.target, "li", true).setAttribute("aria-checked", e.target.checked);
            if (!e.target.hasAttribute("disabled") && !e.target.classList.contains(prefix+"disabled")) {
                //create new off canvas filter
                if(e.target.checked) {
                    createOffCanvasFilterItem(e.target.name, desktopCheckbox);
                    toggleClearAllButtons(true);
                }
                // remove off canvas filter
                else {
                    var checkboxFilter;
                    var value = e.target.name.toLowerCase();
                    checkboxFilter = filterOffCanvasResults.querySelector("[value=\"" + value + "\"]");
                    var currentFilter = altFind(filtersTemp, function(result) {
                        var check = result.innerText.toLowerCase().replace(/\s/g, "") == checkboxFilter.innerText.toLowerCase().replace(/\s/g, "");
                        return check;
                    });
                    // remove from temp array
                    var index = filtersTemp.indexOf(currentFilter);
                    filtersTemp.splice(index, 1);
    
                    if (checkboxFilter) {
                        setTimeout(function() {
                            filterOffCanvasResults.removeChild(checkboxFilter);
                            setTimeout(function() {
                                //if no more filters turn off Clear All Item
                                if (self.numOfFilters(true) < 1) {
                                    toggleClearAllButtons(false);
                                }
                            },10);
                        }, 1);
                    }
                }
                updateOffCanvasAccordionCount(e.target); //horizontal multi-selects don't have updated count at this point
            }
        },

        handleOffCanvasClearAllClick = function () {
            var focusableEls = getFocusableElements(filterOffCanvas);
            // after hitting "offcanvas" clear all button set focus on the previous focusable element 
            if(focusableEls.indexOf(clearAllOffCanvas) > 0) {
                focusableEls[focusableEls.indexOf(clearAllOffCanvas) - 1].focus();
            }
            handleOffCanvasClearAllEvent();
        },

        handleOffCanvasClearAllEvent = function(){
            each(element.querySelectorAll("." + prefix + "modal-offcanvas input." + prefix + "form-check-input"), function(checkbox) {
                checkbox.checked = false;
                getClosest(checkbox, "li", true).setAttribute("aria-checked", "false");
                updateOffCanvasAccordionCount(checkbox);
            });
            each(element.querySelectorAll("." + prefix + "modal-offcanvas button." + prefix + "filter-item"), function(filter) {
                filterOffCanvasResults.removeChild(filter);
            });
            filtersTemp = [];
            toggleClearAllButtons(false);
        },

        handleOffCanvasFilterItemClickEvent = function(e){
            var target = e.target.classList.contains("." + prefix + "filter-item") ? e.target : getClosest(e.target, "." + prefix + "filter-item"),
                currentFilter = altFind(filtersTemp, function(result) {
                    return result.value.toLowerCase().replace(/\s+/g, "") === target.innerText.toLowerCase().replace(/\s+/g, "");
                });
            
            // uncheck the corresponding checkbox
            if (currentFilter.offCanvasOrigin) {
                currentFilter.offCanvasOrigin.checked = false;
                getClosest(currentFilter.offCanvasOrigin, "li", true).setAttribute("aria-checked", "false");
                updateOffCanvasAccordionCount(currentFilter.offCanvasOrigin);
            }

            // remove from temp array
            var index = filtersTemp.indexOf(currentFilter);
            filtersTemp.splice(index, 1);

            //set focus on next element if it exists
            target.nextElementSibling ? 
                target.nextElementSibling.focus() : 
                target.previousElementSibling && target.previousElementSibling.classList.contains(prefix + "filter-item") ?
                    target.previousElementSibling.focus() :
                    setFocus(getFocusableElements(filterOffCanvas)[0]);
            // getFocusableElements(filterOffCanvas)[0].focus();
            
            // remove filter button from DOM
            if (filterOffCanvasResults.contains(target)){
                setTimeout(function() {
                    filterOffCanvasResults.removeChild(target);
                    setTimeout(function() {
                        //if no more filters turn off Clear All Item
                        if (self.numOfFilters(true) < 1) {
                            toggleClearAllButtons(false);
                        }
                    },10);
                }, 1);
            }
        },
    
        handleOffCanvasOpen = function() {
            isOffCanvasState = true;
            filtersTemp = filters.slice();
        },

        handleResizeSmall = function (e) {
            //change vertical clear all button
            if (!e.matches) {
                //going from small to big
                mobileModal.hide();
                smallWindow = false;
                if (!horizontal) {
                    if (clearAllItem.classList.contains("dds__d-flex")) {
                        clearAllItem.classList.remove("dds__d-flex");
                        clearAllItem.classList.add("dds__d-none");
                        clearAllItem = element.querySelector("."+prefix+"filter-collector-accordion ."+prefix+"filter-btn-clear");
                        clearAllItem.classList.remove("dds__d-none");
                        clearAllItem.classList.add("dds__d-flex");
                    } else {
                        clearAllItem = element.querySelector("."+prefix+"filter-collector-accordion ."+prefix+"filter-btn-clear");
                    }
                    clearAllItem.addEventListener("click", handleClearAllEvent, false);
                    clearAllItem.addEventListener("touchstart", handleClearAllEvent, false);
                }
            } else if (e.matches && !horizontal) {
                smallWindow = true;
                if (clearAllItem.classList.contains("dds__d-flex")) {
                    clearAllItem.classList.remove("dds__d-flex");
                    clearAllItem.classList.add("dds__d-none");
                    clearAllItem = element.querySelector(".dds__filter-label-results .dds__filter-btn-clear");
                    clearAllItem.classList.remove("dds__d-none");
                    clearAllItem.classList.add("dds__d-flex");
                } else {
                    clearAllItem = element.querySelector(".dds__filter-label-results .dds__filter-btn-clear");
                }
                clearAllItem.addEventListener("click", handleClearAllEvent, false);
                clearAllItem.addEventListener("touchstart", handleClearAllEvent, false);
            }
            updateFiltersCount();
        },

        handleResizeMedium = function (e) {
            var numOfDropdowns, rows;
            if (e.matches) {
                mediaIncrementforDropdowns = 3;
                
                rows = Math.max(Math.ceil((visibileIdx + 1) / 4), 2);
                numOfDropdowns = rows * 3 - 2;
                if (numOfDropdowns < dropdowns.length - 1) {
                    showMoreDiv.classList.remove(prefix + "d-none");
                }
                visibileIdx = numOfDropdowns - 1;
                toggleDropdown(visibileIdx);
            } else {
                mediaIncrementforDropdowns = 4;
                if (visibileIdx === dropdowns.length) {
                    return;
                }
                rows = Math.max(Math.ceil((visibileIdx + 1) / 3), 2);
                numOfDropdowns = rows * 4 - 2;
                if (numOfDropdowns > dropdowns.length - 1) {
                    numOfDropdowns = dropdowns.length;
                    showMoreDiv.classList.add(prefix + "d-none");
                }
                visibileIdx = numOfDropdowns - 1;
                toggleDropdown(visibileIdx);
            }
        },

        handleShowMore = function () {
            visibileIdx = Math.min(visibileIdx + mediaIncrementforDropdowns, dropdowns.length - 1);
            if (visibileIdx === dropdowns.length - 1) {
                showMoreDiv.classList.add(prefix + "d-none");
            }
            toggleDropdown(visibileIdx);
            setTimeout(function() {
                setFocus(dropdowns[visibileIdx].querySelector("."+prefix+"btn-secondary"));
            },1);
        },

        arrayOfObjectsContains = function (arr, value) {
            var results = arr.filter(function(obj) {
                return obj.value === value;
            });
            return results.length > 0;
        },

        clearMultiSelects = function () {
            each(collectionInputs, function(input) {
                if (input.querySelector("." + prefix + "checked-presentation")) {
                    input.firstElementChild.removeChild(input.querySelector("." + prefix + "checked-presentation"));
                }

                var typeofInput = input["getAttribute"]("data-filter");
                if (typeofInput == prefix + "multi-select") {
                    var ul = input["getAttribute"]("data-target");
                    each(element.querySelector(ul).children, function(option) {
                        option.setAttribute("aria-checked", false);
                        option.querySelector("input").checked = false;
                    });
                }
            });
        },

        clearFilter = function(filter) {
            if (filter === null || filter === undefined) {
                return;
            }

            // finder filter, if found no match in the filters, return
            var targetValue = filter.innerText,
                results = filters.filter(function(obj) {
                    targetValue = targetValue.toLowerCase().replace(/\n/g, "");
                    return obj.value === targetValue;
                });

            if (results.length < 1) {
                return;
            }
            
            // if it's a checkbox, uncheck
            var deselectTarget = results[0];            
            if(deselectTarget.origin.tagName === "INPUT" && deselectTarget.origin.checked === true){
                deselectTarget.origin.checked = false;
                getClosest(deselectTarget.origin, "li", true).setAttribute("aria-checked", "false");
            }
            if(deselectTarget.offCanvasOrigin && deselectTarget.offCanvasOrigin.tagName === "INPUT" && deselectTarget.offCanvasOrigin.checked === true){
                deselectTarget.offCanvasOrigin.checked = false;
                getClosest(deselectTarget.offCanvasOrigin, "li", true).setAttribute("aria-checked", "false");
                updateOffCanvasAccordionCount(deselectTarget.offCanvasOrigin);
            }
            if (!horizontal) {
                updateVerticalDesktopAccordionCount(deselectTarget.origin);
            }

            var value = filter.value.toLowerCase();
            var index;

            if (filterResults.contains(filter)) {
                var filterOffCanvas;        

                filterResults.removeChild(filter);
                filterOffCanvas = filterOffCanvasResults.querySelector("[value=\"" + value + "\"]");
                if (filterOffCanvasResults.contains(filterOffCanvas)) {
                    filterOffCanvasResults.removeChild(filterOffCanvas);
                    
                    //remove from filter temp
                    var offCanvasFilterObj = altFind(filtersTemp, function(result) {
                        return result.value === targetValue.toLowerCase().replace(/\n/g, "");
                    });
                    index = filtersTemp.indexOf(offCanvasFilterObj);
                    filtersTemp.splice(index, 1);
                }
            }

            //if selecting an offcanvas filter
            else if (filterOffCanvasResults.contains(filter) || filterResults.querySelector("#" +filter.id ) != null){
                // filterOffCanvasResults.removeChild(filter);
                var filterDesktop = filterResults.querySelector("#" +filter.id );
                filterResults.removeChild(filterDesktop);
            }
            
            index = filters.indexOf(results[0]);
            filters.splice(index, 1);
            if (!horizontal && 
                (element.getBoundingClientRect().y && element.getBoundingClientRect().y< 0) 
                    || (element.getBoundingClientRect().top && element.getBoundingClientRect().top < 0 )) { //Edge FF use top, not y
                element.scrollIntoView({behavior: "smooth"});
            }
            if (filters.length === 0) {
                toggleClearAllButtons(false);
            }
            // reset text (count) values in dropdowns
            each(dropdownObjects, function(dropdown) {
                dropdown.recountChecked();
            });

        },

        createShowMoreButton = function(){
            showMoreDiv = createElement("div", {
                class: prefix + "col-xs-12 " + prefix + "col-sm-6 " + prefix + "col-md-4 " + prefix + "col-lg-3",
            });
            var showMore = createElement("button", {
                class: prefix + "filter-btn-show " + prefix + "btn " + prefix + "btn-secondary " + prefix + "d-flex " + prefix + "text-truncate",
                id: "showMorefilters",
                html: "Show More Filters"
            });
            showMore.addEventListener("click", handleShowMore, false);
            showMoreDiv.appendChild(showMore);
            collectionInputsContainer.appendChild(showMoreDiv);
        },

        createOffCanvasFilterItem = function (filterItem, origin) {
            if (filterAlreadyExists(filterItem.value ? filterItem.value : filterItem, true)) {
                return;
            }

            var filterOffCanvasItem;
            var isDigit = false;
            if (origin === null || origin === undefined) {
                origin = "";
            }
            if (typeof filterItem === "object") {
                // it's an object so clone it
                filterOffCanvasItem = filterItem.cloneNode(true);
            } else {
                // id's can't have a digit as first number
                if (filterItem[0].match(/\d/)){
                    filterItem = digit.concat(filterItem);
                    isDigit = true;
                }
                filterItem = filterItem.replace(/\s+/g," ").trim();
                // need to create an object
                filterOffCanvasItem = createFilter(filterItem, true, isDigit);
            }           
            if (!isApplyingState) {
                filterOffCanvasItem.addEventListener("click", handleOffCanvasFilterItemClickEvent, false);
                filterOffCanvasResults.appendChild(filterOffCanvasItem); // DOM Add New Filter to OffCanvas
            }
            var filterValue = filterItem.value ? filterItem.value : filterItem.replace(/^digit/, "") ;
            if (isOffCanvasState){
                var offCanvasOriginId = origin.id + "OffCanvas";
                var offCanvasOrigin = document.getElementById(offCanvasOriginId);
                filterOffCanvasItem.value = origin.name ? origin.name.toLowerCase() : filterValue.toLowerCase();
                filterOffCanvasItem.origin = origin;
                filterOffCanvasItem.offCanvasOrigin = offCanvasOrigin;
                filtersTemp.push(filterOffCanvasItem);
            }

            if (clearAllOffCanvas.classList.contains(prefix + "d-none")) {
                toggleClearAllButtons(true);
            }

        },

        createDesktopFilterItem = function (filter, origin) {
            var isDigit = false;
            if (origin === null || origin === undefined) {
                origin = "";
            }
            // id's can't have a digit as first number
            if (filter[0].match(/\d/)){
                filter = digit.concat(filter);
                isDigit = true;
            }
            filter = filter.replace(/\s+/g," ").trim();
 
            if (filterAlreadyExists(filter, false)) {
                return;
            }

            if (filters.length <= 0) {
                resultsLabelDesktop.classList.remove(prefix + "d-none");
            }
    
            if (clearAllItem.classList.contains(prefix + "d-none")) {
                toggleClearAllButtons(true);
            }
            
            var filterItem = createFilter(filter, true, isDigit);
            var offCanvasOriginId = origin.id + "OffCanvas";
            var offCanvasOrigin = document.getElementById(offCanvasOriginId);

            filterItem.origin = origin;
            filterItem.offCanvasOrigin = offCanvasOrigin,
            filterItem.value = origin.name ? origin.name.toLowerCase() : filter.replace(/^digit/, "").toLowerCase();
            filterItem.addEventListener("click", handleFilterItemClickEvent, false);

            if(!isOffCanvasState){
                filters.push(filterItem);
                if (offCanvasOrigin) {
                    offCanvasOrigin.checked = true; // Keep offCanvas checkbox in sync
                    getClosest(offCanvasOrigin, "li", true).setAttribute("aria-checked", "true");
                    updateOffCanvasAccordionCount(offCanvasOrigin);
                }
                if (horizontal) {
                    filterResults.insertBefore(filterItem, clearAllItem);
                } else {
                    filterResults.appendChild(filterItem); // DOM Add new filter to Desktop
                    updateVerticalDesktopAccordionCount(origin);
                }
                if (!isApplyingState){
                    createOffCanvasFilterItem(filterItem, filterItem.origin);
                }
                updateFiltersCount();
                uicoreCustomEvent("FilterCollection", "CreateEvent", filterItem, { "filters": filtersAsStrings(), "addedFilter": filterItem.innerText } );
            }
        },

        // creates the HTML filterItem button
        createFilter = function (filter, useX, isDigit) {
            var filterText = filter;
            if(isDigit){
                filterText = filter.replace(/^digit/, "");
            }
            var filterId = filter.replace(/([^A-Za-z0-9._:/-])/g,""); // =need to delete instances of special characters
            var filterItem = createElement("button", {
                class: prefix + "filter-item " + prefix + "btn " + prefix +"btn-secondary " + prefix + "btn-sm " + prefix + "text-truncate",
                id: filterId.toLowerCase().replace(/\s/g, "").trim()
            });

            var filterLabel = createElement("label", {
                html: filterText,
                class: prefix + "text-truncate"
            });

            filterItem.appendChild(filterLabel);

            // create filterItem SVG
            if(useX) {
                var filterIcon = renderSvg([{name:"close-x", show:true}]);
                classAdd(filterIcon, prefix + "svg-close-x");
                classAdd(filterIcon, prefix + "align-self-center");
                classAdd(filterIcon, prefix + "m1-1");
                filterIcon.setAttribute("tabindex", "-1");
                filterIcon.setAttribute("focusable", "false");
                filterIcon.setAttribute("aria-label", "filter-close");
        
                // put everything together
                filterItem.appendChild(filterIcon);
            } 
            return filterItem;            
        },
                
        filterAlreadyExists = function(filter, isOffCanvasFilter) {
            var whichFilters = isOffCanvasFilter ? filtersTemp : filters;
            var whichParent = isOffCanvasFilter ? filterOffCanvas : element;
            
            if (filter === null || filter === undefined || filter == "") {
                console.warn("Not a valid filter tag");
                return true;
            }
            if(arrayOfObjectsContains(whichFilters, filter.toLowerCase())){
                
                each(whichParent.querySelectorAll(".dds__filter-item"), function(htmlFilter) {
                    if (htmlFilter.value.toLowerCase() == filter.toLowerCase()){
                        htmlFilter.classList.add(prefix + "shakey");
                        
                        setTimeout(function(){
                            htmlFilter.classList.remove(prefix + "shakey");
                        }, getAnimationDurationFromElement(htmlFilter));
                    }
                });
                console.warn("This filter has already been added");
                return true;
            }

            return false;
        },

        updateOffCanvasAccordionCount = function(origin){
            var accordionParent = getClosest(origin, "." + prefix + "collapse").id;
            var accordionBtn = element.querySelector("[data-target='#"+accordionParent+"']");//[data-target='#" + activeCollapse.id + "']"
            var accordionBody = getClosest(origin, "." + prefix + "accordion-card-body");
            var itemsChecked = accordionBody.querySelectorAll("." + prefix + "form-check-input:checked").length;

            var displayTxt;
            if (itemsChecked > 0) {
                displayTxt = "(" + itemsChecked +")";
            } else {
                displayTxt = " ";
            }

            var checkedCounter = accordionBtn.querySelector("." + prefix + "checked-presentation");
            if (checkedCounter != null) {
                checkedCounter.innerHTML = displayTxt;
                // currentText = displayTxt;
            } else {
                checkedCounter = createElement("span", {
                    class: prefix + "checked-presentation " + prefix + "ml-1",
                    html: displayTxt
                });
                accordionBtn.appendChild(checkedCounter);
            }
        },

        updateVerticalDesktopAccordionCount = function(origin) {
            if (origin) {
                var accordionParent = getClosest(origin, "." + prefix + "collapse").id;
                var accordionBtn = element.querySelector("[data-target='#"+accordionParent+"']");//[data-target='#" + activeCollapse.id + "']"
                var accordionBody = getClosest(origin, "." + prefix + "secondary-accordion");
                var itemsChecked = accordionBody.querySelectorAll("." + prefix + "form-check-input:checked").length;
                
                var displayTxt;
                if (itemsChecked > 0) {
                    displayTxt = "(" + itemsChecked +")";
                } else {
                    displayTxt = " ";
                }
    
                var checkedCounter = accordionBtn.querySelector("." + prefix + "checked-presentation");
                if (checkedCounter != null) {
                    checkedCounter.innerHTML = displayTxt;
                } else {
                    checkedCounter = createElement("span", {
                        class: prefix + "checked-presentation " + prefix + "ml-1 " + prefix + "font-weight-bold",
                        html: displayTxt
                    });
                    accordionBtn.insertBefore(checkedCounter, accordionBtn.querySelector("." + prefix + "arrow-tri-solid-right"));
                }
            } else {
                accordionObjects.forEach(function(accordion) {
                    var itemsChecked = accordion.parentNode.querySelectorAll("." + prefix + "form-check-input:checked").length;
                    
                    var displayTxt;
                    if (itemsChecked > 0) {
                        displayTxt = "(" + itemsChecked +")";
                    } else {
                        displayTxt = " ";
                    }

                    var checkedCounter = accordion.querySelector("." + prefix + "checked-presentation");
                    if (checkedCounter != null) {
                        checkedCounter.innerHTML = displayTxt;
                    } else {
                        checkedCounter = createElement("span", {
                            class: prefix + "checked-presentation " + prefix + "ml-1",
                            html: displayTxt
                        });
                        accordion.querySelector("span").appendChild(checkedCounter);
                    }
                });
            }
        },

        updateFiltersCount = function() {
            var itemsChecked = filters.length;
            var filteringMobileBtn = element.querySelectorAll("." + prefix + "filter-btn-mobile")[0];

            var displayTxt;
            if (itemsChecked > 0) {
                displayTxt = "(" + itemsChecked +")";
            } else {
                displayTxt = " ";
            }
            var checkedCounter = filteringMobileBtn.querySelector("." + prefix + "checked-presentation");
            if (checkedCounter != null) {
                checkedCounter.innerHTML = displayTxt;
            } else {
                checkedCounter = createElement("span", {
                    class: prefix + "checked-presentation " + prefix + "ml-1",
                    html: displayTxt
                });
                filteringMobileBtn.appendChild(checkedCounter);
            }
        },

        filtersAsStrings = function() {
            var stringArray = [];
            each(filters, function(filter) {
                stringArray.push(filter.value);
            });
            return stringArray;
        },

        toggleClearAllButtons = function (onState) {
            if (smallWindow && !horizontal) {
                clearAllItem = element.querySelector("."+prefix+"filter-label-results ."+prefix+"filter-btn-clear");
            }
            if (onState == true && clearAllItem.classList.contains(prefix + "d-none")) {
                clearAllOffCanvas.classList.remove(prefix + "d-none");
                clearAllItem.classList.add(prefix + "d-flex");
                clearAllItem.classList.remove(prefix + "d-none");
                clearAllItem.addEventListener("click", handleClearAllEvent, false);
                clearAllItem.addEventListener("touchstart", handleClearAllEvent, false);
            } else if (onState == false & !clearAllItem.classList.contains(prefix + "d-none")) {
                clearAllOffCanvas.classList.add(prefix + "d-none");
                clearAllItem.classList.remove(prefix + "d-flex");
                clearAllItem.classList.add(prefix + "d-none");
                clearAllItem.removeEventListener("click", handleClearAllEvent, false);
                clearAllItem.removeEventListener("touchstart", handleClearAllEvent, false);
            }
        },

        toggleDropdown = function (index) {
            var i;
            if (dropdowns[index].classList.contains(prefix + "d-none")) {
                for (i = 0; i <= index; i++) {
                    dropdowns[i].classList.remove(prefix + "d-none");
                }
            }
            else {
                for (i = index + 1; i < dropdowns.length; i++) {
                    dropdowns[i].classList.add(prefix + "d-none");
                }
            }
        };

    this.numOfFilters = function(OffCanvas) {
        if (OffCanvas) {
            return filterOffCanvasResults.querySelectorAll("." + prefix + "filter-item").length;
        }
    };
    
    this.updateResultsLabel = function(resultCount) {
        if (!resultCount || typeof resultCount !== "string" ) {
            console.warn("This is not a valid input for updateResultsLabel.");
            return;
        }
        element.querySelector("." + prefix + "resultCount").innerText = resultCount;
    };

    // init
    if (!(stringFilterCollection in element)) {
        if (element.classList.contains(prefix + "filter-collection-vertical")) {
            horizontal = false;
        }
        if (horizontal) {
            clearAllItem = element.querySelector("."+prefix+"filter-collection-wrapper ."+prefix+"filter-btn-clear");
        } else if (smallMediaSize.matches) {
            clearAllItem = element.querySelector("."+prefix+"filter-label-results ."+prefix+"filter-btn-clear");
        } else {
            clearAllItem = element.querySelector("."+prefix+"filter-collector-accordion ."+prefix+"filter-btn-clear");
        }
    
        collectionInputsContainer = element.querySelector("." + prefix + "filter-input-container");
        dropdowns = collectionInputsContainer.querySelectorAll("div." + prefix + "btn-dropdown");
        collectionInputs = element.querySelectorAll("[data-filter]");
        targetCollection = element["getAttribute"]("data-target");
        filterCollection = element.querySelector(targetCollection);

        if (filterCollection == null) {
            console.error("Found no filter collection to output results to.");
            return false;
        }
        if (collectionInputs == null) {
            console.error("Found no data-filter element(s) available to use");
            return false;
        }

        //off canvas inits
        if (element.querySelector("." + prefix + "filter-btn-mobile")) {
            var modalBtn = element.querySelector("." + prefix + "filter-btn-mobile");
            mobileModal = new Modal(modalBtn);
            modalBtn.addEventListener("click", handleOffCanvasOpen);
            filterOffCanvas = element.querySelector("." + prefix + "modal-offcanvas");

            if (element.querySelectorAll("[data-toggle='" + prefix + "collapse" + "']")) {
                each(element.querySelectorAll("[data-toggle='" + prefix + "collapse" + "']"), function(accordion) {
                    new Collapse(accordion);
                });
            }
            if (filterOffCanvas.querySelector("." + prefix + "text-right ." + prefix + "btn-primary")) {
                var applyBtn = filterOffCanvas.querySelector("." + prefix + "text-right ." + prefix + "btn-primary");
                applyBtn.addEventListener("click", handleOffCanvasApplyEvent);
            }
            if (filterOffCanvas.querySelector("." + prefix + "text-right ." + prefix + "dds__btn-secondary-primary")) {
                var clearAllBtn = filterOffCanvas.querySelector("." + prefix + "text-right ." + prefix + "dds__btn-secondary");
                clearAllBtn.addEventListener("click", handleClearAllEvent);
            }
            if (filterOffCanvas.querySelector("." + prefix + "text-right ." + prefix + "btn-secondary")) {
                clearAllOffCanvas = filterOffCanvas.querySelector("." + prefix + "text-right ." + prefix + "btn-secondary");
                clearAllOffCanvas.addEventListener("click", handleOffCanvasClearAllClick, false);
            }
            if (filterOffCanvas.querySelector("[data-dismiss]")) {
                var backOffCanvas = filterOffCanvas.querySelector("[data-dismiss]");
                backOffCanvas.addEventListener("click", handleOffCanvasCancelEvent, false);
            }
            each(filterOffCanvas.querySelectorAll("." + prefix + "form-check-input"), function(checkbox){
                checkbox.addEventListener("click", handleOffCanvasCheckboxClick, false);
            });
        }
        if (horizontal) {
            if (!mediumMediaSize.matches) {
                if (collectionInputs.length > 8){
                    visibileIdx = 5;
                    mediaIncrementforDropdowns = 4;
                    for (var i = visibileIdx + 1; i < dropdowns.length; i++ ) {
                        dropdowns[i].classList.add(prefix + "d-none");
                    }
                    createShowMoreButton();
                }
            }
            else if (mediumMediaSize.matches) {
                if (collectionInputs.length > 6) {
                    visibileIdx = 3;
                    mediaIncrementforDropdowns = 3;
                    for (i = visibileIdx + 1; i < dropdowns.length; i++ ) {
                        dropdowns[i].classList.add(prefix + "d-none");
                    }
                    createShowMoreButton();
                }
            }
        }

        each(collectionInputs, function(input) {
            var typeofInput = input["getAttribute"]("data-filter");
            if (typeofInput == prefix + "keyword-filter") {
                keywordFilter = input;
                new FilterKeyword(keywordFilter);
                keywordFilter.addEventListener("uicFilterKeywordCreateEvent", handleAddEvent, false);
            }
            else if (typeofInput == prefix + "multi-select") {
                var multiSelect = input;
                dropdownObjects.push(new Dropdown(input));
                multiSelect.addEventListener("uicDropdownAddEvent", handleDesktopCheckboxClick, false);
                multiSelect.addEventListener("uicDropdownRemoveEvent", handleDesktopCheckboxClick, false);
            } else if (typeofInput == prefix + "secondary-accordion") {
                accordionObjects.push(input);
                var checkboxes = input.parentNode.querySelectorAll("." + prefix + "form-check");
                each(checkboxes, function(checkbox) {
                    checkbox.addEventListener("click", handleDesktopCheckboxClick, true);
                });
            }
        });
        
        filterResults = filterCollection.querySelector("div." + prefix + "filter-results");
        filterOffCanvasResults = element.querySelector("div." + prefix + "modal-offcanvas div." + prefix + "filter-results");
        resultsLabelDesktop = element.querySelector("label." + prefix + "filter-label-results");

        if (horizontal) { mediumMediaSize.addListener(handleResizeMedium);}
        smallMediaSize.addListener(handleResizeSmall);
        
    }

    element[stringFilterCollection] = self;
}
