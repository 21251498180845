import { DOC, getElementsByClassName, prefix, uicoreCustomEvent, getTransitionDurationFromElement, each, getClosest } from "./utilities.js";

export function Button(element) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    // constant
    var self = this,
        stringButton = "Button",
        toggled = false, // toggled makes sure to prevent triggering twice the change.bs.button events
        pxToMove,
        btnWidth,
        checked = "checked",
        LABEL = "LABEL",
        onEl,
        offEl,
        circleEl,
        btnToggle,
        // private methods
        keyHandler = function(e) {
            var key = e.which || e.keyCode;
            key === 32 && e["target"] === DOC.activeElement && toggle(e);
        },
        preventScroll = function(e) {
            var key = e.which || e.keyCode;
            key === 32 && e["preventDefault"]();
        },
        toggle = function(e) {
            var label =
                e["target"].tagName === LABEL 
                    ? e["target"] 
                    : e["target"]["parentNode"].tagName === LABEL 
                        ? e["target"]["parentNode"] 
                        : null; // the .btn label

            if (!label) return; //react if a label or its immediate child is clicked

            var eventTarget = e["target"], // the button itself, the target of the handler function
                labels = getElementsByClassName(eventTarget["parentNode"], "btn"), // all the button group buttons
                input = label["getElementsByTagName"]("INPUT")[0];
            
            if (!input) return; //return if no input found

            // manage the dom manipulation
            if (input.type === "checkbox") {
                //checkboxes
                if (!input[checked]) {
                    label.classList.add(prefix+"active");
                    input["getAttribute"](checked);
                    input["setAttribute"](checked, checked);
                    input[checked] = true;
                } else {
                    label.classList.remove(prefix+"active");
                    input["getAttribute"](checked);
                    input.removeAttribute(checked);
                    input[checked] = false;
                }

                if (!toggled) {
                    // prevent triggering the event twice
                    toggled = true;
                    // bootstrapCustomEvent.call(input, "change", component); //trigger the change for the input
                    // bootstrapCustomEvent.call(element, "change", component); //trigger the change for the btn-group
                }
            }

            if (input.type === "radio" && !toggled) {
                // radio buttons
                if (!input[checked]) {
                // don't trigger if already active
                    label.classList.add(prefix+"active");
                    input["setAttribute"](checked, checked);
                    input[checked] = true;
                    // bootstrapCustomEvent.call(input, "change", component); //trigger the change for the input
                    // bootstrapCustomEvent.call(element, "change", component); //trigger the change for the btn-group

                    toggled = true;
                    each(labels, function(otherLabel) {
                        var otherInput = otherLabel["getElementsByTagName"]("INPUT")[0];
                        if (otherLabel !== label && otherLabel.classList["contains"](prefix+"active")) {
                            label.classList.remove(prefix+"active");
                            otherInput.removeAttribute(checked);
                            otherInput[checked] = false;
                            // bootstrapCustomEvent.call(otherInput, "change", component); // trigger the change
                        }
                    });
                }
            }
            setTimeout(function() {
                toggled = false;
            }, 50);
        },
        clickHandler = function() {
            // going from on to off
            if (btnToggle.getAttribute("aria-checked") === "true") {
                self.toggleOff();
            // going from off to on
            } else {
                self.toggleOn();
            }   
        };
    
    // public methods
    this.toggleOff = function() {
        circleEl.style.transform = "translateX(0px)";
        setTimeout(function(){
            btnToggle.setAttribute("aria-checked", "false");
        }, (getTransitionDurationFromElement(circleEl)/2)-25);
        offEl.setAttribute("aria-hidden", "false");
        onEl.setAttribute("aria-hidden", "true");
        uicoreCustomEvent("ButtonToggle", "Off", element);
    };
    this.toggleOn = function() {
        circleEl.style.transform = "translateX(" + pxToMove + "px)";
        setTimeout(function(){
            btnToggle.setAttribute("aria-checked", "true");
        }, (getTransitionDurationFromElement(circleEl)/2)-25);
        offEl.setAttribute("aria-hidden", "true");
        onEl.setAttribute("aria-hidden", "false") ;
        uicoreCustomEvent("ButtonToggle", "On", element);
    };

    // init
    if (!(stringButton in element)) {
        // prevent adding event handlers twice
        if (element.classList.contains(prefix + "btn-toggle-container")) {
            btnToggle = element.firstElementChild.tagName === "LABEL" ? element.querySelector("." + prefix + "btn-toggle") : element;
            
            offEl = btnToggle.querySelector("." + prefix + "toggle-txt-off");
            onEl = btnToggle.querySelector("." + prefix + "toggle-txt-on");
            circleEl = btnToggle.querySelector("." + prefix + "toggle-circle");
            
            if (element.firstElementChild.tagName === "SPAN") {
                offEl.style.display="block";
                onEl.style.display="block";
                var widthOff = offEl.getBoundingClientRect().width;
                var widthOn = onEl.getBoundingClientRect().width;
                offEl.removeAttribute("style");
                onEl.removeAttribute("style");
                btnWidth = Math.max(widthOff, widthOn);
                btnToggle.style.width = (btnWidth + 24) + "px";
            }

            pxToMove = btnToggle.getBoundingClientRect().width - 28;
            
            if(btnToggle.getAttribute("aria-checked") === "true"){
                circleEl.style.transform = "translateX(" + pxToMove + "px)";
            }
            btnToggle.style.visibility = "visible";
            element.addEventListener("click", clickHandler);
        // bootstrap button js that needs to be checked on 
        } else {
            element.addEventListener("click", toggle, false);
            element["hasAttribute"]("tabindex") && 
            element.addEventListener("keyup", keyHandler, false),
            element.addEventListener("keydown", preventScroll, false);
    
            // activate items on load
            each(getElementsByClassName(element, prefix+"btn"), function(label) {
                !label.classList["contains"](prefix+"active") &&
                label("hasAttribute")("checked") &&
                label.classList.add(prefix+"active");
            });
        }
        
    }

    element[stringButton] = self;
}

export function ButtonFilter(element) {
    // initialization element
    element = element instanceof HTMLElement ? element : (function() {
        return false;
    })();

    var stringButtonFilter = "ButtonFilter",
        lastActiveItem = null,
        inactiveFilterItem,
        activeFilterItem,
        // Event Handlers
        focusInEventHandler = function(e) {
            var filterItem = e["currentTarget"];
            e.preventDefault();
            // Clicking or Tabbing on the active filter item
            var parentFieldset = getClosest(filterItem, "fieldset", false);
            if(filterItem == lastActiveItem || filterItem.classList.contains(prefix+"disabled") || parentFieldset && parentFieldset.hasAttribute("disabled")){
                return;
            }

            activeFilterItem = filterItem;
            inactiveFilterItem = lastActiveItem;

            if (inactiveFilterItem.label) {
                inactiveFilterItem.label.classList.remove(prefix + "active");
                activeFilterItem.label.classList.add(prefix + "active");
            }
            if (inactiveFilterItem.input) {
                inactiveFilterItem.input.removeAttribute("checked");
                activeFilterItem.input.setAttribute("checked","");
            }

            inactiveFilterItem.classList.remove(prefix + "active");
            activeFilterItem.classList.add(prefix + "active");
            lastActiveItem = activeFilterItem;

            // TODO
            // Emit filter event typeArg: string, canBubbleArg: boolean, cancelableArg: boolean, detailArg: T
            uicoreCustomEvent("ButtonFilter", "Click", activeFilterItem.label, {"target": activeFilterItem.label.dataset["filterValue"]});
        };

    if (!(stringButtonFilter in element)) {
        var filterItems = element.querySelectorAll("."+ prefix + "filter-item");
        lastActiveItem = element.querySelector("." + prefix + "active");
        for (var f = 0; f < filterItems.length; f++) {
            filterItems[f].addEventListener("focusin", focusInEventHandler, false);
            filterItems[f].label = filterItems[f].querySelector("."+ prefix + "btn-filter");
            filterItems[f].input = filterItems[f].querySelector("."+ prefix + "btn-input");
        }
    }

    element[stringButtonFilter] = self;
}